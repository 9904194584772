import { Component, OnInit } from '@angular/core';
import { AwsUtil } from './service/aws.service';
import { Router,NavigationEnd} from '@angular/router';
import { UserLoginService } from './service/user-login.service';
import { CognitoUtil, LoggedInCallback } from './service/cognito.service';
import { UserIdleService } from 'angular-user-idle';
import { CitiesService } from './service/cities.service';
import { environment } from '../environments/environment';
import * as jwt_decode from 'jwt-decode';
declare global {
    interface Window {
      dataLayer: any;
    }
    interface Window {
      fbq: any;
    }
  }



@Component({
    selector: 'app-root',
    templateUrl: 'template/app.html'
})
export class AppComponent implements OnInit, LoggedInCallback {
    routeUrl = '';
    // loadingBar = false;
    // error = false;
    // unauthorized = false;

    constructor(public awsUtil: AwsUtil,
        public router: Router,
        private userIdle: UserIdleService,
        private citiesService: CitiesService,
        public userService: UserLoginService, public cognito: CognitoUtil) {
        console.log('AppComponent: constructor');
        this.router.events.forEach((item) => {
            if (item instanceof NavigationEnd) {
                console.log(item.url)
                window.dataLayer.push({
                    event: "pageview",
                    page: { path: item.url },
                  });
            }
        })
    }

    ngOnInit() {
        console.log('AppComponent: Checking if the user is already authenticated');
        this.userService.isAuthenticated(this);
        console.log('thi is the route url', document.URL);
        // this.routeUrl = ((((document.URL).split('.fieldsmanager'))[0]).split('://'))[1];
        this.routeUrl = ((document.URL).replace('www.', '').split('://'))[1];
        console.log('first part of url:', this.routeUrl);
        if (this.routeUrl.toLowerCase().includes('localhost')) {
            // this.getCityDetails('sh', this.routeUrl);
            // this.router.navigate(['/home/land']);
            this.setSystemAdminDetails();
        } else
            if (this.routeUrl.toLowerCase().includes('fieldsmanager'.toLowerCase()) && !(this.routeUrl.toLowerCase().includes('staging.fieldsmanager'.toLowerCase()))) {
                if ((this.routeUrl.split('fieldsmanager')[0]) === '') {
                    console.log('In home page ---- admin');
                    this.setSystemAdminDetails();
                } else {
                    // this.routeUrl = this.routeUrl.split('.fieldsmanager')[0];
                    console.log('this is the route url in stage', this.routeUrl);
                    this.getCityDetails(this.routeUrl.split('.fieldsmanager')[0], this.routeUrl);
                }
            } else if (this.routeUrl.toLowerCase().includes('dev-fm.bizdevworks'.toLowerCase())) {
                if ((this.routeUrl.split('dev-fm.bizdevworks')[0]) === '') {
                    console.log('In home page ---- dev admin');
                    if (this.routeUrl.toLowerCase().includes('access_token')) {
                     this.getCityDetails(this.routeUrl.split('.dev-fm.bizdevworks')[0], this.routeUrl)
                    } else {
                        this.setSystemAdminDetails();
                    }
                } else {
                    // this.routeUrl = this.routeUrl.split('.dev-fm.bizdevworks')[0];
                    console.log('this is the route url in stage', this.routeUrl);
                    this.getCityDetails(this.routeUrl.split('.dev-fm.bizdevworks')[0], this.routeUrl);
                    // this.router.navigate(['/home/land']);
                }
            } else if (this.routeUrl.toLowerCase().includes('stage-fm.bizdevworks'.toLowerCase())) {
                if ((this.routeUrl.split('stage-fm.bizdevworks')[0]) === '') {
                    console.log('In home page ---- stage admin');
                    this.setSystemAdminDetails();
                } else {
                    // this.routeUrl = this.routeUrl.split('.stage-fm.bizdevworks')[0];
                    console.log('this is the route url in stage', this.routeUrl);
                    this.getCityDetails(this.routeUrl.split('.stage-fm.bizdevworks')[0], this.routeUrl);
                }
            } else if (this.routeUrl.toLowerCase().includes('staging.fieldsmanager'.toLowerCase())) {
                if ((this.routeUrl.split('staging.fieldsmanager')[0]) === '') {
                    console.log('In home page ---- stage admin');
                    this.setSystemAdminDetails();
                } else {
                    // this.routeUrl = this.routeUrl.split('.staging.fieldsmanager')[0];
                    console.log('this is the route url in stage', this.routeUrl);
                    this.getCityDetails(this.routeUrl.split('.staging.fieldsmanager')[0], this.routeUrl);
                }
            }
    }

    getCityDetails(params, routeUrl) {
        sessionStorage.setItem('params', params + '/biz417bend180qaztriptransflightresponse/' + routeUrl);
        if (routeUrl.toLowerCase().includes('checkstatus')) {
            // this.router.navigate(['/home/checkStatus']);
            console.log('get city Details method: URL contains checkStatus, Default routing is disabled');
        } else if (routeUrl.toLowerCase().includes('access_token')) {
            console.log('after self close & befora navigation');
            let param = new URL(routeUrl.replace(new RegExp('/#', ''), ':/?')).searchParams.get('id_token'); 
            console.log("token::",param);
            let tokenInfo = this.getDecodedAccessToken(param);
            localStorage.setItem('authToken', param);
            localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' +
             tokenInfo['cognito:username'] + '.idToken', param);
            localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' + tokenInfo['cognito:username'] +
              '.accessToken', new URL(routeUrl.replace(new RegExp('/#', ''), ':/?')).searchParams.get('access_token'));
            localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' +
             tokenInfo['cognito:username'] + '.clockDrift', '0');
            localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.LastAuthUser', tokenInfo['cognito:username']);
            sessionStorage.setItem('authorizationToken', param);
            this.router.navigate(['/home'])
            self.close();
        } else {
            setTimeout(function(){
                this.router.navigate(['/home/signin']);
            }.bind(this),1000);
            self.close()
        }
    }

    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    setSystemAdminDetails() {
        // sessionStorage.setItem('cityName', 'BizCloud Experts');
        // sessionStorage.setItem('address', '417 Oakbend Drive, #180, Lewisville, Texas - 75067');
        // sessionStorage.setItem('website', 'www.bizcloudexperts.com');
        // sessionStorage.setItem('cityId', 'System');
        console.log('this is the route url', this.routeUrl);
        if (this.routeUrl.toLowerCase().includes('checkstatus')) {
            // this.router.navigate(['/home/checkStatus']);
            console.log('URL contains checkStatus, Default routing is disabled');
        } else if (this.routeUrl.toLowerCase().includes('login')) {
            this.router.navigate(['/home/login']);
        } else if (this.routeUrl.toLowerCase().includes('pricing')) {
            console.log('URL contains pricing, Default routing is disabled');
        } else {
        console.log('this is the default routing');
            if (this.routeUrl.toLowerCase().includes('access_token')) {
                console.log('after self close & befora navigation');
                let param = new URL(this.routeUrl.replace(new RegExp('/#', ''), ':/?')).searchParams.get('id_token'); 
                console.log("token::",param);
                let tokenInfo = this.getDecodedAccessToken(param);
                localStorage.setItem('authToken', param);
                localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' +
                tokenInfo['cognito:username'] + '.idToken', param);
                localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' + tokenInfo['cognito:username'] +
                '.accessToken', new URL(this.routeUrl.replace(new RegExp('/#', ''), ':/?')).searchParams.get('access_token'));
                localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.' +
                tokenInfo['cognito:username'] + '.clockDrift', '0');
                localStorage.setItem('CognitoIdentityServiceProvider.' + environment.clientId + '.LastAuthUser', tokenInfo['cognito:username']);
                sessionStorage.setItem('authorizationToken', param);
                this.router.navigate(['/home']); 
                self.close();
            }
            if (this.routeUrl.toLowerCase().includes('error_description=presignup')) {
                setTimeout(function(){ 
                    this.router.navigate(['/home/signin']);
                }.bind(this),1000); 
                self.close();
            }  
        } 
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        console.log('AppComponent: the user is authenticated: ' + isLoggedIn);
        let mythis = this;
        this.cognito.getIdToken({
            callback() {

            },
            callbackWithParam(token: any) {
                // Include the passed-in callback here as well so that it's executed downstream
                console.log('AppComponent: calling initAwsService in callback')
                mythis.awsUtil.initAwsService(null, isLoggedIn, token);
            }
        });
    }

    errorHandle(err) {
        if (err.status === 401) {
            // this.loadingBar = false;
            // this.unauthorized = true;
            this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            // this.loadingBar = false;
            // this.error = true;
            this.router.navigate(['/admin/error']);
        } else {
            // this.loadingBar = false;
            // this.error = true;
            this.router.navigate(['/admin/error']);
        }
    }
}
