import { CognitoUtil } from './../../service/cognito.service';
import { environment } from './../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserFormService} from '../../service/user-form.service';
import {CitiesService} from '../../service/cities.service'
import {Sport} from '../../models/sport';
import { DropdownModule, Dropdown } from 'primeng/primeng';
import {Ground} from '../../models/ground';
import {Validators, FormControl, FormGroup, FormBuilder, AsyncValidator} from '@angular/forms';
import {Reservation} from '../../models/reservation';
import * as moment from 'moment';
import {Moment} from 'moment';
import { ActivatedRoute  } from '@angular/router';
import { NumberFormatStyle } from '@angular/common';
import { UserLoginService } from '../../service/user-login.service';
import * as jwt_decode from 'jwt-decode';
var popupTools = require('popup-tools');
declare let $;


declare let AWS: any;
declare let AWSCognito: any;
declare let paypal: any;



@Component({
  selector: 'app-custom-slot',
  templateUrl: './custom-slot.component.html',
  styleUrls: ['../../../assets/slotsAssets/css/style.css']
})
export class CustomSlotComponent implements OnInit {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityNumber: number;
    opendates = [];
    openmonths = [];
    randomVal: any;
    loginPage: boolean = false;
    captchaResolved: boolean = false;
    public sportsList = [];
    public groundsList = [];
    timeSlots = [];
    selectedParkId: any;
    startDate: any = moment().format('YYYY-MM-DD');
    endDate: any = moment(this.startDate).endOf('month');
    loadingBar = false;
    error = false;
    unauthorized = false;
    conflictResponse = {};
    reservationMessageError = null;
    reservationMessageOverlap = null;
    reservationMessageSuccess = null;
    reservationData = new Reservation();
    selectedSport: any;
    selectedGround: any;
    slotDetailsPage: boolean;
    userDetailsPage: boolean;
    termsCheck: boolean;
    public terms: boolean;
    weekdayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    currentDate = new Date();
    currentDay: string;
    currentWeek: string;
    minDate: Moment;
    maxDate: Moment;
    // maxDate: Moment;
    slotsAvailable = [];
    reservationform: FormGroup;
    slotValue: string;
    slotsLabel: string;
    cityName: string;
    address: string;
    website: string;
    slotUnAvailable = false;
    slot_message = '';
    dropDownGrounds: Dropdown;
    emailContent: string;
    selectedSlotStatus: any;
    selectedSlotFIFO: any;
    weatherReport: any;
    checkout: any;
    selectedSlotPaidStatus;
    addScript: boolean = false;
    paypalLoad: boolean = true;
    finalAmount: number;
    paypalToken: any;
    selectedGroundInstanceName: any;
    selectedGroundInstance: any;
    user = 'false';
    loginType = false;
    email: string;
    password: string;
    errorMessage: string;
    user_loadingBar


    constructor(public router: Router,
        public userFormService: UserFormService,
        public citiesService: CitiesService,
        private cognitoUtil: CognitoUtil,
        private formbuilder: FormBuilder,
        public userService: UserLoginService,
        private route: ActivatedRoute
    ) {
        this.slotDetailsPage = true;
        this.userDetailsPage = false;
        this.terms = false;
    }
    ngOnInit() {
        this.setCityDetails(sessionStorage.getItem('params').split('/biz417bend180qaztriptransflightresponse/')[0],
         sessionStorage.getItem('params').split('/biz417bend180qaztriptransflightresponse/')[1]);
        // this.getSports();
        this.reservationform = this.formbuilder.group({
            'sportId': new FormControl('', Validators.required),
            'groundId': new FormControl('', Validators.required),
            'selectedDate': new FormControl('', Validators.required),
            'firstName': new FormControl('', Validators.required),
            'lastName': new FormControl('', Validators.required),
            'teamName': new FormControl(''),
            'residentOf': new FormControl(''),
            'email': new FormControl('', [Validators.email, Validators.required]),
            'phoneNum': new FormControl('', [Validators.min(1000000000), Validators.max(9999999999), Validators.required]),
            'startTime': new FormControl('', Validators.required),
            'endTime': new FormControl('', Validators.required)
        });
        this.user = localStorage.getItem('user');
        if (this.user) {
         this.user_loadingBar=true;    
        }
        this.slotsLabel = 'Select a slot';
        this.termsCheck = false;
        console.log(this.currentDate.getDay());
        this.restrictDays();
    }

    loginWithAuthProvider(param, authType) {
     var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
     if (authType === 'facebook') {
        URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
     } else if (authType === 'google') {
        URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
     } else if (authType === 'amazon') {
        URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
     }
    //  open(URL,'sample', 'width=300,height=300').focus();
    // let  newwindow = window.open(URL, 'Fields Manager', 'height=500,width=300');
    // if (window.focus) {newwindow.focus()}
        popupTools.popup(URL, 'My Popup', { width: 400, height: 450 },((err)=> {
        console.log('window closed::');
        this.user_loadingBar=true;
        // sessionStorage.setItem('authorizationToken',localStorage.authToken);
        // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
         if (param === 'userView') {
            this.userDetailsPage = true;
            this.slotDetailsPage = false;
            this.loginType = false;
            this.loginPage = false;
         }
        if (localStorage.authToken) {
          this.cognitoCallback(null, localStorage.authToken);
        }
        }))
    }

    goToDashboard() {
        this.router.navigate(['/home/dashboard']);
    }

    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
      getAdminEMails(cityId) {
        this.userFormService.getAdminEMails(cityId).subscribe((data) => {
            this.emailContent = 'mailto:' + data + '?Subject=Custom Slot Request&body=Hello Admin,%0D%0A%0D%0AI would like to create a custom slot, %0D%0ADetails are listed below. %0D%0A%0D%0A(Fill the below details)%0D%0APark Name*:%0D%0AGround Name*:%0D%0ASport*:%0D%0ADate*:%0D%0ASlot Time*:%0D%0AName*:%0D%0APhone*:%0D%0AEmail*:%0D%0ATeam:%0D%0AResident Of:%0D%0A%0D%0AThank You.';
        });
      }
    setCityDetails(params, routeUrl) {
        this.loadingBar = true;
        this.citiesService.getCityDetails(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                if (response.body.length === 0) {
                    //this.router.navigate(['/home/land']);
                } else {
                    this.paypalToken = response.body[0]['PayPal'];
                    console.log('This is the response from getCity Details by domain', response.body);
                    // this.citiesService.cityDetails.emit(response.body);
                    this.cityName = response.body[0]['City_Name'];
                    this.address = response.body[0]['Street'] +', '+ response.body[0]['City'] +', \n'+ response.body[0]['State'] +', \n'+ response.body[0]['Zip_Code'];
                    this.website = response.body[0]['Website'];
                    this.somevalue = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + response.body[0]['City_Id'] + '.png';
                    this.getSports(response.body[0]['City_Id']);
                    // this.getAdminEMails(response.body[0]['City_Id']);
                    if (routeUrl.toLowerCase().includes('checkstatus')) {
                        console.log('route url has check status');
                        this.router.navigate(['/home/checkStatus']);
                    } else {
                        if (localStorage.getItem('CognitoIdentityServiceProvider.3run3nquj30pkpaf1djg6acu8n.rohitk.accessToken') === null) {
                            //this.router.navigate(['/home/land']);
                        } else {
                            this.router.navigate(['/home/login']);
                        }
                    }
                    // this.loadingBar = false;
                    sessionStorage.setItem('cityName', response.body[0]['City_Name']);
                    sessionStorage.setItem('location', response.body[0]['City'] + ', ' + response.body[0]['State']);
                    sessionStorage.setItem('address', response.body[0]['Street'] +', '+ response.body[0]['City'] +', \n'+ response.body[0]['State'] +', \n'+ response.body[0]['Zip_Code']);
                    sessionStorage.setItem('website', response.body[0]['Website']);
                    sessionStorage.setItem('cityId', response.body[0]['City_Id']);
                    sessionStorage.setItem('cityDomain', params);
                }
            }
        },
            err => {
                this.errorHandle(err)
            });
    }
    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        } else {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        }
    }
    onClickGuest() {
        this.loginType = false;
        this.slotDetailsPage = false;
        this.userDetailsPage = true;
        this.loginPage = false;
    }
    onClickLogin() {
        this.loginPage = true;
        this.loginType = false;
    }
    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = 'All fields are required';
            return;
        }
        this.userDetailsPage = true
        this.slotDetailsPage = false;
        this.loginType = false;
        this.loginPage = false;
        this.user_loadingBar = true;
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }
     cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.user_loadingBar = false;
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log('redirecting');
                this.router.navigate(['/home/accountActivation', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                console.log('redirecting to set new password');
                this.router.navigate(['/home/newPassword']);
            }
        } else { // success
            let tokenInfo;
            if (result.idToken === undefined) {
               tokenInfo = this.getDecodedAccessToken(result);
                console.log('this is the id token ###################', tokenInfo);
               sessionStorage.setItem('authorizationToken', result);
            } else {
               tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
            }
            let params = sessionStorage.getItem('authorizationToken');
            console.log('This is the token info', tokenInfo);
            if (tokenInfo['cognito:groups'][0] === 'SystemAdmin') {
                console.log('this is the console for system admin');
                localStorage.setItem('superAdmin', 'true');
                // this.loadingBar = false;
                this.router.navigate(['/admin/cityRequests']);
            } else if (tokenInfo['cognito:groups'][0].includes('Facebook') && !(tokenInfo['cognito:groups'][0].includes('User'))) {
                console.log("facebook Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    // this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('Google') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("google Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    // this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('LoginWithAmazon') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("amazon Data::",tokenInfo);
                console.log("amazon Data::",tokenInfo['name']);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                localStorage.setItem('firstName', tokenInfo['name']);
                // localStorage.setItem('lastName', tokenInfo['family_name']);
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    // this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('User')) {
                console.log('************ User is logged in ***********');
                localStorage.setItem('user', 'true');
                this.user = 'true';
                //this.loadingBar = true;
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                    //this.loadingBar = false;
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
                    // this.router.navigate(['/home/land']);
                    // this.loadingBar = false;
                    // this.userDetailsPage = true
                    // this.slotDetailsPage = false;
                    // this.loginType = false;
                    // this.loginPage = false;
                    // this.ngOnInit();
            } else {
                this.userService.getAdminDetails(params).subscribe(data => {
                    console.log('this is the output from get admin details:', data);
                    localStorage.setItem('repId', data[0]['Rep_Id']);
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                    if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        // this.loadingBar = false;
                        this.router.navigate(['/parkDefaults']);
                    } else {
                        localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                        localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        this.router.navigate(['/admin']);
                    }
                });
            }
        }
    }
    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
    onClear() {
        this.reservationform.reset();
        this.terms = false;
        this.reservationform['selectedDate'] = '';
    }
    onCickNext() {
        this.slotDetailsPage = false;
        this.userDetailsPage = true;
        if (this.user === 'true') {
        setTimeout(() => {
        this.user_loadingBar = false;
        }, 2000);
        this.loginType = false;
        this.reservationData.firstName = localStorage.getItem('firstName'),
        this.reservationData.lastName = localStorage.getItem('lastName'),
        this.reservationData.email = localStorage.getItem('email'),
        this.reservationData.teamName = localStorage.getItem('teamName'),
        this.reservationData.phoneNumber = (localStorage.getItem('phoneNumber')).split('+1')[1],
        this.reservationData.residentOf = localStorage.getItem('residentOf')
        } else {
          this.slot_message = '';
          this.loginType = true;
        }
    }
    onClickBack() {
        console.log('Login Page:', this.loginPage, 'Login Type:', this.loginType, 'Slot Details Page:',
         this.slotDetailsPage, 'User Details Page:', this.userDetailsPage);
        if (this.loginPage === true) {
            this.loginType = true;
            this.loginPage = false;
        }else if (this.loginType === true ) {
            this.loginPage = false;
            this.loginType = false;
            this.slotDetailsPage = true;
            this.userDetailsPage = false;
        } else if (this.userDetailsPage = true && this.loginType === false && this.loginPage === false) {
            if (this.user === 'true') {
                this.loginType = false;
                this.loginPage = false;
                this.slotDetailsPage = true;
                this.userDetailsPage = false;
            } else {
                this.loginType = true;
            }
        }
        this.captchaResolved = false;
        this.termsCheck = false;
        this.addScript = false;
        }
    close() {
        this.termsCheck = false;
        // this.captchaResolved = false;
    }
    onTerms() {
        if (this.captchaResolved === true) {
        this.terms = true;
        } else {this.terms = false;
            alert('No bots please'); }
    }

    onPayment() {
      this.terms = false;
      this.checkout = true;
    }

    onTermsCheck() {
        console.log('terms and conditions:', this.termsCheck);
    }
    onFormSubmit() {
        this.terms = false;
        this.selectedGroundInstance = false;
        let startTime = moment(this.reservationData.startTime).format('hh:mm A');
        let endTime = moment(this.reservationData.endTime).format('hh:mm A');
        this.loadingBar = true;
        if (this.reservationData.teamName === '' || this.reservationData.teamName === undefined) {
            this.reservationData.teamName = null;
        }
        this.reservationData.selectedDate = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
        console.log('sport is: ', this.reservationData.sportId);
        const phone = '+1'.concat(this.reservationData.phoneNumber);
        const createdAt = moment().tz('America/Chicago').format('YYYY-MM-DD hh:mm:ss');
        if (this.reservationData.residentOf === '' || this.reservationData.residentOf === undefined || this.reservationData.residentOf === null ){
            this.reservationData.residentOf = null;
        }
        if (localStorage.getItem('id') === null || localStorage.getItem('id') === undefined || localStorage.getItem('id') === '') {
            this.reservationData.userId = null;
        } else {
            this.reservationData.userId = localStorage.getItem('id');
        }
        const params = {
            'sportId': this.reservationData.sportId,
            'groundId': this.reservationData.groundId,
            'phoneNumber': phone,
            'reservationDate': this.reservationData.selectedDate,
            'cityId': sessionStorage.getItem('cityId'),
            'createdAt': createdAt,
            'userId': this.reservationData.userId,
            'firstName': this.reservationData.firstName,
            'lastName': this.reservationData.lastName,
            'email': this.reservationData.email,
            'teamName': this.reservationData.teamName,
            'residentOf': this.reservationData.residentOf,
            'stage': environment.logoStage,
            'startTime': startTime,
            'endTime': endTime,
            'cityDomain': sessionStorage.getItem('cityDomain'),
            'parkId': this.selectedParkId
        };
        // this.termsCheck = false;
        // this.terms = false;
        // this.checkout = false;
        console.log('Model data is:', this.reservationData);
        console.log('Add reservation params are:', params);
        this.userFormService.submitCustomRequest(params).subscribe((data) => {
            this.loadingBar = false;
            console.log('Response is: ', data);
            this.reservationData = new Reservation();
            this.slotDetailsPage = true;
            this.userDetailsPage = false;
            if (data.msg = 'Reservation request submitted. Notification sent!') {
            this.reservationMessageOverlap = 'Reservation request submitted!';
            } else {
                this.reservationMessageOverlap = data.msg;
            }
            setTimeout(() => {
                this.clearMessages();
                //this.router.navigate(['/home/land']);
            }, 9000);
        },
        err => {
            this.errorHandle(err)
        });
    }
    clearMessages() {
        this.reservationMessageOverlap = null;
        this.reservationMessageSuccess = null;
        this.reservationMessageError = null;
    }
    resolved(captchaResponse: string) {
        let resolvedKey = captchaResponse;
        if (resolvedKey === '' || resolvedKey === undefined || resolvedKey === null) {
            this.captchaResolved = false;
        } else {
            this.captchaResolved = true;
        }
        console.log(`Resolved captcha: ${captchaResponse}`);
    }
    restrictDays() {
        // this.currentDay = moment().format('dddd').toString();
        // console.log('Current Day is', this.currentDay);
        // this.currentWeek = moment().format('w');
        this.minDate = (moment())['_d'];
        this.maxDate = (moment()).add(40, 'days')['_d'];
        // this.maxDate = moment
        // if (this.weekdayList.indexOf(this.currentDay) > -1) {
        //     this.minDate = (moment().add(1, 'weeks').startOf('isoWeek'))['_d'];
        //     // this.maxDate = (moment().add(1, 'weeks').endOf('isoWeek'))['_d'];
        //     console.log(this.currentDay, this.minDate,  ' is a weekday');
        // } else {
        //     this.minDate = (moment().add(2, 'weeks').startOf('isoWeek'))['_d'];
        //     // this.maxDate = (moment().add(2, 'weeks').endOf('isoWeek'))['_d'];
        //     console.log(this.currentDay, this.minDate,  ' is not a weekday');
        // }
    }
    getSports(cityId) {
        this.sportsList = [];
        this.loadingBar = true;
        this.userFormService.getSports(cityId).subscribe((data) => {
            // let tempObjArray: Ground[] = [];
            console.log("sports data response from API call is", data);
            this.sportsList = [];
            if (data.length === 0) {
                setTimeout(() => {
                    this.loadingBar = false;
                }, 3000);
            } else {
                data.forEach((o) => {
                    console.log(o);
                    let obj = new Sport();
                    const temp = {};
                    temp['value'] = o.Sport_Id;
                    temp['label'] = o.Sport_Name;
                    this.sportsList.push(temp);
                });
                this.loadingBar = false;
                if(this.sportsList.length==1){
                  this.reservationData.sportId=this.sportsList[0].value;
                  this.getGroundsBySport(this.reservationData.sportId)
                }
                // this.getSportsByGround(this.selectedGround);
                console.log('sport data is: ', this.sportsList);
            }
        },
        err => {
            this.errorHandle(err)
        });
    }
    getGroundsBySport(inputParams) {
        this.selectedGroundInstance = false;
        this.reservationData.slot = null;
        this.opendates = [];
        this.reservationData.groundId = null;
        this.reservationData.selectedDate = null;
        this.groundsList = [];
        this.loadingBar = true;
        console.log("get grounds for sportId :", inputParams);
        let outputParams = {
            'sportId': inputParams,
            'cityId': sessionStorage.getItem('cityId')
        }
        this.selectedGround = '';
        this.userFormService.getGrounds(outputParams).subscribe((data) => {
            console.log('this is the data', data);
            this.groundsList = [];
            if (data.length === 0) {
               this.loadingBar = false;
            } else {
                data.forEach((o) => {
                    console.log(o);
                   // this.selectedGroundInstance = o.Ground_Id;
                    this.selectedGroundInstanceName = o.Ground_Name;
                   // localStorage.setItem('defaultParkId', "38");
                    let obj = new Ground();
                    const temp = {};
                    temp['value'] = o.Ground_Id;
                    temp['label'] = o.Ground_Name + ' - ' + o.Park_Name;
                    temp['parkId'] = o.Park_Id;
                    this.groundsList.push(temp);
                });
                this.loadingBar = false;
                if (this.groundsList.length === 1) {
                 this.reservationData.groundId = this.groundsList[0].value;
                 this.getSlots(this.reservationData.groundId);
                }
                console.log('groundsList data is: ', this.groundsList);
            }
        },
        err => {
            this.errorHandle(err)
        });
    }
    loginAsAdmin() {
        console.log('on router button click');
        this.router.navigate(['/home/login'],{queryParams:{'name':'custom'}});
    }
    signup() {
        console.log('on router button click');
        this.router.navigate(['/home/register']);
    }
    logout () {
        // this.userService.logout();
        var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        URL = environment.logout + domainName;
        this.user = 'false';
        console.log('UserLoginService: Logging out');
        localStorage.removeItem('user');
        sessionStorage.removeItem('authorizationToken');
        localStorage.removeItem('repId');
        localStorage.removeItem('repInitials');
        localStorage.removeItem('repName');
        localStorage.removeItem('id');
        // this.reservationData.sportId = undefined;
        // this.reservationData.groundId = undefined;
        // this.reservationData.slot = undefined;
        // this.reservationData.selectedDate = undefined;
        // this.timeSlots = [];
        // this.groundsList = [];
        this.slot_message = '';
        setTimeout(function(){
        this.reservationData.firstName = localStorage.removeItem('firstName');
        this.reservationData.lastName = localStorage.removeItem('lastName');
        this.reservationData.residentOf = localStorage.removeItem('residentOf');
        this.reservationData.teamName = localStorage.removeItem('teamName');
        this.reservationData.phoneNumber = localStorage.removeItem('phoneNumber');
        this.reservationData.email = localStorage.removeItem('email');
        }.bind(this), 0);
        this.slotDetailsPage = true;
        this.userDetailsPage = false;
        this.loginType = false;
        this.cognitoUtil.getCurrentUser().signOut();
        console.log('Logout URL:', URL);
        window.location.replace(URL);
        // this.router.navigate(['/home/customSlot']);
    }
    getSlots(groundId) {
        this.selectedGroundInstance = groundId;
        // this.selectedGroundInstanceName = this.selectedGroundInstanceName;
        this.loadingBar = true;
        this.selectedParkId = null;
        this.groundsList.forEach((o) => {
            if (o.value === groundId) {
                this.selectedGroundInstanceName = o.label;
                this.selectedParkId = o.parkId;
                console.log('this is the selcted parkId', this.selectedParkId);
            }
        });
        this.loadingBar = false;
        console.log('Selected ground Id is: ', groundId);
    }
    onDateSelected() {
        const date = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
        console.log('this is the date', date);
    }
}
