import {Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'fSearch'
})
export class HomePipe implements PipeTransform {
    transform(items: any, parkSearch: string, groundSearch: string, sportSearch: string,statusSearch?:string, citySearch?: string, slotDateSearch?: any){
        if (items && items.length){
            return items.filter(item =>{
                if (parkSearch && item.Park_Name.toLowerCase().indexOf(parkSearch.toLowerCase()) === -1){
                    return false;
                }
                if (groundSearch && item.Ground_Name.toLowerCase().indexOf(groundSearch.toLowerCase()) === -1){
                    return false;
                }
                if (sportSearch && item.Sport_Name.toLowerCase().indexOf(sportSearch.toLowerCase()) === -1){
                    return false;
                }
                if (statusSearch && item.Ref_Slot_Req_Status){
                    if (item.Ref_Slot_Req_Status.toLowerCase().indexOf(statusSearch.toLowerCase()) === -1) {
                        return false;
                    }
                }
                if (citySearch && item.City.toLowerCase().indexOf(citySearch.toLowerCase()) === -1){
                    return false;
                }
                if (slotDateSearch && moment(item.Date).format("YYYY-MM-DD") !== slotDateSearch){
                    return false;
                }
                return true;
           })
        }
        else{
            return items;
        }
    }
}




