import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  // @Input('refId') refId;
  // @Input('resvId') resvId;
  // @Output() backToLanding = new EventEmitter<string>();
  
  feedbackForm: FormGroup;
  fbckFormSubmitted: boolean = false;
  feedback: boolean = false;
  userId: any;
  resvId:any;
  refId: any;
  user = 'false';
  fb_service_types: any[] = [];
  loadingBar: boolean = false;
  
  constructor(
    private formbuilder: FormBuilder,
    private homeService: HomeService,
    private messageService:MessageService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.route.params.subscribe((params)=> {
      if (params.reservation_id !== undefined) {
      this.resvId = params.reservation_id;
      }
    })
  }

  ngOnInit() {
  	this.feedbackForm = this.formbuilder.group({
      'title': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'service': new FormControl(null, Validators.required),
      'regular_user': new FormControl(''),
      'suggestion': new FormControl(''),
      'overall_experience': new FormControl(''),
      'timely_response': new FormControl(''),
      'our_support': new FormControl(''),
      'overall_satisfaction': new FormControl(''),
      'userId': new FormControl('')
    });
    this.userId = localStorage.getItem('id');
    this.getFbServices();
  }

  getFbServices() {
    this.homeService.getFbServices()
      .subscribe((res: any)=> {
        console.log('fb services', res);
        if (!res.msg) {
          let fb_service_types = res;
          this.fb_service_types = fb_service_types;
        }
      });
      
  }

   onFeedback() {
     this.fbckFormSubmitted = true;
     if (this.feedbackForm.valid) {
      this.loadingBar=true;
      this.fbckFormSubmitted = false;
      if (this.feedbackForm.value.regular_user === true) {
        this.feedbackForm.value.userId = this.userId;
      }else{
        this.feedbackForm.value.regular_user = false;
      }
      this.homeService.saveFeedback(this.feedbackForm.value, this.refId, this.resvId)
        .subscribe((res: any)=> {
          console.log('fdbc res', res);
          this.loadingBar=false;
          this.feedbackForm.reset();
          this.messageService.add({ severity: 'success', summary: 'Feedback', detail: 'Feedback Submitted Successfully' });
          // this.backToLanding.next('feedback');
          this.router.navigate(['/home']);
        });
      
    }
  }

  cancelFeedback() {
    // this.backToLanding.next('feedback');
    this.router.navigate(['/home']);
  }

  public get fbckTitle() : string {
    return this.feedbackForm.get("title").value;
  }
  public get fbckDesc() : string {
    return this.feedbackForm.get("description").value;
  }
  public get fbckService() : string {
    return this.feedbackForm.get("service").value;
  }
  public get fbTimelyRes() : string {
    return this.feedbackForm.get("timely_response").value;
  }
  public get fbOvrlExp() : string {
    return this.feedbackForm.get("overall_experience").value;
  }
  public get fbOurSprt() : string {
    return this.feedbackForm.get("our_support").value;
  }
  public get fbOvrlSatisfaction() : string {
    return this.feedbackForm.get("timely_response").value;
  }

}
