import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'support'
})
export class SupportPipe implements PipeTransform {

  transform(items: any, args?: any): any {
    console.log(args);
    if(!items)return null;
    if(args.priority === '' && args.status === '')return items;

    return items.filter((item)=>{
      if (args.status === '' && args.priority !== '' && item.Priority_Id === parseInt(args.priority)) {
        return true;
      }
      if (args.priority === '' && args.status !== '' && item.Status_Id === parseInt(args.status)) {
        return true;
      }
      if (args.status !== '' && args.priority !== '' && item.Status_Id === parseInt(args.status) && item.Priority_Id === parseInt(args.priority)) {
        return true;
      }
    });
  }

}
