import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HomeService } from '../../service/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoginService } from '../../service/user-login.service';
import * as moment from 'moment';



@Component({
  selector: 'app-support-requests',
  templateUrl: './support-requests.component.html',
  styleUrls: ['./support-requests.component.css']
})
export class SupportRequestsComponent implements OnInit, OnChanges {

  suportReqs: any = [];
  fltrPriority: string = "";
  fltrStatus: string = "";
  userId:any;
  moment: any = moment;
  loadingBar=false;
  user = 'false';
  superAdmin='false';
  cityAdmin='false';
  feedback_url:any;
  support_requests: boolean = false;
  sports_details=false;
  show_allSports=false;
  park_slotsDetails=false;
  reportIssue = false;
  feedback=false;

  constructor(private homeService: HomeService,private router: Router,public userService: UserLoginService) { }


  ngOnInit() {
    this.userId = localStorage.getItem('id');
    this.user = localStorage.getItem('user');
    this.getSuportReqs();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.userId = localStorage.getItem('id');
    this.getSuportReqs();
  }

  getSuportReqs() {
    this.loadingBar=true;
    this.homeService.getSuportReqs(this.userId)
    .subscribe((res: any)=> {
      this.loadingBar = false;
      if (res.msg === undefined) {
        let issues = res;
        this.suportReqs = issues;
      }
    });
  }

   gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

   logout () {
    this.user = 'false';
    this.cityAdmin='false';
    this.superAdmin='false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

}
