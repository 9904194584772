import { environment } from './../../../../environments/environment';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserLoginService } from "../../../service/user-login.service";
import { CognitoCallback } from "../../../service/cognito.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements CognitoCallback {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    loadingBar = false;
    address: string;
    website: string;
    email: string;
    errorMessage: string;
    state: any;

    // forgotPassNext = false;

    constructor(public router: Router,
        public userService: UserLoginService) {
        this.errorMessage = null;
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
    }
    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    onNext() {
        // this.forgotPassNext = true;
        this.errorMessage = null;
        this.userService.forgotPassword(this.email, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message == null && result == null) { // error
            this.router.navigate(['/home/forgot-password', this.email]);
        } else { // success
            this.errorMessage = message;
        }
    }
}


@Component({
    selector: 'app-forgot-passwordStep2',
    templateUrl: './forgot-passwordStep2.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordStep2Component implements CognitoCallback, OnInit, OnDestroy {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    loadingBar = false;
    address: string;
    website: string;
    verificationCode: string;
    email: string;
    password: string;
    errorMessage: string;
    state: any;
    private sub: any;
    confirmpassword:any;

    constructor(public router: Router, public route: ActivatedRoute,
        public userService: UserLoginService) {
        console.log("email from the url: " + this.email);
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['email'];

        });
        this.errorMessage = null;
        $('body').addClass('sign_main');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        $('body').removeClass('sign_main');
    }

    onNext() {
        this.loadingBar = true;
        this.errorMessage = null;
        this.userService.confirmNewPassword(this.email, this.verificationCode, this.password, this);

    }
    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    cognitoCallback(message: string) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { // success
            this.loadingBar = false;
            alert('Password has been reset successfully');
            this.router.navigate(['/home/signin']);
        }
    }

}
