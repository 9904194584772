import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NotificationsService } from '../../service/notifications.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notifications: any[] = [];
  moment: any = moment;
  loadingBar: boolean = false;
  unauthorized = false;
  error = false;

  constructor(
    private slotsService: SlotsService,
    private router: Router,
    private notificationService: NotificationsService
  ) {
    this.notificationService.subject.subscribe((response) => {
      console.log("notify count is" + response);
      this.getNotifications();
    })
  }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    if (localStorage.superAdmin == 'true') { return false }

    this.loadingBar = true;
    let city_id = localStorage.getItem("cityId");
    this.slotsService.getNotifications(city_id)
      .subscribe((res: any) => {
        console.log(1212121, res);
        this.loadingBar = false;
        this.notifications = res.notifications;
      },
        err => {
          this.errorHandle(err)
        });
  }

  onNotifClick(notif) {
    if (notif.Entity == "Slot") {
      this.router.navigate(['/admin/slots']);
    } else if (notif.Entity == "CustomSlot") {
      this.router.navigate(['/admin/custom-slot-requests']);
    } else if (notif.Entity == "ClaimSlot") {
      this.router.navigate(['/admin/infoslot-claim-reqs']);
    }
    else if(notif.Entity =="OnBoardSlot"){
      this.router.navigate(['/admin/onboard-slot-requests'])
    }
    let notificationId = notif.Notification_Id;
    this.updateUnreadNotifications(notificationId);
  }

  updateUnreadNotifications(notificationId) {
    this.slotsService.updateUnreadNotifications(notificationId).subscribe((response) => {
      console.log("update Notifications response :" + response);
      this.getNotifications();
      this.slotsService.isNotificationClicked.next(true);
    },
      err => {
        this.errorHandle(err)
      })
  }

  errorHandle(err) {
    if (err.status === 401) {
      this.loadingBar = false;
      this.unauthorized = true;
      // this.router.navigate(['/admin/unauthorized']);
    } else if (err.status === 400 || err.status === 404 || err.status === 500) {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    } else {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    }
  }

}
