import { Component,Input,OnInit,Output,EventEmitter } from '@angular/core';
import {HomeService} from '../../service/home.service';
import {Sport} from '../../models/sport';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { Router } from "@angular/router";
import { CitiesService } from './../../service/cities.service';
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import { environment } from './../../../environments/environment';
import { MessageService } from 'primeng/components/common/messageservice';
declare var google;
declare let $;


@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.css']
})
export class SportsComponent implements OnInit {
 
  public sportsList = [];
  public parksList=[];
  loadingBar = false;
  cityform: FormGroup;
  reqSportForm: FormGroup;
  citiesInfo = [];
  distanceData:any;
  distanceArray=[];
  searchPlace;
  nearestParksData;
  sportsSuggestions:any;
  loadParks=false;
  sports_details=false;
  show_allSports=false;
  loading_spinner=false;
  submitted: boolean = false;
  userId:any;


  @Output()
  sportsDetailClick: EventEmitter<String> = new EventEmitter<String>(); //creating an output event

  constructor(
    public router: Router,
    public homeService: HomeService,
    private _fb: FormBuilder,
    public citiesservice: CitiesService,private msgService: MessageService
  ) { }

  ngOnInit() {
    this.getSports();
    
    this.reqSportForm = this._fb.group({
      sport: [null, [Validators.required]],
      description: [null, [Validators.required]]
    })
    this.userId = localStorage.getItem('id');
  }

  get sportName() {
    return this.reqSportForm.controls.sport.value;
  }

  get sportDesc() {
    return this.reqSportForm.controls.description.value;
  }

  allSports(){
   this.show_allSports=true;
   this.sports_details=false; 
  }

  getsportsDetail(sport){
   this.sportsDetailClick.emit(sport);
  }
  getSports() {
    this.loading_spinner=true;
    this.sportsList = [];
    this.homeService.getSports().subscribe((data) => {
        console.log("sports data response from API call is", data);
        this.sportsList = [];
        if (data.length === 0) {
        }
        else{
          data.forEach((o) => {
            console.log(o);
            let obj = new Sport();
            const temp = {};
            temp['value'] = o.Sport_Id;
            temp['label'] = o.Sport_Name;
            temp['image'] = temp['image'] = 'https://content.fieldsmanager.com/' + environment.logoStage + '/sports-images/' + o.Sport_Id + '.png';
            this.sportsList.push(temp);
          });
        }
        console.log('sport data is: ', this.sportsList);
        this.loading_spinner=false;
      })
  }
  createRequest() {
    this.loadingBar=true;
    this.submitted = true;
    if (this.reqSportForm.valid) {
      let data = this.reqSportForm.value;
      this.homeService.requestSport(data, this.userId)
        .subscribe((res: any)=> {
          this.loadingBar=false;
          this.msgService.add({
            severity: 'success',
            summary: 'Request Sport',
            detail: res.body.msg
          });
        });
      this.submitted = false;
      this.reqSportForm.reset();
      $("#reqSportModal").modal('hide');
    }
    else{
      this.loadingBar=false;
    }
  }

}
