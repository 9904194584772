import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
declare let $;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: any[] = [];
  moment: any = moment;
  serviceName: string = "";
  isFormSubmitted: boolean = false;
  userId: string;
  cityId: string;
  editService: boolean;
  editServiceId: any;
  loadingBar = false;
  error:any;
  unauthorized:any;
  msgs:any;


  constructor(
    private homeService: HomeService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getServices();
    this.userId = localStorage.getItem('CognitoIdentityServiceProvider.3run3nquj30pkpaf1djg6acu8n.rohit.clockDrift');
    this.cityId = sessionStorage.getItem('cityId');
  }

  onServiceAction() {
    if (this.editService) {
      this.onUpdateService();
    } else {
      this.onCreateService();
    }
  }

  onCreateService() {
    this.isFormSubmitted = true;
    if (this.serviceName !== "") {
      this.loadingBar=true;
      let data = {
        service_name: this.serviceName,
        created_by: this.userId,
        city_id: this.cityId
      }
      this.homeService.createService(data)
        .subscribe((res: any)=> {
          this.loadingBar=false;
          this.messageService.add({ 
            severity: 'success', 
            summary: 'Create Service', 
            detail: res.body.msg 
          });
          this.getServices();
          $("#createServiceModal").modal('hide');
          this.isFormSubmitted = false;
        });
    }
  }

  getServices() {
    this.loadingBar=true;
    this.homeService.getServices()
    .subscribe((res: any)=> {
      console.log('get services res', res);
      
      if(res.msg=='No services found!'){
        this.services = [];
      }
      else{
        this.services = res;
      }
      this.loadingBar=false;
    });
  }
  
  openCreateSrvModal() {
    $("#createServiceModal").modal('show');
    this.editService = false;
    this.serviceName = "";
  }

  onEditService(service) {
    this.editService = true;
    this.editServiceId = service.Service_Id
    this.serviceName = service.Service_Name;
    $("#createServiceModal").modal('show');
  }

  onUpdateService() {
    this.isFormSubmitted = true;
    if (this.serviceName !== "") {
      this.loadingBar=true;
      this.isFormSubmitted = false;
      let data = {
        service_id: this.editServiceId,
        service_name: this.serviceName,
      }
      this.homeService.updateService(data)
      .subscribe((res: any)=> {
        this.messageService.add({ 
          severity: 'success', 
          summary: 'Update Service', 
          detail: res.body.msg 
        });
        this.getServices();
        $("#createServiceModal").modal('hide');
        this.loadingBar=false;
      });
    }
  }

  onChangeStatus(type, service_id) {
    this.loadingBar=true;
      let data = {
        service_id: service_id,
        type: type === "Activate" ? "Active": "InActive"
      }
      this.homeService.changeServiceStatus(data)
      .subscribe((res: any)=> {
        this.loadingBar=false;
        this.messageService.add({ 
          severity: 'success', 
          summary: 'Service Status Change', 
          detail: res.body.msg 
        });
        this.getServices();
      });
  }

}
