import { CitiesService } from './../../service/cities.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import {TabViewModule} from 'primeng/tabview';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/System.png';
  basicDetails= [];
  advancedDetails= [];
  premiumDetails= [];
  payAsYouGoDetails = [];
  selectble_plans= [];
  toggleMenu: any;
  sub_active_tab;
  pay_active_tab;

  constructor(public router: Router,
    public citiesService: CitiesService) { }

  ngOnInit() {
    this.getPlans();
    if (this.router.url === '/home/pricing/pay') {
    this.sub_active_tab = false;
    this.pay_active_tab = true;
    } else {
    this.sub_active_tab = true;
    this.pay_active_tab = false;
    }
  }

  handleChange(e) {
    if (e.index === 0) {
    this.router.navigate(['home/pricing', 'subscription']);
    } else if (e.index === 1) {
    this.router.navigate(['home/pricing', 'pay']);
    }
  }
  updateUrl(event) {
    console.log('failed url:', this.somevalue);
    this.somevalue = 'https://content.fieldsmanager.com/System.png';
    console.log('new url:', this.somevalue);
  }
  routeToSignUp() {
    this.router.navigate(['/home/signup','admin'])
  }
  getPlans() {
    this.citiesService.getPlans().subscribe(response => {
      let plans = response.body;
     for ( let i = 0; i < plans.length; i++) {
        this.selectble_plans.push({label: plans[i].Plan_Name, value: plans[i]});
        if (plans[i].Plan_Name === 'Basic') {
          this.basicDetails.push(
              {'noofParks': plans[i].Parks_Limit,
              'groundsperParks': plans[i].Grounds_Per_Park,
              'sportsperGround': plans[i].Sports_Per_Ground,
              'price': plans[i].Price
            })
        } else if (plans[i].Plan_Name === 'Advanced') {
            this.advancedDetails.push(
                {'noofParks': plans[i].Parks_Limit,
                'groundsperParks': plans[i].Grounds_Per_Park,
                'sportsperGround': plans[i].Sports_Per_Ground,
                'price': plans[i].Price})
        } else if (plans[i].Plan_Name === 'Premium') {
          this.premiumDetails.push(
            {'noofParks': plans[i].Parks_Limit,
            'groundsperParks': plans[i].Grounds_Per_Park,
            'sportsperGround': plans[i].Sports_Per_Ground,
            'price': plans[i].Price})
        } else {
          this.payAsYouGoDetails.push({
            'noofParks': plans[i].Parks_Limit,
            'groundsperParks': plans[i].Grounds_Per_Park,
            'sportsperGround': plans[i].Sports_Per_Ground,
            'price': plans[i].Price
          })
        }
      }
    })
  }
}
