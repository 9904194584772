import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../../service/user-login.service';
import { Callback, CognitoUtil, LoggedInCallback } from '../../service/cognito.service';
import { UserParametersService } from '../../service/user-parameters.service';
import { Router } from '@angular/router';
import { ParkModel } from '../../models/park';
import { ParksService } from '../../service/parks.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
declare let $;
declare var google;



@Component({
    selector: 'app-parks',
    templateUrl: './parks.component.html',
    styleUrls: ['./parks.component.css']
})
export class ParksComponent implements LoggedInCallback, OnInit {
    public parameters: Array<Parameters> = [];
    addParkDialog = false;
    editParkDialog = false;
    loadingBar = false;
    unauthorized = false;
    error = false;
    parkData = new ParkModel();
    parkform: FormGroup;
    noParks: boolean;
    parksList = [];
    msgs: any;
    checkValidation;
    fileToUpload: File = null;
    fileUploadDialog=false;
    preSignedUrl:any;
    ParkId;
    isParkAdded : boolean = false;
    modal_header : string;
    modal_body : string;
    modal_type: string;
    newlyAddedParkId : any;
    newlyAddedParkName : any;


    constructor(public router: Router,
        public userService: UserLoginService,
        public userParams: UserParametersService,
        public cognitoUtil: CognitoUtil,
        public parksservice: ParksService,
        private formbuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        private messageService: MessageService) {
        this.userService.isAuthenticated(this);
        this.noParks = false;
        console.log('In ParksComponent');
    }


    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            // this.router.navigate(['/home/login']);
            this.router.navigate(['/home/signin']);
        } else {
            this.userParams.getParameters(new GetParametersCallback(this, this.cognitoUtil));
        }
    }

    ngOnInit() {
        this.parksList = [];
        // Resrtict for no parks
        if(localStorage.getItem('repName') !== 'madhu m'){
            this.getParks();
        } else {
            this.noParks = true;
        }
        // Resrtict for no parks
        //this.getParks();
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
        zipPattern = '[0-9]{5}';
        this.parkform = this.formbuilder.group({
            'parkName': new FormControl('', Validators.required),
            'parkStreet': new FormControl('', Validators.required),
            'parkCity': new FormControl('', Validators.required),
            'parkState': new FormControl('', Validators.required),
            'parkZipCode': new FormControl('', [Validators.required,Validators.pattern(zipPattern)]),
            'parkWebsite': new FormControl('', [Validators.required,Validators.pattern(reg)]),
            'parkisCancel':new FormControl('', Validators.required),
            'parkAgreement':new FormControl()
        });
        this.placesScript();
    }

    handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    }

     cancelFileUpload() {
        let element = document.getElementById('upld-fle');
        element.classList.remove('park_modal_bg');
        this.fileUploadDialog = false;
    }


    uploadfileAWSS3(){
        if(this.fileToUpload!=null)
        {
            this.loadingBar=true;
            this.cancelFileUpload();
            this.parksservice.uploadfile(this.preSignedUrl,this.fileToUpload).subscribe((response: any) => {
                   this.loadingBar=false;
                   console.log("file upload response",response);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Parks Update',
                        detail: 'Park Agreement uploaded successfully'
                    });
                
                    this.parksservice.addParkAgreement(this.ParkId,this.preSignedUrl).subscribe((res: any) => {
                        console.log("res",res);
                         this.getParks();
                    })
                   
            });
        }
    }

    placesScript(){
        var options = {
        types: ['(cities)'],
        componentRestrictions: {
        country: 'us'
        }
        };
        var input = document.getElementById('searchTextField');
        var autocomplete = new google.maps.places.Autocomplete(input,options);
        google.maps.event.addDomListener(window, 'load');
         google.maps.event.addListener(autocomplete, 'place_changed', () => {
        var place = autocomplete.getPlace();
        console.log('place', place);
        for (let i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types.indexOf("administrative_area_level_1") > -1) {
                console.log('long name', place.address_components[i].long_name);
                this.parkform.patchValue({
                    "parkState": place.address_components[i].long_name
                })
            }            
        }
        localStorage.setItem('searchPlace',place.formatted_address)
        }); 
    }

    getParks() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        this.parksList = [];
        const params = {
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        this.parksservice.getParks(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                if (response.body.length === 0) {
                    this.noParks = true;
                    this.loadingBar = false;
                    if (localStorage.getItem('defaultParkId') === 'temp') {
                        this.messageService.add({
                            severity: 'info',
                            summary: 'Please add a new park',
                            detail: ''
                        });
                    } else {
                        localStorage.removeItem('defaultParkId');
                        this.messageService.add({
                            severity: 'info',
                            summary: 'Parks Update',
                            detail: 'No parks exist'
                        });
                    }
                } else {
                    this.noParks = false;
                    console.log('get parks data', response.body);
                    response.body.forEach((o) => {
                        const temp = {};
                        temp['parkId'] = o['Park_Id'];
                        temp['parkname'] = o['Park_Name'];
                        temp['address'] = o['Street'] + ', ' + o['City'] + ', ' + o['State'] + ', ' + o['Zip_Code'];
                        temp['street'] = o['Street'];
                        temp['city'] = o['City'];
                        temp['state'] = o['State'];
                        temp['zipCode'] = o['Zip_Code'];
                        temp['website'] = o['Website'];
                        temp['cityId'] = o['City_Id'];
                        temp['parkStatus'] = o['Park_Status'];
                        temp['Park_Agreement']=o['Park_Agreement']
                        this.parksList.push(temp);
                    });
                    this.loadingBar = false;
                    console.log('parksList', this.parksList);
                }
            }
        },
            err => {
                this.errorHandle(err)
            });
    }
    confirmDelete(selectedParkRow) {
        console.log('in confirmation service popup method', selectedParkRow);
        this.confirmationService.confirm({
            message: 'Deleting a park will delete all associated grounds, reservations, schedules and slots. \n Are you sure?',
            header: 'Delete Park',
            // icon: 'fa fa-question-circle',
            accept: () => {
                this.deletePark(selectedParkRow);
            },
            reject: () => { }
        })
    }
    deletePark(inputParams) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        const outputParams = {
            'parkId': inputParams.parkId,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        console.log('params', outputParams);
        this.parksservice.deletePark(outputParams).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                // if (response.body.hasOwnProperty('affectedRows')) {
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Parks Update',
                    detail: 'Park deleted successfully'
                });
                this.getParks();
                if(inputParams.parkId == localStorage.getItem('defaultParkId')){
                    localStorage.setItem('defaultParkId',null)
                    localStorage.setItem('defaultParkName',null)
                    this.router.navigate(['/parkDefaults']);
                }
                // }
            } else if (response.status === 201) {
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Parks Update',
                    detail: 'Park deleted, Error deleting all associated entities'
                });
                this.getParks();
            }
        }, err => {
            this.errorHandle(err)
        });
    }
    onClickEditPark(inputParams) {
        let element = document.getElementById('edit-park');
        element.classList.add('park_modal_bg');
        this.editParkDialog = true;
        this.parkData.parkName = inputParams.parkname;
        this.parkData.address = inputParams.address;
        this.parkData.street = inputParams.street;
        this.parkData.city = inputParams.city;
        this.parkData.state = inputParams.state;
        this.parkData.zipCode = inputParams.zipCode;
        this.parkData.parkId = inputParams.parkId;
        this.parkData.website = inputParams.website;
        if(inputParams.Park_Agreement!=null){
          console.log("11111");
          $("#agrmnt_chck").prop("checked", true);
          this.parkform.controls['parkAgreement'].setValue(true);
        }
        console.log('this is the selected park data in ts file', this.parkData);
    }
    onClickAddPark() {
        this.placesScript();
        let element = document.getElementById('add-park');
        element.classList.add('park_modal_bg');
        this.addParkDialog = true;
        this.parkform.reset();
        this.checkValidation=false;
    }

    addPark() {
        if (( this.parkData.parkName === undefined || this.parkform.controls['parkName'].status==='INVALID')|| (this.parkData.street==undefined || this.parkform.controls['parkStreet'].status==='INVALID')||
        (this.parkData.city==undefined || this.parkform.controls['parkCity'].status==='INVALID') ||
        (this.parkData.state==undefined || this.parkform.controls['parkState'].status==='INVALID')||
        (this.parkData.zipCode==undefined || this.parkform.controls['parkZipCode'].status==='INVALID' || !this.parkform.controls['parkZipCode'].valid)||
        (this.parkData.website==undefined || this.parkform.controls['parkWebsite'].status==='INVALID' || !this.parkform.controls['parkWebsite'].valid)){
        this.checkValidation = true;
        }
        else{
        this.loadingBar = true;
        // this.cancelAddPark();
        this.checkValidation = false;
        this.cognitoUtil.refresh();
        console.log('These are the new park params', this.parkData);
        let params = {
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'Agreement':this.parkform.controls['parkAgreement'].value==null? false : this.parkform.controls['parkAgreement'].value
        };
        this.parksservice.addPark(this.parkData, params).subscribe((response:any) => {
            console.log('The response is', response);
            if (response.status === 200) {
                if(response.body.httpStatus && response.body.httpStatus === 400){
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Unable to add park',
                        detail: response.body.message
                    });
                    this.loadingBar = false;
                }
                else{
                    // this.isParkAdded = true;
                    this.cancelAddPark()
                    this.getParks();
                    $('#makeParkDefaultmodal').modal('show');
                    this.newlyAddedParkId = response.body.park.parkId;
                    this.newlyAddedParkName = this.parkData.parkName;
                    // this.modal_header ="Create New Ground Alert";
                    // this.modal_body = "There are no grounds onboarded under the &nbsp;"+this.parkData.parkName.bold()+"&nbsp;.Would you like to create one?"
                    // this.modal_type = "grounds"
                    if (localStorage.getItem('defaultParkId') === 'temp') {
                        localStorage.setItem('defaultParkId', response.body['insertId']);
                        localStorage.setItem('defaultParkName', this.parkData.parkName);
                    }
                    this.loadingBar = false;
                    this.parkform.reset();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Parks Update',
                        detail: 'Park added successfully'
                    });
                    if(response.body.park.signed_url){
                        this.preSignedUrl=response.body.park.signed_url;
                        this.ParkId=response.body.park.parkId;
                        let element = document.getElementById('upld-fle');
                        element.classList.add('park_modal_bg');
                        this.fileUploadDialog = true;
                    }
                }
                // this.getParks();
                // this.cancelAddPark();
            }
        }, err => {
            this.errorHandle(err)
        });
     }
     
    }
    cancelAddPark() {
        let element = document.getElementById('add-park');
        element.classList.remove('park_modal_bg');

        this.addParkDialog = false;
        // this.parkform.reset();
    }
    editPark() {
        if (( this.parkData.parkName === undefined || this.parkform.controls['parkName'].status==='INVALID')|| (this.parkData.street==undefined || this.parkform.controls['parkStreet'].status==='INVALID')||
        (this.parkData.city==undefined || this.parkform.controls['parkCity'].status==='INVALID') ||
        (this.parkData.state==undefined || this.parkform.controls['parkState'].status==='INVALID')||
        (this.parkData.zipCode==undefined || this.parkform.controls['parkZipCode'].status==='INVALID')||
        (this.parkData.website==undefined || this.parkform.controls['parkWebsite'].status==='INVALID')){
        this.checkValidation = true;
        }
        else{
        this.loadingBar = true;
        this.checkValidation = false;
        this.cognitoUtil.refresh();
        // this.cancelEditPark();
        console.log('These are the edit park params', this.parkData);
        let params = {
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'Agreement':this.parkform.controls['parkAgreement'].value==null? false : this.parkform.controls['parkAgreement'].value
        };
        this.parksservice.editPark(this.parkData, params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
               if(response.body.httpStatus && response.body.httpStatus === 400){
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Unable to edit park',
                        detail: response.body.message
                    });
                    this.loadingBar = false;
                }
                else{
                    this.loadingBar = false;
                    this.parkform.reset();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Parks Update',
                        detail: 'Park edited successfully'
                    });
                    this.cancelEditPark();
                    this.getParks();
                    this.parkform.reset();
                    // this.cancelEditPark();
                    if(response.body.park.signed_url){
                        this.preSignedUrl=response.body.park.signed_url;
                        this.ParkId=response.body.park.parkId;
                        let element = document.getElementById('upld-fle');
                        element.classList.add('park_modal_bg');
                        this.fileUploadDialog = true;
                    }
                }
                
            }
        }, err => {
            this.errorHandle(err)
        });
     }
    }
    cancelEditPark() {
        let element = document.getElementById('edit-park');
        element.classList.remove('park_modal_bg');
        this.editParkDialog = false;
        // this.parkform.reset();
    }
    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        } else {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        }
    }

    makeDefault() {
        this.isParkAdded = true;
        localStorage.setItem('defaultParkId',this.newlyAddedParkId );
        localStorage.setItem('defaultParkName', this.newlyAddedParkName);
        this.parksservice.isNewParkAdded.next(true)
        this.modal_header ="Create New Ground Alert";
        this.modal_body = "There are no grounds onboarded under the &nbsp;"+this.newlyAddedParkName.bold()+"&nbsp;.Would you like to create one?"
        this.modal_type = "grounds"
    }

    onCancleDefault() {
        this.isParkAdded = true;
        $('#makeParkDefaultmodal').modal('hide')
        this.modal_header ="Create New Ground Alert";
        this.modal_body = "There are no grounds onboarded under the &nbsp;"+this.newlyAddedParkName.bold()+"&nbsp;.Would you like to create one?"
        this.modal_type = "grounds"
    }

}

export class Parameters {
    name: string;
    value: string;
}

export class GetParametersCallback implements Callback {

    constructor(public me: ParksComponent, public cognitoUtil: CognitoUtil) {

    }

    callback() {

    }

    callbackWithParam(result: any) {

        for (let i = 0; i < result.length; i++) {
            let parameter = new Parameters();
            parameter.name = result[i].getName();
            parameter.value = result[i].getValue();
            this.me.parameters.push(parameter);
        }
        let param = new Parameters()
        param.name = 'cognito ID';
        param.value = this.cognitoUtil.getCognitoIdentity();
        this.me.parameters.push(param)
    }
}
