import { Injectable } from '@angular/core';
// import { webSocket } from "rxjs/webSocket";
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { retry } from 'rxjs/operators';


@Injectable()
export class NotificationsService {
  subject: any;
  notificationsCount: number;
  constructor() {
    let cityId = localStorage.getItem("cityId");
    // this.subject = webSocket(environment.webSocketUrl + "?City_Id=" + cityId);
    // this.notificationsCount = this.subject.subscribe(
    //   msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
    //   err => {
    //     console.log(err)
    //   }, // Called if at any point WebSocket API signals some kind of error.
    //   () => {
    //     console.log('complete')
    //   } // Called when connection is closed (for whatever reason).
    // );
  }
}
