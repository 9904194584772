import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
declare let $;

@Component({
  selector: 'app-sport-requests',
  templateUrl: './sport-requests.component.html',
  styleUrls: ['./sport-requests.component.css']
})
export class SportRequestsComponent implements OnInit {
  
  sport_requests=[];
  moment: any = moment;
  loadingBar = false;
  error:any;
  unauthorized:any;
  msgs:any;
  serviceName:any;
  isFormSubmitted:boolean=false;
  editService:any;



  constructor( private homeService: HomeService,private messageService: MessageService) { }

  ngOnInit() {
    this.sportRequests();
  }

  sportRequests() {
    this.loadingBar=true;
    this.homeService.getsportRequests()
    .subscribe((res: any)=> {
      this.loadingBar=false;
      console.log('get sport requests::', res);
      if(res.msg=='No sport requests found!'){
       this.sport_requests=[];
      }
      else{
        this.sport_requests = res;
      }
    });
  }

  onChangeStatus(type, Sport_Req_Id) {
    this.loadingBar=true;
    let data = {
      Sport_Req_Id: Sport_Req_Id,
      type: type === "Activate" ? "Active": "InActive"
    }
    this.homeService.changeSportReqStatus(data)
    .subscribe((res: any)=> {
      this.loadingBar=false;
      this.messageService.add({ 
        severity: 'success', 
        summary: 'Sport Request Status Change', 
        detail: res.body.msg 
      });
      this.sportRequests();
    });
}

}
