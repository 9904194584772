import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../../../service/user-registration.service";
import { UserLoginService } from '../../../service/user-login.service';
import { CognitoCallback } from "../../../service/cognito.service";
import {environment} from '../../../../environments/environment';
var popupTools = require('popup-tools');
import * as jwt_decode from 'jwt-decode';

export class RegistrationUser {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    phone_number: string;
    password: string;
    repassword: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'app-registration',
    templateUrl: './registration.html',
    styleUrls: ['../../../../assets/slotsAssets/css/style.css']
})
export class RegisterComponent implements CognitoCallback {
    registrationUser: RegistrationUser;
    router: Router;
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    errorMessage: string;
    cityName: string;
    address: string;
    mfaStep: any;
    website: string;
    loadingBar = false;
    urlType: string;
    user = 'false';

    constructor(public userRegistration: UserRegistrationService,public userService: UserLoginService,router: Router) {
        this.router = router;
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
        console.log('LoginComponent constructor');
        this.onInit();
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    // single sign on auth
    loginWithAuthProvider(authType) {
        var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        if (authType === 'facebook') {
        URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
        } else if (authType === 'google') {
        URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
        } else if (authType === 'amazon') {
        URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
        }
        popupTools.popup(URL, 'My Popup', { width: 400, height: 450 }, ((err) => {
        console.log('window closed::');
        // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
        if (localStorage.authToken) {
        this.cognitoCallbackAuth(null, localStorage.authToken);
        }
        }))
    }
    // single sign on auth

    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
    }

    onRegister() {
        this.errorMessage = null;
        this.userRegistration.register(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) {// error
            this.errorMessage = message;
            console.log('result: ', this.errorMessage);
        } else { // success
            // move to the next step
            this.userRegistration.userAddToGroup(this.registrationUser).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    // this.loadingBar = false;
                    console.log('user registration successfully completed! with 200');
                }else if (response.status === 201) {
                    console.log('user registration successfully completed! with 201');
                }
            }, err => {
                this.errorHandle(err)
            });
            console.log('redirecting');
            this.router.navigate(['/home/accountActivation', result.user.username]);
        }
    }

    cognitoCallbackAuth(message: string, result: any) {
        if (message != null) { // error
            this.loadingBar = false;
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
        } else { // success
            let tokenInfo;
            if (result.idToken === undefined) {
                tokenInfo = this.getDecodedAccessToken(result);
                console.log('this is the id token ###################', tokenInfo);
                sessionStorage.setItem('authorizationToken', result);
            } else {
                tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
            }
            let params = sessionStorage.getItem('authorizationToken');
            console.log('This is the token info', tokenInfo);
            if (tokenInfo['cognito:groups'][0] === 'SystemAdmin') {
                console.log('this is the console for system admin');
                localStorage.setItem('superAdmin', 'true');
                // this.loadingBar = false;
                this.router.navigate(['/admin/cityRequests']);
            } else if (tokenInfo['cognito:groups'][0].includes('Facebook') && !(tokenInfo['cognito:groups'][0].includes('User'))) {
                console.log("facebook Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                });
                if ( this.urlType === 'custom') {
                 this.router.navigate(['home/customSlot'])
                } else {
                  this.router.navigate(['/home/land']);
                }
            } else if (tokenInfo['cognito:groups'][0].includes('Google') && !(tokenInfo['cognito:groups'][0].includes('User'))) {
                console.log("google Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                });
                if ( this.urlType === 'custom') {
                 this.router.navigate(['home/customSlot'])
                } else {
                  this.router.navigate(['/home/land']);
                }
            } else if (tokenInfo['cognito:groups'][0].includes('LoginWithAmazon') && !(tokenInfo['cognito:groups'][0].includes('User'))) {
                console.log("amazon Data::",tokenInfo);
                console.log("amazon Data::",tokenInfo['name']);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                localStorage.setItem('firstName', tokenInfo['name']);
                // localStorage.setItem('lastName', tokenInfo['family_name']);
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                });
                if ( this.urlType === 'custom') {
                 this.router.navigate(['home/customSlot'])
                } else {
                  this.router.navigate(['/home/land']);
                }
            } else if (tokenInfo['cognito:groups'][0].includes('User')) {
                console.log('************ User is logged in ***********');
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                });
                if ( this.urlType === 'custom') {
                 this.router.navigate(['home/customSlot'])
                } else {
                  this.router.navigate(['/home/land']);
                }
            } else {
                this.userService.getAdminDetails(params).subscribe(data => {
                    console.log('this is the output from get admin details:', data);
                    localStorage.setItem('repId', data[0]['Rep_Id']);
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                    if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        // this.loadingBar = false;
                        this.router.navigate(['/parkDefaults']);
                    } else {
                        localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                        localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        this.router.navigate(['/admin']);
                    }
                });
            }
        }
    }

    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;

            // this.router.navigate(['/admin/error']);
        }
    }
}
