import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UserFormService {
    headers: any;
    constructor(private http: HttpClient) { }
    storeTransaction(data, reservationId): any {
        console.log('this is the paypal data in add receipt service', data);
        let params = new HttpParams();
        params = params.append('reservationId', reservationId);
        params = params.append('receipt', data);
        // // params = params.append('ack', data['ack']);
        // params = params.append('data_id', data['id']);
        // params = params.append('data_intent', data['intent']);
        // params = params.append('data_state', data['state']);
        // params = params.append('data_cart', data['cart']);
        // params = params.append('data_create_time', data['create_time']);
        // params = params.append('data_payer_payment_method', data['payer']['payment_method']);
        // // params = params.append('data_payer_status', data['payer']['payer_status']);
        // params = params.append('data_payer_payer_info_email', data['payer']['payer_info']['email']);
        // params = params.append('data_payer_payer_info_first_name', data['payer']['payer_info']['first_name']);
        // params = params.append('data_payer_payer_info_middle_name', data['payer']['payer_info']['middle_name']);
        // params = params.append('data_payer_payer_info_last_name', data['payer']['payer_info']['last_name']);
        // params = params.append('data_payer_payer_info_payer_id', data['payer']['payer_info']['payer_id']);
        // params = params.append('data_payer_payer_info_country_code', data['payer']['payer_info']['country_code']);
        // params = params.append('data_transactions_amount_total', data['transactions'][0]['amount']['total']);
        // params = params.append('data_transactions_amount_currency', data['transactions'][0]['amount']['currency']);
        // // params = params.append('data_transactions_amount_details', JSON.stringify(data['transactions'][0]['amount']['details']));
        // params = params.append('data_transactions_related_resources_sale_id', data['transactions'][0]['related_resources'][0]['sale']['id']);
        // params = params.append('data_transactions_related_resources_sale_state', data['transactions'][0]['related_resources'][0]['sale']['state']);
        // params = params.append('data_transactions_related_resources_sale_payment_mode', data['transactions'][0]['related_resources'][0]['sale']['payment_mode']);
        // params = params.append('data_transactions_related_resources_sale_protection_eligibility', data['transactions'][0]['related_resources'][0]['sale']['protection_eligibility']);
        // params = params.append('data_transactions_related_resources_sale_parent_payment', data['transactions'][0]['related_resources'][0]['sale']['parent_payment']);
        // params = params.append('data_transactions_related_resources_sale_create_time', data['transactions'][0]['related_resources'][0]['sale']['create_time']);
        // params = params.append('data_transactions_related_resources_sale_update_time', data['transactions'][0]['related_resources'][0]['sale']['update_time']);
        // params = params.append('data_transactions_related_resources_sale_amount_total', data['transactions'][0]['related_resources'][0]['sale']['amount']['total']);
        // params = params.append('data_transactions_related_resources_sale_amount_currency', data['transactions'][0]['related_resources'][0]['sale']['amount']['currency']);
        // params = params.append('data_transactions_related_resources_sale_amount_details_subtotal', data['transactions'][0]['related_resources'][0]['sale']['amount']['details']['subtotal']);
        // // params = params.append('meta_calc', data['meta_calc']);
        // // params = params.append('meta_rlog', data['meta_rlog']);
        // // params = params.append('server', data['server']);
        console.log('these are the service call params for store Transaction Details', params);
        const url = environment.apiUrl + '/reservations/transactiondetails';
        return this.http.post<any>(url, '', { observe: 'response', params: params});
        // return this.http.post(url, '', { params: params });
    }
    getSports(data): Observable<any> {
        // const cityId = data;
        let params = new HttpParams().set('cityId', data);
        // const headers = new HttpHeaders().set('Content-Type', 'application/json');
        // const options = { params: params };
        const url = environment.apiUrl + '/groundssports/sportsbycity';
        return this.http.get(url, { params: params });
    }
    
     getGroundsData(data): Observable<any> {
        // const cityId = data;
        let params = new HttpParams().set('cityId', data);
        // const headers = new HttpHeaders().set('Content-Type', 'application/json');
        // const options = { params: params };
        const url = environment.apiUrl + '/groundssports/groundsbycity';
        return this.http.get(url, { params: params });
    }

    getSportsData(data): Observable<any> {
        console.log('this is the data from get Sports by ground', data);
        let params = new HttpParams();
        //let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        params = params.append('groundId', data.groundId);
        params = params.append('cityId', data.cityId);
        const options = { params: params};
        const url = environment.apiUrl + '/groundssports/ground';
        return this.http.get<any>(url, { observe: 'response', params: params});
    }

    getAdminEMails(cityId): Observable<any> {
        let params = new HttpParams();
        params = params.append('cityId', cityId);
        console.log('these are the service call params for get Slots by Sport and Ground', params);
        const url = environment.apiUrl + '/city/getadminemails';
        return this.http.get(url, { params: params });
    }
    getReservationStatus(email, reservationId): Observable<any> {
        let params = new HttpParams();
        params = params.append('email', email);
        params = params.append('reservationId', reservationId);
        console.log('these are the service call params for get Slots by Sport and Ground', params);
        const url = environment.apiUrl + '/reservations/getstatus';
        return this.http.get(url, { params: params });
    }

    getGrounds(data): Observable<any> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('sportId', data.sportId);
        console.log('these are the service call params for get Grounds by SportId', params);
        const url = environment.apiUrl + '/groundssports/groundsbysportandcity';
        return this.http.get(url, { params: params });
    }

    public getSlotsAvailable(data): Observable<any> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('sportId', data.sportId);
        params = params.append('groundId', data.groundId);
        params = params.append('startDate', data.startDate);
        params = params.append('endDate', data.endDate);
        console.log('these are the service call params for get Slots by Sport and Ground', params);
        const url = environment.apiUrl + '/slots/slotsbygroundandsport';
        return this.http.get(url, { params: params });
    }

    public submitReservation(data): any {
        let params = new HttpParams();
        params = params.append('userId', data.userId);
        params = params.append('phoneNumber', data.phoneNumber);
        params = params.append('slotId', data.slotId);
        params = params.append('slot', data.slot);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('slotFIFO', data.slotFIFO);
        params = params.append('slotStatus', data.slotStatus);
        params = params.append('reservationDate', data.reservationDate);
        params = params.append('cityId', data.cityId);
        params = params.append('groundId', data.groundId);
        params = params.append('sportId', data.sportId);
        params = params.append('createdAt', data.createdAt);
        params = params.append('firstName', data.firstName);
        params = params.append('lastName', data.lastName);
        params = params.append('email', data.email);
        params = params.append('teamName', data.teamName);
        params = params.append('residentOf', data.residentOf);
        params = params.append('stage', data.stage);
        params = params.append('authorizationId', data.authorizationId);
        params = params.append('paypalAmount', data.paypalAmount);
        params = params.append('orderId', data.orderId);
        params = params.append('cityDomain', sessionStorage.getItem('cityDomain'));
        params = params.append('Payment_Mode',data.paymentMode);
        console.log('these are the service call params for add Reservations', params);
        const url = environment.apiUrl + '/reservations';
        return this.http.post(url, '', { params: params });
    }
    public submitCustomRequest(data): any {
        let params = new HttpParams();
        params = params.append('sportId', data.sportId);
        params = params.append('groundId', data.groundId);
        params = params.append('reservationDate', data.reservationDate);
        params = params.append('userId', localStorage.getItem('id'));
        params = params.append('parkId', data.parkId);
        params = params.append('startTime', data.startTime);
        params = params.append('endTime', data.endTime);
        params = params.append('cityId', data.cityId);
        params = params.append('phoneNumber', data.phoneNumber);
        params = params.append('createdAt', data.createdAt);
        params = params.append('firstName', data.firstName);
        params = params.append('lastName', data.lastName);
        params = params.append('email', data.email);
        params = params.append('teamName', data.teamName);
        params = params.append('residentOf', data.residentOf);
        params = params.append('stage', data.stage);
        params = params.append('cityDomain', sessionStorage.getItem('cityDomain'));
        console.log('these are the service call params for add Reservations', params);
        const url = environment.apiUrl + '/slots/customrequests';
        return this.http.post(url, '', { params: params });
    }
}
