import { environment } from './../../../../environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRegistrationService } from '../../../service/user-registration.service';
import { UserLoginService } from '../../../service/user-login.service';
import { LoggedInCallback } from '../../../service/cognito.service';
import { HomeService } from '../../../service/home.service';

@Component({
    selector: 'app-logout',
    template: ''
})
export class HomeLogoutComponent implements LoggedInCallback {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    constructor(public router: Router,
        public userService: UserLoginService) {
        this.userService.isAuthenticated(this)
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.userService.logout();
            this.router.navigate(['/home']);
        }

        this.router.navigate(['/home/signin']);
    }
    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
}

@Component({
    selector: 'app-confirm-signup',
    templateUrl: './confirm-signup.component.html',
    styleUrls: ['./confirm-signup.component.css']

})
export class ConfirmSignupComponent implements OnInit, OnDestroy {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    state: string;
    address: string;
    website: string;
    confirmationCode: string;
    email: string;
    errorMessage: string;
    private sub: any;
    tm_invt_id: any;
    user_id: any;

    constructor(public regService: UserRegistrationService, public router: Router, public route: ActivatedRoute, private homeService: HomeService) {
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];
            this.tm_invt_id = this.route.snapshot.queryParams['tm_invt_id'];
            this.user_id = params['userId'];

            localStorage.setItem("reg_uname", this.email);
            localStorage.setItem("reg_uid", this.user_id);
            
        });
        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    onAccountActivation() {
        this.errorMessage = null;
        this.regService.accountActivation(this.email, this.confirmationCode, this);
        if(this.tm_invt_id){
            this.homeService.joinTeamCnfm(this.tm_invt_id, this.user_id).subscribe((res: any) => {
                console.log('API::', res);
            })
        }
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log('message: ' + this.errorMessage);
        } else { // success
            // move to the next step
            console.log('Moving to admin');
            // this.configs.curUser = result.user;
            if (localStorage.getItem('superAdmin') === 'true') {
                this.router.navigate(['/admin/cityRequests']);
            } else {
                // this.router.navigate(['/admin']);
                alert('Account is activated successfully, please login with new password to continue!');
                this.router.navigate(['/admin/logout']);
            }
        }
    }
}





