import { Component, OnInit } from '@angular/core';
import { AdminsService } from '../../service/admins.service';
import {MessageService} from 'primeng/components/common/messageservice';
import { HomeService } from '../../service/home.service';

declare let $;

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  email: string;
  isempty_email: boolean =false;
  isvalid_email: boolean =true;
  form_submitted: boolean =false;
  field_managers: any[] = [];
  loadingBar: boolean = false;
  partnerId:any;
  partnerName:any;
  partnerEmail:any;
  current_fm: any;


  constructor(
    public adminsservice: AdminsService,
    private messageService: MessageService,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    this.partnerId=localStorage.getItem('partnerId');
    this.partnerName=localStorage.getItem('repName');
    this.partnerEmail=localStorage.getItem('partner_email')
    this.getPartnerInvitations();
    
    this.adminsservice.updatePartnerStatusToCityAdmin(this.partnerId,this.partnerEmail).subscribe((response:any) => {
      console.log("response",response);
    })
  }

  getPartnerInvitations(){
    this.loadingBar=true;
    this.adminsservice.getPartnerInvitations(this.partnerId).subscribe((response:any) => {
       console.log("get Partner Invitations::",response);
       this.loadingBar=false;
       if(response.msg==undefined){
        this.field_managers=response;
       }
       else{
         this.field_managers=[];
       }
    })
  }

  sendEmail() {
    this.loadingBar=true;
    this.form_submitted = true;
    if (this.email === "" || this.email === undefined) {
      this.isempty_email = true;
      this.loadingBar=false;
    } else {
        if(this.isvalid_email) {
          this.adminsservice.sendInvitation(this.partnerId,this.partnerName,this.email).subscribe((response:any) => {
          this.isempty_email = false;
          this.loadingBar=false;
          console.log("inviattion sent::",response);
          this.messageService.add({ severity: 'success', summary: 'Invite', detail: response.body.msg });
          $("#inviteFieldsOwner .close").click()
          this.getPartnerInvitations();
          })
        } else {
          this.loadingBar=false;
        }
    }
  }

  onChangeEmail() {
    console.log('===', this.validateEmail(this.email));
    if (this.validateEmail(this.email)) {
      this.isvalid_email = true;
    } else {
      this.isvalid_email = false;
    }
    if (this.email === "" || this.email === undefined) {
      this.isempty_email = true;
    } else {
      this.isempty_email = false;
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  openDelModal(fm_info) {
    this.current_fm = fm_info;
    $('#deleteFieldsOwner').modal('show');
  }

  removePartner() {
    let data = {
      partner_inv_id: this.current_fm.Partner_Inv_Id,
      partner_id: this.current_fm.InvitedBy,
      email: this.current_fm.InvitedTo_Email
    }
    $('#deleteFieldsOwner').modal('hide');
    this.homeService.removePartner(data)
    .subscribe((res: any) => {
      console.log('delete partner res', res);
      this.getPartnerInvitations();
    });
    
  }

}
