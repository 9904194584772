import { UserLoginService } from './../service/user-login.service';
import { CognitoUtil } from './../service/cognito.service';
import { environment } from './../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserFormService} from '../service/user-form.service';
import {CitiesService} from '../service/cities.service'
import {Sport} from '../models/sport';
import { DropdownModule, Dropdown } from 'primeng/primeng';
import {Ground} from '../models/ground';
import {Validators, FormControl, FormGroup, FormBuilder, AsyncValidator} from '@angular/forms';
import {Reservation} from '../models/reservation';
import * as moment from 'moment';
import {Moment} from 'moment';
import { ActivatedRoute  } from '@angular/router';
import { NumberFormatStyle } from '@angular/common';
import {GMapModule} from 'primeng/gmap';
declare let $;
declare var google;
import * as jwt_decode from 'jwt-decode';
var popupTools = require('popup-tools');




declare let AWS: any;
declare let AWSCognito: any;
declare let paypal: any;
declare let paypal_auth:any;


@Component({
    selector: 'app-landinghome',
    templateUrl: './landinghome.html',
    styleUrls: ['../../assets/slotsAssets/css/style.css']
})
export class HomeLandingComponent implements OnInit {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityNumber: number;
    opendates = [];
    openmonths = [];
    loginPage: boolean = false;
    randomVal: any;
    amenityList: boolean = false;
    optionalList: boolean = false;
    captchaResolved: boolean = false;
    // waitdates = [{day: 21, month: 9, year: 2018}];
    // closeddates = [20];
    // value = '17';
    public sportsList = [];
    public groundsList = [];
    timeSlots = [];
    startDate: any = moment().format('YYYY-MM-DD');
    endDate: any = moment(this.startDate).endOf('month');
    loadingBar = false;
    conflictResponse = {};
    reservationMessageError = null;
    reservationMessageOverlap = null;
    reservationMessageSuccess = null;
    reservationData = new Reservation();
    selectedSport: any;
    selectedGround: any;
    slotDetailsPage: boolean;
    userDetailsPage: boolean;
    termsCheck: boolean;
    public terms: boolean;
    weekdayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    currentDate = new Date();
    currentDay: string;
    currentWeek: string;
    minDate: Moment;
    unauthorized = false;
    error = false;
    // maxDate: Moment;
    slotsAvailable = [];
    reservationform: FormGroup;
    slotValue: string;
    slotsLabel: string;
    cityName: string;
    address: string;
    website: string;
    slotUnAvailable = false;
    slot_message = '';
    dropDownGrounds: Dropdown;
    emailContent: string;
    selectedSlotStatus: any;
    repID: any;
    repName: any;
    selectedSlotFIFO: any;
    checkout: any;
    selectedSlotPaidStatus;
    addScript: boolean = false;
    addlaterScript: boolean = false;
    paypalLoad: boolean = true;
    finalAmount: number;
    paypalToken: any;
    options: any;
    overlays: any[];
    amenity_option: any;
    map_option: any;
    amenitiesData;
    optionalData;
    payPalReceipt: any;
    user = 'false';
    loginType = false;
    email: string;
    password: string;
    errorMessage: string;
    user_loadingBar;
    final_weather: any;
    image_url: any;
    booking_types:any;
    sportSelected:boolean=true;
    public bySportsList = [];
    public byGroundsList = [];
    paypal_price: any;
    auto_fifo_option;
    paypal_authorizationId: any;
    paypal_amount: any;
    orderId: any;


    constructor(public router: Router,
        public userFormService: UserFormService,
        public citiesService: CitiesService,
        private cognitoUtil: CognitoUtil,
        private formbuilder: FormBuilder,
        private route: ActivatedRoute, public userService: UserLoginService) {
        this.slotDetailsPage = true;
        this.userDetailsPage = false;
        this.terms = false;
    }
    ngOnInit() {
        this.setCityDetails(sessionStorage.getItem('params').split('/biz417bend180qaztriptransflightresponse/')[0], sessionStorage.getItem('params').split('/biz417bend180qaztriptransflightresponse/')[1]);
        // this.getSports();
        this.reservationform = this.formbuilder.group({
            'sportId': new FormControl('', Validators.required),
            'groundId': new FormControl('', Validators.required),
            'selectedDate': new FormControl('', Validators.required),
            'timeSlot': new FormControl('', Validators.required),
            'firstName': new FormControl('', Validators.required),
            'lastName': new FormControl('', Validators.required),
            'teamName': new FormControl(''),
            'residentOf': new FormControl(''),
            'email': new FormControl('', [Validators.email, Validators.required]),
            'phoneNum': new FormControl('', [Validators.min(1000000000), Validators.max(9999999999), Validators.required]),
            'bookingType': new FormControl('', Validators.required),
        });
        this.slotsLabel = 'Select a slot';
        this.termsCheck = false;
        console.log(this.currentDate.getDay());
        this.restrictDays();

        $(document).on('click', '.clndr_over', function () {
              $('.gr_resrve_frm').css('overflow', 'visible');
        });
        $(document).on('click', '.grd_lst', function () {
              $('.gr_resrve_frm').css('overflow', 'visible');
        });
        this.user = localStorage.getItem('user');

         setTimeout(() => {
            if (this.user) {
                this.user_loadingBar = true;
                this.reservationData.firstName = localStorage.getItem('firstName'),
                this.reservationData.lastName = localStorage.getItem('lastName'),
                this.reservationData.email = localStorage.getItem('email'),
                this.reservationData.teamName = localStorage.getItem('teamName'),
                this.reservationData.phoneNumber = (localStorage.getItem('phoneNumber'))?(localStorage.getItem('phoneNumber')).split('+1')[1]:'',
                this.reservationData.residentOf = localStorage.getItem('residentOf')
            }
        }, 2000);
     
       this.booking_types=[{label:'Sport',value:'1'},{label:'Ground',value:'2'}];
       this.reservationData.bookingType='1';
    }

    loginWithAuthProvider(param, authType) {
     var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
     if (authType === 'facebook') {
        URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
     } else if (authType === 'google') {
        URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
     } else if (authType === 'amazon') {
        URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
     }
    //  open(URL,'sample', 'width=300,height=300').focus();
    // let  newwindow = window.open(URL, 'Fields Manager', 'height=500,width=300');
    // if (window.focus) {newwindow.focus()}
        popupTools.popup(URL, 'My Popup', { width: 400, height: 450 }, ((err) => {
        console.log('window closed::', localStorage.authToken);
        this.user_loadingBar = true;
        // sessionStorage.setItem('authorizationToken',localStorage.authToken);
        // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
         if (param === 'userView') {
            this.userDetailsPage = true
            this.slotDetailsPage = false;
            this.loginType = false;
            this.loginPage = false;
         }
        if (localStorage.authToken) {
          this.cognitoCallback(null, localStorage.authToken);
        }
        }))
    }

    showAmenity() {
     this.amenity_option = false;
    }

    getBookBy(booking_type){
      this.amenity_option = false;
      this.map_option=false;
      this.reservationData.groundId=null;
      this.reservationData.sportId=null;
      if(booking_type==1){
       console.log('sports selected::')
       this.groundsList=[];
       this.sportSelected=true;
      }
      else{
        console.log('ground selected::')
        this.sportSelected=false;
      }
    }

    getGrounds(cityId) {
        this.byGroundsList = [];
        this.loadingBar = true;
        this.userFormService.getGroundsData(cityId).subscribe((data) => {
            console.log("Grounds data response from API call is", data);
            this.byGroundsList = [];
            if (data.length === 0) {
                setTimeout(() => {
                    this.loadingBar = false;
                }, 3000);
            } else {
                data.forEach((o) => {
                    console.log(o);
                    let obj = new Ground();
                    const temp = {};
                    temp['value'] = o.Ground_Id;
                    temp['label'] = o.Ground_Name + ' - ' + o.Park_Name;
                     temp['groundType'] = o.Ground_Type;
                    temp['address'] = o.Street + ', ' + o.CIty + ', ' + o.State + ', ' + o.Zip_Code;
                    this.byGroundsList.push(temp);
                });
                this.loadingBar = false;
                console.log('Ground data is: ', this.byGroundsList);
            }
        });

    }

    getSportsByGround(inputParams){
        // google maps & amenities

        var lat, lang;
        this.map_option = true;
        console.log('this is the get slots first type data', inputParams);

        if (this.groundsList.length !== 0){
            this.groundsList.forEach(e => {
            if (e.value === inputParams) {
            this.reservationData.groundType = e.groundType;
            this.reservationData.address = e.address;
            }
            });
        } else {
           this.byGroundsList.forEach(e => {
            if (e.value === inputParams) {
            this.reservationData.groundType = e.groundType;
            this.reservationData.address = e.address;
            }
            });
        }

        var geocoder = new google.maps.Geocoder();
        var address = this.reservationData.address;
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                lat = results[0].geometry.location.lat();
                lang = results[0].geometry.location.lng();
            }
        });

       setTimeout(function(){
            console.log('Map Data::', lat, lang);
            this.options = {
                center: { lat: lat, lng: lang },
                zoom: 12
            };
            this.overlays = [
                new google.maps.Marker({ position: { lat: lat, lng: lang }, title: this.reservationData.address})
            ];
        }.bind(this), 3000);

        this.amenity_option = true;
        this.getAmenities(inputParams);

        // google maps & amenities

        this.reservationData.slot = null;
        this.opendates = [];
        this.reservationData.selectedDate = null;
        this.reservationData.sportId = null;
        this.loadingBar = true;
        console.log('get sports for', inputParams);
        this.selectedSport = 'All Sports';
        this.bySportsList = [];
        const params = {
            'groundId': inputParams,
            'cityId': sessionStorage.getItem('cityId'),
        };
        this.userFormService.getSportsData(params).subscribe(response => {
            this.loadingBar = false;
            console.log('The response is', response);
            if (response.status === 200) {
                if (response.body.length === 0) {
                } else {
                    response.body.forEach((o) => {
                        console.log(o);
                        let obj = new Sport();
                        const temp = {};
                        temp['value'] = o.Sport_Id;
                        temp['label'] = o.Sport_Name;
                        this.bySportsList.push(temp);
                    });
                    console.log('sportList data is: ', this.bySportsList);
                    console.log('groundsList data')
                }
            }
        },
            err => {this.errorHandle(err) });
    }
    goToDashboard() {
        this.router.navigate(['/home/dashboard']);
    }

    setPayPal(token, price) {
        console.log('this is token:', token, 'this is amount:', price);
        // $('#paypal-checkout-btn').html('');
        setTimeout(function(){
           this.paypal_price = price;
        }.bind(this), 0);

        if (!this.addScript) {
          this.addPaypalScript().then(() => {
            setTimeout(function(){
               paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                amount: {
                value: this.paypal_price
                }
                }],
                application_context: {
                shipping_preference: "NO_SHIPPING"
                },
              });
            },
            onApprove: (data, actions) => {
              this.loadingBar = true;
              this.checkout = false;
              return actions.order.capture().then((payment) => {
                this.payPalReceipt = payment;
                if ( payment.status === 'COMPLETED') {
                console.log("payment success::", payment)
                this.onFormSubmit();
                } else {
                    this.onPayment();
                    alert('Some error occured:' + payment.state);
                }
              })
            }
               }).render('#paypal-checkout-btn');
            }.bind(this), 0);
            this.paypalLoad = false;
          })
        }
    }
    addPaypalScript() {
        console.log(this.paypalToken)
        this.addScript = true;
        return new Promise((resolve, reject) => {
          let scripttagElement = document.createElement('script');
          scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypalToken+'&currency=USD&commit=true&intent='+'capture';
          scripttagElement.onload = resolve;
          document.body.appendChild(scripttagElement);
        })
    }
    getWeather(city, state, zip) {
        console.log('location is 1.', city);
        this.citiesService.getWeather(city).subscribe(response => {
            console.log('this is the response', response);
            if (Object.keys(JSON.parse(response.body).location).length === 0) {
                this.getWeather1(state, zip)
            } else {
                this.final_weather = JSON.parse(response.body);
                this.final_weather.current_observation.condition['unit'] = 'F';
                this.final_weather.current_observation.wind['unit'] = 'm/h';
                this.final_weather.current_observation.pubDate = moment.unix(this.final_weather.current_observation.pubDate).format('MM-DD-YYYY, hh:mm a');
                this.image_url = 'https://content.fieldsmanager.com/Icons/Weather/' + this.final_weather.current_observation.condition.code + '.png';
                console.log('This is the final weather report:', this.final_weather);
            }
        },
            err => {
                this.errorHandle(err);
            });
    }
    getWeather1(state, zip) {
        console.log('location is 2.', state);
        this.citiesService.getWeather(state).subscribe(response => {
            console.log('this is the response.body', JSON.parse(response.body));
            if (Object.keys(JSON.parse(response.body).location).length === 0) {
                this.getWeather2(zip)
            } else {
                this.final_weather = JSON.parse(response.body);
                this.final_weather.current_observation.condition['unit'] = 'F';
                this.final_weather.current_observation.wind['unit'] = 'm/h';
                this.final_weather.current_observation.pubDate = moment.unix(this.final_weather.current_observation.pubDate).format('MM-DD-YYYY, hh:mm a');
                this.image_url = 'https://content.fieldsmanager.com/Icons/Weather/' + this.final_weather.current_observation.condition.code + '.png';
                console.log('This is the final weather report:', this.final_weather);
            }
        },
            err => {
                this.errorHandle(err);
            });
    }
    getWeather2(zipcode) {
        console.log('location is 3.', zipcode);
        this.citiesService.getWeather(zipcode).subscribe(response => {
            console.log('this is the response', response);
            if (Object.keys(JSON.parse(response.body).location).length === 0) {
                console.log('weather report is unvailable for this place');
            } else {
                this.final_weather = JSON.parse(response.body);
                this.final_weather.current_observation.condition['unit'] = 'F';
                this.final_weather.current_observation.wind['unit'] = 'm/h';
                this.final_weather.current_observation.pubDate = moment.unix(this.final_weather.current_observation.pubDate).format('MM-DD-YYYY, hh:mm a');
                this.image_url = 'https://content.fieldsmanager.com/Icons/Weather/' + this.final_weather.current_observation.condition.code + '.png';
                console.log('This is the final weather report:', this.final_weather);
            }
        },
            err => {
                this.errorHandle(err);
            });
    }
    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
      getAdminEMails(cityId) {
        this.userFormService.getAdminEMails(cityId).subscribe((data) => {
        this.emailContent = 'mailto:' + data + '?Subject=Custom Slot Request&body=Hello Admin,%0D%0A%0D%0AI would like to create a custom slot, %0D%0ADetails are listed below. %0D%0A%0D%0A(Fill the below details)%0D%0APark Name*:%0D%0AGround Name*:%0D%0ASport*:%0D%0ADate*:%0D%0ASlot Time*:%0D%0AName*:%0D%0APhone*:%0D%0AEmail*:%0D%0ATeam:%0D%0AResident Of:%0D%0A%0D%0AThank You.';
        });
      }
    setCityDetails(params, routeUrl) {
        this.loadingBar = true;
        this.citiesService.getCityDetails(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                if (response.body.length === 0) {
                    this.loadingBar = false;
                    this.router.navigate(['/home/land']);
                } else {
                    this.paypalToken = response.body[0]['PayPal'];
                    if(response.body[0]['PayPal'] && response.body[0]['PayPal_Secret']){
                      localStorage.setItem('paypal','true')
                    }
                    else{
                      localStorage.setItem('paypal','false') 
                    }
                    console.log('This is the response from getCity Details by domain', response.body);
                    const paypalconfigData={
                     'clientId':response.body[0]['PayPal'],
                     'clientSecret':response.body[0]['PayPal_Secret']   
                    }
                    this.citiesService.sendData(paypalconfigData);
                    // this.citiesService.cityDetails.emit(response.body);
                    this.cityName = response.body[0]['City_Name'];
                    this.address = response.body[0]['Street'] +', '+ response.body[0]['City'] +', \n'+ response.body[0]['State'] +', \n'+ response.body[0]['Zip_Code'];
                    this.website = response.body[0]['Website'];
                    this.somevalue = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + response.body[0]['City_Id'] + '.png';
                    this.getSports(response.body[0]['City_Id']);
                    this.getGrounds(response.body[0]['City_Id']);
                    this.getAdminEMails(response.body[0]['City_Id']);
                    this.getWeather(response.body[0]['City'] + ', ' + response.body[0]['State'], response.body[0]['State'], response.body[0]['Zip_Code']);
                    if (routeUrl.toLowerCase().includes('checkstatus')) {
                        console.log('route url has check status');
                        // this.router.navigate(['/home/checkStatus']);
                    } else {
                        if (localStorage.getItem('CognitoIdentityServiceProvider.3run3nquj30pkpaf1djg6acu8n.rohitk.accessToken') === null) {
                            this.router.navigate(['/home/land']);
                        } else {
                            this.router.navigate(['/home/login']);
                        }
                    }
                    //this.loadingBar = false;
                    sessionStorage.setItem('cityName', response.body[0]['City_Name']);
                    sessionStorage.setItem('location', response.body[0]['City'] + ', ' + response.body[0]['State']);
                    sessionStorage.setItem('address', response.body[0]['Street'] +', '+ response.body[0]['City'] +', \n'+ response.body[0]['State'] +', \n'+ response.body[0]['Zip_Code']);
                    sessionStorage.setItem('website', response.body[0]['Website']);
                    sessionStorage.setItem('cityId', response.body[0]['City_Id']);
                    sessionStorage.setItem('cityDomain', params);
                }
            }
        },
            err => {
                this.errorHandle(err)
            });
    }
    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        } else {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        }
    }
    onClear() {
        this.reservationform.reset();
        this.terms = false;
        this.reservationform['selectedDate'] = '';
    }
    onClickGuest() {
        this.loginType = false;
        this.slotDetailsPage = false;
        this.userDetailsPage = true;
        this.loginPage = false;
    }
   // Login start

    onClickLogin() {
        this.loginPage = true;
        this.loginType = false;
    }
    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = 'All fields are required';
            return;
        }
        this.userDetailsPage = true
        this.slotDetailsPage = false;
        this.loginType = false;
        this.loginPage = false;
        this.user_loadingBar = true;
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }
     cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.user_loadingBar = false;
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log('redirecting');
                this.router.navigate(['/home/accountActivation', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                console.log('redirecting to set new password');
                this.router.navigate(['/home/newPassword']);
            }
        } else { // success
            let tokenInfo;
            if(result.idToken==undefined){
               tokenInfo = this.getDecodedAccessToken(result);
                console.log('this is the id token ###################', tokenInfo);
               sessionStorage.setItem('authorizationToken', result);
            }
            else{
               tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
            }
            let params = sessionStorage.getItem('authorizationToken');
            console.log('This is the token info', tokenInfo);
            if (tokenInfo['cognito:groups'][0] === 'SystemAdmin') {
                console.log('this is the console for system admin');
                localStorage.setItem('superAdmin', 'true');
                // this.loadingBar = false;
                this.router.navigate(['/admin/cityRequests']);
            } else if (tokenInfo['cognito:groups'][0].includes('Facebook') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("facebook Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    //this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('Google') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("google Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    //this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                        this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('LoginWithAmazon') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("amazon Data::",tokenInfo);
                console.log("amazon Data::",tokenInfo['name']);
                localStorage.setItem('user', 'true');
                this.user = 'true';
                localStorage.setItem('firstName', tokenInfo['name']);
                //localStorage.setItem('lastName', tokenInfo['family_name']);
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    //this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
            } else if (tokenInfo['cognito:groups'][0].includes('User')) {
                console.log('************ User is logged in ***********');
                localStorage.setItem('user', 'true');
                this.user = 'true';
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] == null ? '' : data[0]['Team_Name']);
                    if (data[0]['Phone_Number'] !== null && data[0]['Phone_Number'] !== undefined) {
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    this.reservationData.phoneNumber = (data[0]['Phone_Number']).split('+1')[1]; }
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    console.log('the user id is', data[0]['User_Id']);
                    this.reservationData.firstName = data[0]['First_Name'],
                    this.reservationData.lastName = data[0]['Last_Name'],
                    this.reservationData.userId = data[0]['User_Id'],
                    this.reservationData.email = data[0]['Email'],
                    this.reservationData.teamName = data[0]['Team_Name'] == null ? '' : data[0]['Team_Name'],
                    this.reservationData.residentOf = data[0]['Resident_Of'] == null ? '' : data[0]['Resident_Of']
                });
                    // this.router.navigate(['/home/land']);
                    // this.userDetailsPage = true
                    // this.slotDetailsPage = false;
                    // this.loginType = false;
                    // this.loginPage = false;
                    setTimeout(() => {
                    this.user_loadingBar = false;
                    }, 2000);
                    // this.ngOnInit();
            } else {
                this.userService.getAdminDetails(params).subscribe(data => {
                    console.log('this is the output from get admin details:', data);
                    localStorage.setItem('repId', data[0]['Rep_Id']);
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                    if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        // this.loadingBar = false;
                        this.router.navigate(['/parkDefaults']);
                    } else {
                        localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                        localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        this.router.navigate(['/admin']);
                    }
                });
            }
        }
    }

    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    // Login end

    onCickNext() {
        this.slotDetailsPage = false;
        this.userDetailsPage = true;
        if (this.user === 'true') {
        setTimeout(() => {
        this.user_loadingBar = false;
        }, 2000);
        this.loginType = false;
        this.reservationData.firstName = localStorage.getItem('firstName'),
        this.reservationData.lastName = localStorage.getItem('lastName'),
        this.reservationData.email = localStorage.getItem('email'),
        this.reservationData.teamName = localStorage.getItem('teamName'),
        this.reservationData.phoneNumber = (localStorage.getItem('phoneNumber'))?(localStorage.getItem('phoneNumber')).split('+1')[1]:'',
        this.reservationData.residentOf = localStorage.getItem('residentOf')
        } else {
          this.slot_message = '';
          this.loginType = true;
        }
    }
    onClickBack() {
        console.log('Login Page:', this.loginPage, 'Login Type:', this.loginType, 'Slot Details Page:',
         this.slotDetailsPage, 'User Details Page:', this.userDetailsPage);
        if (this.loginPage === true) {
            this.loginType = true;
            this.loginPage = false;
        }else if (this.loginType === true ) {
            this.loginPage = false;
            this.loginType = false;
            this.slotDetailsPage = true;
            this.userDetailsPage = false;
        } else if (this.userDetailsPage = true && this.loginType === false && this.loginPage === false) {
            if (this.user === 'true') {
                this.loginType = false;
                this.loginPage = false;
                this.slotDetailsPage = true;
                this.userDetailsPage = false;
            } else {
                this.loginType = true;
            }
        }
        this.captchaResolved = false;
        this.termsCheck = false;
        this.addScript = false;
        }
    close() {
        this.termsCheck = false;
        // this.captchaResolved = false;
    }
    onTerms() {
        if (this.captchaResolved === true) {
        this.terms = true;
        } else {this.terms = false;
            alert('No bots please'); }
    }

    onPayment() {
      this.terms = false;
      this.checkout = true;
    }

    onTermsCheck() {
        console.log('terms and conditions:', this.termsCheck);
    }
    onFormSubmit() {
        this.loadingBar = true;
        if (this.reservationData.teamName === '' || this.reservationData.teamName === undefined) {
            this.reservationData.teamName = null;
        }
        this.reservationData.selectedDate = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
        console.log('sport is: ', this.reservationData.sportId);
        const phone = '+1'.concat(this.reservationData.phoneNumber);
        const createdAt = moment().tz('America/Chicago').format('YYYY-MM-DD hh:mm:ss');
        if (this.reservationData.residentOf === '' || this.reservationData.residentOf === undefined || this.reservationData.residentOf === null ){
            this.reservationData.residentOf = null;
        }
        if (localStorage.getItem('id') === null || localStorage.getItem('id') === undefined || localStorage.getItem('id') === '') {
            this.reservationData.userId = null;
        } else {
            this.reservationData.userId = localStorage.getItem('id');
        }

        if (this.selectedSlotFIFO === 'false' && this.selectedSlotPaidStatus === true) {
              const params = {
                'phoneNumber': phone,
                'slotId': this.reservationData.slot,
                'slot': this.slotValue,
                'slotStatus': this.selectedSlotStatus,
                'slotFIFO': this.selectedSlotFIFO,
                'repId': this.repID,
                'repName': this.repName,
                'reservationDate': this.reservationData.selectedDate,
                'cityId': sessionStorage.getItem('cityId'),
                'userId': this.reservationData.userId,
                'groundId': this.reservationData.groundId,
                'sportId': this.reservationData.sportId,
                'createdAt': createdAt,
                'firstName': this.reservationData.firstName,
                'lastName': this.reservationData.lastName,
                'email': this.reservationData.email,
                'teamName': this.reservationData.teamName,
                'residentOf': this.reservationData.residentOf,
                'stage': environment.logoStage,
                'authorizationId': this.paypal_authorizationId,
                'paypalAmount': this.paypal_amount,
                'orderId': this.orderId
            };
            this.termsCheck = false;
            this.terms = false;
            this.checkout = false;
            console.log('Model data is:', this.reservationData);
            console.log("auto fifo false and paid slot")
            console.log('Add reservation params are:', params);
            this.userFormService.submitReservation(params).subscribe((data) => {
                this.slot_message = '';
                console.log('Response is: ', data);
                // if (data.reservationId !== null || data.reservationId !== undefined || data.reservationId !== '') {
                //     if (this.selectedSlotPaidStatus === true) {
                //     this.userFormService.storeTransaction(this.payPalReceipt, data.reservationId).subscribe(response => {
                //         console.log('this is the response from store transaction details:', response);
                //     }); }
                // }
                this.conflictResponse['msg'] = data.msg;
                this.options = false;
            this.map_option = false;
            this.amenity_option = false;
            // this.showAmenity();
                this.checkConflict();
            });
        } else {
             const params = {
            'phoneNumber': phone,
            'slotId': this.reservationData.slot,
            'slot': this.slotValue,
            'slotStatus': this.selectedSlotStatus,
            'slotFIFO': this.selectedSlotFIFO,
            'repId': this.repID,
            'repName': this.repName,
            'reservationDate': this.reservationData.selectedDate,
            'cityId': sessionStorage.getItem('cityId'),
            'userId': this.reservationData.userId,
            'groundId': this.reservationData.groundId,
            'sportId': this.reservationData.sportId,
            'createdAt': createdAt,
            'firstName': this.reservationData.firstName,
            'lastName': this.reservationData.lastName,
            'email': this.reservationData.email,
            'teamName': this.reservationData.teamName,
            'residentOf': this.reservationData.residentOf,
            'stage': environment.logoStage
        };
        this.termsCheck = false;
        this.terms = false;
        this.checkout = false;
        console.log('Model data is:', this.reservationData);
        console.log('auto fifo true and paid/free slot');
        console.log('Add reservation params are:', params);
        this.userFormService.submitReservation(params).subscribe((data) => {
            this.slot_message = '';
            setTimeout(function(){
            this.reservationData.bookingType='1';
            this.sportSelected=true;
            }.bind(this), 0);
            console.log('Response is: ', data);
            // if (data.reservationId !== null || data.reservationId !== undefined || data.reservationId !== '') {
            //     if (this.selectedSlotPaidStatus === true) {
            //     this.userFormService.storeTransaction(this.payPalReceipt, data.reservationId).subscribe(response => {
            //         console.log('this is the response from store transaction details:', response);
            //     }); }
            // }
            this.conflictResponse['msg'] = data.msg;
            this.options = false;
        this.map_option = false;
        this.amenity_option = false;
        // this.showAmenity();
            this.checkConflict();
        });
        }
    }
    clearMessages() {
        this.options = false;
        this.map_option = false;
        this.reservationMessageOverlap = null;
        this.reservationMessageSuccess = null;
        this.reservationMessageError = null;
    }
    resolved(captchaResponse: string) {
        let resolvedKey = captchaResponse;
        if (resolvedKey === '' || resolvedKey === undefined || resolvedKey === null) {
            this.captchaResolved = false;
        } else {
            this.captchaResolved = true;
        }
        console.log(`Resolved captcha: ${captchaResponse}`);
    }
    checkConflict() {
        this.loadingBar = true;
        // this.conflictResponse = {'msg': 'overlap'};
        console.log('Response is: ', this.conflictResponse['msg']);
        if (this.conflictResponse['msg'] === 'overlap') {
            // this.showMessage('info', '', 'Your request for this slot already exists.');
            this.loadingBar = false;
            this.reservationMessageOverlap = 'Your reservation request already exists';
            // this.reservationform.reset();
            // this.router.navigate(['/home/""']);
            setTimeout(() => {
                this.clearMessages();
            }, 7000);
            this.reservationData = new Reservation();
        } else if (this.conflictResponse['msg'] === 'Reservation request submitted. Notification sent!') {
            this.loadingBar = false; // } else if (this.conflictResponse['msg'] === 'limitReached') {
            this.slotDetailsPage = true;
            this.userDetailsPage = false;
            //   // this.showMessage('warn', 'Maximum Limit Reached:', 'Only 2 reservation requests allowed per day.');
            this.reservationMessageSuccess = 'Request has been successfully submited';
            setTimeout(() => {
                this.clearMessages();
            }, 7000);
            this.reservationData = new Reservation();
        } else if (this.conflictResponse['msg'] === 'error') {
            // this.reservationform.reset();
            // this.getSports();
            // this.showMessage('error', '', 'Error booking your slot, Please contact our office at 972-874-6300');
            this.loadingBar = false;
            this.reservationMessageError = 'Error submiting your request, Please contact our office at 972-874-6300';
            setTimeout(() => {
                this.clearMessages();
            }, 7000);
            this.reservationData = new Reservation();
        } else {
            // this.reservationform.reset();
            // this.getSports();
            this.loadingBar = false;
            this.reservationMessageError = 'An unexpected error occured, Unable to submit request at this time';
            setTimeout(() => {
                this.clearMessages();
            }, 7000);
            this.reservationData = new Reservation();
        }
        // this.showMessage('warn', this.conflictResponse['msg'], this.conflictResponse['msg']);

    }
    loginAsAdmin() {
        console.log('on router button click');
        this.router.navigate(['/home/login'],{queryParams:{'name':'land'}});
    }
    signup() {
        console.log('on router button click');
        this.router.navigate(['/home/register']);
    }
    logout () {
        // this.cognitoUtil.getCurrentUser().signOut();
        var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        URL = environment.logout + domainName;
        this.loadingBar = true;
        this.slotDetailsPage = true;
        this.userDetailsPage = false;
        this.loginType = false;
        this.slot_message = '';
        this.user = 'false';
        this.amenity_option = false;
        setTimeout(function(){
        this.reservationData.firstName = localStorage.removeItem('firstName');
        this.reservationData.lastName = localStorage.removeItem('lastName');
        this.reservationData.residentOf = localStorage.removeItem('residentOf');
        this.reservationData.teamName = localStorage.removeItem('teamName');
        this.reservationData.phoneNumber = localStorage.removeItem('phoneNumber');
        this.reservationData.email = localStorage.removeItem('email');
        }.bind(this), 0);
        this.userService.logout();
        // this.router.navigate(['/home/land']);
        console.log('Logout URL:', URL);
        window.location.replace(URL);
    }
    restrictDays() {
        // this.currentDay = moment().format('dddd').toString();
        // console.log('Current Day is', this.currentDay);
        // this.currentWeek = moment().format('w');
        this.minDate = (moment())['_d'];
        // if (this.weekdayList.indexOf(this.currentDay) > -1) {
        //     this.minDate = (moment().add(1, 'weeks').startOf('isoWeek'))['_d'];
        //     // this.maxDate = (moment().add(1, 'weeks').endOf('isoWeek'))['_d'];
        //     console.log(this.currentDay, this.minDate,  ' is a weekday');
        // } else {
        //     this.minDate = (moment().add(2, 'weeks').startOf('isoWeek'))['_d'];
        //     // this.maxDate = (moment().add(2, 'weeks').endOf('isoWeek'))['_d'];
        //     console.log(this.currentDay, this.minDate,  ' is not a weekday');
        // }
    }
    getSports(cityId) {
        this.sportsList = [];
        this.loadingBar = true;
        this.userFormService.getSports(cityId).subscribe((data) => {
            // let tempObjArray: Ground[] = [];
            //data=this.sportsList=[{Sport_Id: 96, Sport_Name: "Soccer"}];
            console.log("sports data response from API call is", data);
            this.sportsList = [];
            if (data.length === 0) {
                $('p-dropdown#sprt_txt .ui-dropdown label').text("No Sports");
                $('p-dropdown#grnd_txt .ui-dropdown label').text("No Grounds");
                setTimeout(() => {
                    this.loadingBar = false;
                }, 3000);
            } else {
                data.forEach((o) => {
                    console.log(o);
                    let obj = new Sport();
                    const temp = {};
                    temp['value'] = o.Sport_Id;
                    temp['label'] = o.Sport_Name;
                    this.sportsList.push(temp);
                });
                this.loadingBar = false;
                if(this.sportsList.length==1){
                  this.reservationData.sportId=this.sportsList[0].value;
                  this.getGroundsBySport(this.reservationData.sportId)
                }
                // this.getSportsByGround(this.selectedGround);
                console.log('sport data is: ', this.sportsList);
            }
        });
    }
    getGroundsBySport(inputParams) {
        this.amenity_option = false;
        this.showAmenity();
        this.map_option = false;
        this.reservationData.slot = null;
        this.opendates = [];
        this.reservationData.groundId = null;
        this.reservationData.selectedDate = null;
        this.groundsList = [];
        this.loadingBar = true;
        console.log("get grounds for sportId :", inputParams);
        let outputParams = {
            'sportId': inputParams,
            'cityId': sessionStorage.getItem('cityId')
        }
        this.selectedGround = '';
        this.userFormService.getGrounds(outputParams).subscribe((data) => {
            console.log('this is the data', data);
            this.groundsList = [];
            if (data.length === 0) {
                this.loadingBar = false;
            } else {
                data.forEach((o) => {
                    console.log(o);
                    let obj = new Ground();
                    const temp = {};
                    temp['value'] = o.Ground_Id;
                    temp['label'] = o.Ground_Name + ' - ' + o.Park_Name;
                    temp['groundType'] = o.Ground_Type;
                    temp['address'] = o.Street + ', ' + o.CIty + ', ' + o.State + ', ' + o.Zip_Code;
                    this.groundsList.push(temp);
                });
                this.loadingBar = false;
                if (this.groundsList.length === 1) {
                 this.reservationData.groundId = this.groundsList[0].value;
                 this.getSlots(this.reservationData.groundId);
                }
                console.log('groundsList data is: ', this.groundsList);
            }

        });
    }
    onMonthClick(params) {
        this.openmonths = [];
        this.opendates = [];
        console.log('On month change click event:', params);
        this.startDate = moment([params.year, params.month - 1]).format('YYYY-MM-DD');
        this.endDate = moment(this.startDate).endOf('month');
        // this.openmonths.push(params.month);
        console.log('The first date is:', this.startDate, 'The last date is:', this.endDate.format('YYYY-MM-DD'));
        this.getSlots(this.reservationData.groundId);
    }
    getAmenities(groundId) {
        this.amenityList = false;
        this.optionalList = false;
        this.citiesService.getAmenityDetails(groundId).subscribe((response) => {
            let finalAmenities = [];
            let finalOptional = [];
            if (response.body.length > 0) {
                console.log('response from get amenities', response);
                response.body.forEach(e => {
                    if (e.Amenity_Type === 'Amenity') {
                        finalAmenities.push(e.Amenity);
                    } else if (e.Amenity_Type === 'Optional') {
                        finalOptional.push(e.Amenity);
                    }
                });
                console.log('these are the final amenities', finalAmenities);
                console.log('these are the optional amenities', finalOptional);
                this.amenitiesData = finalAmenities;
                if (this.amenitiesData.length > 0) { this.amenityList = true; }
                this.optionalData = finalOptional;
                if (this.optionalData.length > 0) { this.optionalList = true; }
            }
        },
            (error) => {
                this.loadingBar = false;
                console.log(error);
            });
    }

    getSlots(groundId) {
        var lat, lang;
        this.map_option = true;
        console.log('this is the get slots first type data', groundId);

        if(this.groundsList.length !== 0){
            this.groundsList.forEach(e => {
            if (e.value === groundId) {
            this.reservationData.groundType = e.groundType;
            this.reservationData.address = e.address;
            }
            });
        }
        else{
           this.byGroundsList.forEach(e => {
            if (e.value === groundId) {
            this.reservationData.groundType = e.groundType;
            this.reservationData.address = e.address;
            }
            });   
        }
       
        //google maps

        var geocoder = new google.maps.Geocoder();
        var address = this.reservationData.address;
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                lat = results[0].geometry.location.lat();
                lang = results[0].geometry.location.lng();
            }
        });

       setTimeout(function(){
            console.log('Map Data::', lat, lang);
            this.options = {
                center: { lat: lat, lng: lang },
                zoom: 12
            };
            this.overlays = [
                new google.maps.Marker({ position: { lat: lat, lng: lang }, title: this.reservationData.address})
            ];
        }.bind(this), 3000);

        this.amenity_option = true;
        this.getAmenities(groundId);

        // google maps

        this.reservationData.slot = null;
        // this.reservationData.selectedDate = null;
        this.reservationData.slot = null;
        this.opendates = [];
        this.loadingBar = true;
        // this.reservationData.selectedDate = '';
        this.reservationData.slot = '';

        console.log('Selected ground Id is: ', groundId);
        const params = {
            'cityId': sessionStorage.getItem('cityId'),
            'sportId': this.reservationData.sportId,
            'groundId': this.reservationData.groundId,
            'startDate': this.startDate,
            'endDate': this.endDate.format('YYYY-MM-DD')
        };
       

        this.userFormService.getSlotsAvailable(params).subscribe((data) => {
            const temp: Reservation[] = [];
            console.log('get slots data..........: ');
            for (let i = 0; i < data.length; i++) {
                let date = moment().format('YYYY-MM-DD');
                data[i].Slots.forEach((obj) => {
                    console.log('this is the obj', obj);
                    let openDate = obj.Slot_Open_Date;
                    let closeDate = obj.Slot_Close_Date;
                    console.log(date, openDate, closeDate);
                    if (moment(date).isSameOrAfter(openDate) && moment(date).isSameOrBefore(closeDate)) {

                        if (obj.Slot_Status === 'Active' || obj.Slot_Status === 'Pending') {
                            let check = moment(data[i].Date, 'YYYY/MM/DD');
                            this.opendates.push(moment(data[i].Date).format('DD'));
                            if (check.format('M') === '1') {
                                console.log('Selected month is January');
                                this.openmonths.push(0);
                            } else {
                                this.openmonths.push(parseInt(moment(data[i].Date).add(-1, 'months').format('M'), 10));
                            }

                            console.log('This is the open dates:', this.opendates, this.openmonths);
                        }
                    }
                });
            }
            this.opendates.map(Number);
            // let unique_array = []
            for (let i = 0; i < this.opendates.length; i++) {
                this.opendates[i] = parseInt(this.opendates[i], 10);
            }
            this.loadingBar = false;
            // this.slotsAvailable = temp;
            this.slotsAvailable = data;
            // console.log('this is the slots available', this.slotsAvailable);
            console.log('this is the data', data);
        },
            (error) => {
                this.loadingBar = false;
                console.log(error);
            });
    }
    test(test) {
        console.log('test is', test);
    }
    onDateSelected() {

        this.reservationData.slot = null;
        this.slot_message = '';
        this.slotUnAvailable = false;
        console.log('this is the selected dates', this.reservationData.selectedDate);
        this.timeSlots = [];
        // const date = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
        this.slotsAvailable.forEach(each => {
            // let day = this.userData.selectedDate;
            const date = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
            if (each.Date === date) {
                console.log('each date: ', each.Date);
                each.Slots.forEach((obj) => {
                    const tmp = {};
                    tmp['label'] = obj.Slot;
                    tmp['value'] = obj.Slot_Id;
                    tmp['Slot_Type'] = obj.Slot_Type;
                    tmp['Slot_Open_Date'] = obj.Slot_Open_Date;
                    tmp['Slot_Close_Date'] = obj.Slot_Close_Date;
                    tmp['Slot_Status'] = obj.Slot_Status;
                    tmp['Auto_FIFO'] = obj.Auto_FIFO;
                    tmp['Price'] = obj.Price;
                    tmp['Rep_Id'] = obj.Rep_Id;
                    tmp['Rep_Name'] = obj.Rep_Name;
                    this.timeSlots.push(tmp);
                });
            }
        });
        if (this.timeSlots.length === 0) {
            this.slotUnAvailable = true;
            this.slotsLabel = 'No Slots Available On This Day';
            // this.slotsLabel = 'No slots available on this day';
            // tmp['value'] = obj.Slot_Id;
            // this.timeSlots.push(tmp);
            // alert('No slots available on that day. Please select another day.');
        }else {
        this.slotsLabel = 'Select a slot'; }

        console.log('slots available on that day', this.timeSlots);
    }
    onSelectSlot(slot) {
        this.slot_message = '';
        this.slotUnAvailable = false;
        console.log('these are the time slots', slot, this.timeSlots);
        this.timeSlots.forEach((each) => {
            if (each.value === slot) {
              console.log("slot info::", each);
                this.slotValue = each.label;
                this.selectedSlotFIFO = each.Auto_FIFO;
                this.repID = each.Rep_Id;
                this.repName = each.Rep_Name;
                this.selectedSlotStatus = each.Slot_Status;
                if (each.Slot_Type === 'Paid' && each.Auto_FIFO === 'true' && each.Slot_Status === 'Active') {
                this.selectedSlotPaidStatus = true;
                this.auto_fifo_option = false;
                this.setPayPal(this.paypalToken, each.Price);
                    // if (each.Slot_Status !== 'Active') {
                    //     this.slotUnAvailable = true;
                    //     this.slot_message = 'This is a paid slot & currently not available for booking';
                    // } else {
                        this.slot_message = 'This is a paid slot ($' + each.Price + ') with instant approval';
                    // }
                } else if (each.Slot_Type !== 'Paid') {
                    this.selectedSlotPaidStatus = false;
                    this.finalAmount = each.Price;
                    console.log('today: ', moment().format('YYYY-MM-DD'), 'Opens date:', each.Slot_Open_Date, 'Close date:', moment(each.Slot_Close_Date));
                    if (moment(each.Slot_Open_Date).isAfter(moment().format('YYYY-MM-DD'))) {
                        console.log('slot opens later', moment().format('YYYY-MM-DD'), moment(each.Slot_Open_Date));
                        this.slot_message = 'Booking for this slot opens on ' + moment(each.Slot_Open_Date).format('YYYY-MM-DD') + '.';
                        this.slotUnAvailable = true;
                    } else if (moment(each.Slot_Close_Date ).isBefore(moment().format('YYYY-MM-DD'))) {
                        console.log('slot booking closed');
                        this.slot_message = 'Booking for this slot was closed on ' + moment(each.Slot_Close_Date ).format('YYYY-MM-DD') + '!';
                        this.slotUnAvailable = true;
                    } else {
                        if (each.Slot_Status === 'Reserved') {
                            console.log('slot is in wait list');
                            this.slot_message = 'This slot is already reserved, your request will be taken in waitlist.';
                        }
                    }
                } else if (each.Slot_Type === 'Paid' && each.Auto_FIFO === 'true' && each.Slot_Status !== 'Active') {
                    this.slot_message = 'This slot is already booked!';
                    this.slotUnAvailable = true;
                }
                else if(each.Slot_Type === 'Paid' && each.Auto_FIFO === 'false' && (each.Slot_Status === 'Active' || each.Slot_Status === 'Pending')){
                    this.selectedSlotPaidStatus = true;
                    this.auto_fifo_option = true;
                    this.latersetPayPal(each.Price);
                    this.slot_message = 'Slot Price: ($' + each.Price + '), You will be charged only if your reservation is approved!';
                }
            }
        });
        console.log('slot is', this.slotValue);
    }
    latersetPayPal(price) {
       // $('#paypal-button-container').html('');
        if (!this.addlaterScript) {
        this.addlaterPaypalScript().then(() => {
        setTimeout(function(){
        paypal.Buttons({
        createOrder: function(data, actions) {
        return actions.order.create({
        purchase_units: [{
        amount: {
        value: price
        }
        }],
        application_context: {
        shipping_preference: "NO_SHIPPING"
        },
        intent: 'authorize'
        });
        },
        onApprove: (data, actions) => {
            this.loadingBar = true;
            this.checkout = false;
            // Authorize the transaction
            return actions.order.authorize().then((authorization) => {
              // Get the authorization id
                if (authorization.status === 'COMPLETED') {
                let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id
                this.paypal_authorizationId = authorizationID;
                this.paypal_amount = authorization.purchase_units[0].payments.authorizations[0].amount.value;
                this.orderId = data.orderID;
                this.onFormSubmit();
                }
              // Call your server to validate and capture the transaction
              // return fetch('/paypal-transaction-complete', {
              //   method: 'post',
              //   body: JSON.stringify({
              //     orderID: data.orderID,
              //     authorizationID: authorizationID
              //   })
              // });
            });
          }
        }).render('#paypal-button-container');
     }.bind(this), 0);
      })
     }
    }

    addlaterPaypalScript() {
        console.log(this.paypalToken)
        this.addlaterScript = true;
        return new Promise((resolve, reject) => {
          let scripttagElement = document.createElement('script');
          scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypalToken+'&currency=USD&commit=true&intent='+'authorize';
          scripttagElement.onload = resolve;
          document.body.appendChild(scripttagElement);
          console.log(scripttagElement);
        })
    }


}




@Component({
    selector: 'app-home',
    templateUrl: './home.html',
    styleUrls: ['../../assets/slotsAssets/css/style.css']
})
export class HomeComponent implements OnInit {

    constructor(public router: Router) {
        console.log('HomeComponent constructor');
    }

    ngOnInit() {

    }
}

