import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../../service/user-login.service';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../../service/cognito.service';
import { UserIdleService } from 'angular-user-idle';
import * as jwt_decode from 'jwt-decode';
var popupTools = require('popup-tools');


@Component({
    selector: 'app-login',
    templateUrl: './login.html',
    styleUrls: ['../../../../assets/slotsAssets/css/style.css']
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    address: string;
    website: string;
    email: string;
    loadingBar = false;
    password: string;
    errorMessage: string;
    mfaStep = false;
    mfaData = { destination: '', callback: null };
    urlType: string;

    constructor(public router: Router,
        private route: ActivatedRoute,
        public userService: UserLoginService,
        private userIdle: UserIdleService) {
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
        console.log('LoginComponent constructor');
        this.urlType = this.route.snapshot.queryParamMap.get('name');
        console.log('urlType:', this.urlType);
    }

    ngOnInit() {
        // if (sessionStorage.getItem('firstPassword') === 'true'){
        //     alert('Account setup successfull!');
        //     sessionStorage.removeItem('firstPassword');
        // }
        this.errorMessage = null;
        console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
        this.userService.isAuthenticated(this);
        // Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => console.log(count));

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() =>
            this.router.navigate(['/admin/logout']));
        // alert('Logged out due to inactivity');
    }

    // single sign on auth
    loginWithAuthProvider(authType) {
        var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        if (authType === 'facebook') {
        URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
        } else if (authType === 'google') {
        URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
        } else if (authType === 'amazon') {
        URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
        }
        popupTools.popup(URL, 'My Popup', { width: 400, height: 450 }, ((err) => {
        console.log('window closed::');
        // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
        if (localStorage.authToken) {
        this.cognitoCallback(null, localStorage.authToken);
        }
        }))
    }
    // single sign on auth

    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    stop() {
        this.userIdle.stopTimer();
    }

    stopWatching() {
        this.userIdle.stopWatching();
    }

    startWatching() {
        this.userIdle.startWatching();
    }

    restart() {
        this.userIdle.resetTimer();
    }

    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = 'All fields are required';
            return;
        }
        this.loadingBar = true;
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
        console.log('this is the this value:', this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.loadingBar = false;
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log('redirecting');
                this.router.navigate(['/home/accountActivation', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                console.log('redirecting to set new password');
                this.router.navigate(['/home/newPassword']);
            }
        } else { // success
            let tokenInfo;
            if(result.idToken==undefined){
                tokenInfo = this.getDecodedAccessToken(result);
                console.log('this is the id token ###################', tokenInfo);
                sessionStorage.setItem('authorizationToken', result);
            }
            else{
                tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
            }
            let params = sessionStorage.getItem('authorizationToken');
            console.log('This is the token info', tokenInfo);
            if (tokenInfo['cognito:groups'][0] === 'SystemAdmin') {
                console.log('this is the console for system admin');
                localStorage.setItem('superAdmin', 'true');
                // this.loadingBar = false;
                this.router.navigate(['/admin/cityRequests']);
            } else if(tokenInfo['cognito:groups'][0].includes('Facebook') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("facebook Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    if ( this.urlType === 'custom') {
                        this.router.navigate(['home/customSlot'])
                    } else {
                         this.router.navigate(['/home/land']);
                    }
                });
                // if ( this.urlType === 'custom') {
                //  this.router.navigate(['home/customSlot'])
                // } else {
                //   this.router.navigate(['/home/land']);
                // }
            } else if(tokenInfo['cognito:groups'][0].includes('Google') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("google Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    if ( this.urlType === 'custom') {
                        this.router.navigate(['home/customSlot'])
                    } else {
                         this.router.navigate(['/home/land']);
                    }
                });
                // if ( this.urlType === 'custom') {
                //  this.router.navigate(['home/customSlot'])
                // } else {
                //   this.router.navigate(['/home/land']);
                // }
            } else if(tokenInfo['cognito:groups'][0].includes('LoginWithAmazon') && !(tokenInfo['cognito:groups'][0].includes('User'))){
                console.log("amazon Data::",tokenInfo);
                console.log("amazon Data::",tokenInfo['name']);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    if ( this.urlType === 'custom') {
                        this.router.navigate(['home/customSlot'])
                    } else {
                         this.router.navigate(['/home/land']);
                    }
                });
                // if ( this.urlType === 'custom') {
                //  this.router.navigate(['home/customSlot'])
                // } else {
                //   this.router.navigate(['/home/land']);
                // }
            } else if (tokenInfo['cognito:groups'][0].includes('User')) {
                console.log('************ User is logged in ***********');
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    if ( this.urlType === 'custom') {
                        this.router.navigate(['home/customSlot'])
                    } else {
                         this.router.navigate(['/home/land']);
                    }
                });
                // if ( this.urlType === 'custom') {
                //  this.router.navigate(['home/customSlot'])
                // } else {
                //   this.router.navigate(['/home/land']);
                // }
            } else {
                console.log("cognito:group",tokenInfo['cognito:groups'][0].split('_')[1])
                localStorage.setItem('cognitoGroup',tokenInfo['cognito:groups'][0].split('_')[1])
                this.userService.getAdminDetails(params).subscribe(data => {
                    console.log('this is the output from get admin details:', data);
                    localStorage.setItem('repId', data[0]['Rep_Id']);
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                    if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        // this.loadingBar = false;
                        this.router.navigate(['/parkDefaults']);
                    } else {
                        localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                        localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                        // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                        this.router.navigate(['/admin']);
                    }
                });
            }
        }
    }
    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
        this.mfaData.callback = (code: string) => {
            if (code == null || code.length === 0) {
                this.errorMessage = 'Code is required';
                return;
            }
            this.errorMessage = null;
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn && localStorage.getItem('superAdmin') === 'true') {
            console.log('this is the super admin isLoggedIn');
            this.router.navigate(['/admin/cityRequests']);
        } else if (isLoggedIn && (localStorage.getItem('defaultParkId') === null || localStorage.getItem('defaultParkId') === 'undefined') && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('user') !== 'true') {
            console.log('this is the park defaults isLoggedIn');
            this.router.navigate(['/parkDefaults']);
        } else if (isLoggedIn && localStorage.getItem('defaultParkId') !== null
            && localStorage.getItem('defaultParkId') !== 'undefined') {
            console.log('this is the no park defaults isLoggedIn');
            this.router.navigate(['/admin']);
        }else if (isLoggedIn && localStorage.getItem('user') === 'true') {
            this.router.navigate(['/home/land']);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false;   // necessary to prevent href navigation
    }
}
