import { Component, OnInit } from '@angular/core';
import { CognitoUtil } from '../../service/cognito.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  // isActive = true;
  id = 0;
  constructor(public cognitoUtil: CognitoUtil,
    private router: Router) {
    // this.cognitoUtil.refresh();
  }

  isActive: string;
  super_admin:boolean;
  partner:boolean;
  // constructor() { }

  ngOnInit() {
    console.log('side bar component..');
    this.isActive = this.router.url;
    this.isActive = '/admin';
    if(localStorage.superAdmin==='true' && localStorage.partner!=='true'){
      this.super_admin=true;
      this.partner=false;
      this.isActive = this.router.url;
      this.isActive = '/admin/cityRequests';
    }
    else if(localStorage.partner==='true' && localStorage.superAdmin!=='true'){
      this.partner=true;
      this.super_admin=false;
      this.isActive = this.router.url;
      this.isActive = '/admin/partners';
    }
    else{
      this.super_admin=false;
      this.partner=false;
      this.isActive = this.router.url;
      this.isActive = '/admin';
    }
  }

  addActive() {
    this.isActive = this.router.url;
    console.log("url ", this.router.url);
  }

}
