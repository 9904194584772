export const environment = {
    production: false,
    stage: 'stage',
    region: 'us-east-1',
    identityPoolId: 'us-east-1:2db6853c-59ce-401e-83f9-5199f39a161b',
    userPoolId: 'us-east-1_gEAppnQzM',
    clientId: '4mb318b46auq43tl8ka7tusd2e',
    apiUrl: 'https://q3qapxnbvf.execute-api.us-east-1.amazonaws.com/stage',
    newapiUrl: 'https://efh92i7te1.execute-api.us-east-1.amazonaws.com/stage',
    slotUrl: 'https://d4g75e2sgi.execute-api.us-east-1.amazonaws.com/stage',
    facilityUrl: 'https://g74c4x003m.execute-api.us-east-1.amazonaws.com/stage',
    subscriptionsUrl: 'https://ovvmij3tga.execute-api.us-east-1.amazonaws.com/stage',
    usersessionUrl: 'https://api.stage.fieldsmanager.io',
    logoStage: 'stage',
    URL: '.stage-fm.bizdevworks.com',
    webSocketUrl : 'wss://rgps8d2ku4.execute-api.us-east-1.amazonaws.com/stage',
    paypalURL: 'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    paypaldirectURL: 'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    facebookAuthURL: 'https://fm-stage.auth.us-east-1.amazoncognito.com/authorize?client_id=4mb318b46auq43tl8ka7tusd2e&response_type=token',
    googleAuthURL: 'https://fm-stage.auth.us-east-1.amazoncognito.com/authorize?client_id=4mb318b46auq43tl8ka7tusd2e&response_type=token',
    amazonAuthURL: 'https://fm-stage.auth.us-east-1.amazoncognito.com/authorize?client_id=4mb318b46auq43tl8ka7tusd2e&response_type=token',
    logout: 'https://fm-stage.auth.us-east-1.amazoncognito.com/logout?&client_id=4mb318b46auq43tl8ka7tusd2e&logout_uri=',
    cityId: '3'
};
