import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
declare let $;


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {

	unauthorized = false;
	loadingBar = false;
	error = false;
	msgs: any;
  plan_price: string = "14";
  subscriptions: any = [];


  constructor(
    private homeService: HomeService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getSubscriptions();
  }

  getSubscriptions() {
    this.loadingBar = true;
    this.homeService.getSubscriptions()
    .subscribe((res: any) => {
      console.log('get subscriptions res', res);
      this.loadingBar = false;
      if (res !== "No Bills due") {
        this.subscriptions = res;
      } else {
        this.subscriptions = [];
      }
    });
  }

  updateBill(bill_id) {
    let bill_price = $('#bill_id_'+bill_id).val();
    if (bill_price !== "") {
      bill_price = '$'+bill_price;
      this.homeService.updateBillPrice(bill_id, bill_price)
      .subscribe((res: any) => {
        console.log('update bill price', res);
        this.messageService.add({ 
          severity: 'success', 
          summary: 'Bill Update', 
          detail: 'Bill updated successfully!' 
        });
        this.getSubscriptions();
      });
    } else {
      $('#bill_id_'+bill_id).focus();
      $('#bill_id_'+bill_id).css({
        'border-color': 'red',
        'box-shadow': 'inset 0 1px 1px red, 0 0 8px red'
      });
    }
  }

  onPriceChange(bill_id) {
    let bill_price = $('#bill_id_'+bill_id).val();
    if (bill_price !== "") {
      $('#bill_id_'+bill_id).css({
        'border-color': '#66afe9',
        'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)'
      });
    }
  }

}
