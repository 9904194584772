import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../../service/user-login.service';
import { Callback, CognitoUtil, LoggedInCallback } from '../../service/cognito.service';
import { UserParametersService } from '../../service/user-parameters.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { GroundsSports } from '../../models/grounds-sports';
import { GroundsService } from '../../service/grounds.service';
import {CitiesService} from '../../service/cities.service'
import { ConfirmationService } from 'primeng/api';
import { Validators, FormControl, FormGroup, FormBuilder, AsyncValidator } from '@angular/forms';
import { Ground } from '../../models/ground';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AuthenticationDetails, CognitoUser, CognitoUserSession, ICognitoUserSessionData } from 'amazon-cognito-identity-js';
import { Sport } from '../../models/sport';
import { CheckboxModule } from 'primeng/checkbox';
import { AccessTokenCallback } from '../slots/slots.component';
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
    selector: 'app-grounds',
    templateUrl: './grounds.html'
})
export class GroundsComponent implements LoggedInCallback, OnInit {
    groundform: FormGroup;
    loadingBar = false;
    public parameters: Array<Parameters> = [];
    public cognitoId: String;
    public allGrounds: GroundsSports[] = [];
    public allSports: Sport[] = [];
    public grounds1 = [];
    public sportsForGround = [];
    selectedPark: any;
    unauthorized = false;
    error = false;
    createNewSport = new Sport();
    addGroundDialog = false;
    sportsExist = false;
    moreSportsExist = false;
    groundData = new Ground();
    filteredSportsSingle: any[];
    sport: any;
    sports: any[];
    selectedSportArray: Sport[];
    edit_groundName;
    edit_groundType;
    edit_groundId;
    edit_sportName = [];
    other_sportName: any;
    newSport;
    availableSports = [];
    isNewSport = false;
    isAddSport = false;
    isNotOthers = true;
    isNotNewOther = true;
    viewSport = false;
    showEditGround = false;
    showSports = true;
    hideSports = false;
    GroundTyes: any;
    edit_ground: any;
    add_amenity: any;
    amenityType: any;
    amenitiesData: any;
    allAmenities: any;
    selectedAmenity: any;
    optionalData: any;
    selectedGround: any;
    selectedGroundType: any;
    msgs: any;
    amenity: any;
    checkValidation: boolean = false;
    atLeastOneIsChecked: boolean = false;
    new_sport_price: string;
    isGroundAdded : boolean = false;
    modal_header : string;
    modal_body : string;
    modal_type: string;
    ground_name : string;

    

    constructor(
        public router: Router,
        public userService: UserLoginService,
        public userParams: UserParametersService,
        public groundsservice: GroundsService,
        public citiesService:CitiesService,
        private formbuilder: FormBuilder,
        public confirmationservice: ConfirmationService,
        public cognitoUtil: CognitoUtil,
        private messageService: MessageService) {
        this.userService.isAuthenticated(this);
        this.selectedSportArray = [];
    }

    private newMethod(): any {
        return 'In GroundsComponent';
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            // this.router.navigate(['/home/login']);
            this.router.navigate(['/home/signin']);
        } else {
            this.userParams.getParameters(new GetParametersCallback(this, this.cognitoUtil));
        }
    }

    ngOnInit() {
        this.cognitoUtil.refresh();
        this.loadGrounds();
        this.getSports();
        this.selectedPark = localStorage.getItem('defaultParkName');
        console.log('selected Park is:', this.selectedPark);
        this.groundform = this.formbuilder.group({
            'groundName': new FormControl('', Validators.required),
            'groundType': new FormControl('', Validators.required),
            'sportName': new FormControl('', Validators.required),
            'selectedSport': new FormControl(''),
            'sportPrice': new FormControl('', Validators.required)
        });
       this.getGroundTypes();
    }

    onClickAccordian(groundId) {
    this.loadingBar = true;
     this.getAmenities(groundId);
        $('.collapse').on('shown.bs.collapse', function(){
        $(this).parent().find(".glyphicon-plus-sign").removeClass("glyphicon-plus-sign").addClass("glyphicon-minus-sign");
        }).on('hidden.bs.collapse', function(){
        $(this).parent().find(".glyphicon-minus-sign").removeClass("glyphicon-minus-sign").addClass("glyphicon-plus-sign");
        });
    }

    getAmenities(groundId) {
        this.citiesService.getAmenityDetails(groundId).subscribe((response) => {
            let finalAmenities = [];
            let finalOptional = [];
                console.log('response from get amenities', response);
                this.allAmenities = response.body;
                response.body.forEach(e => {
                    if (e.Amenity_Type === 'Amenity') {
                        finalAmenities.push(e.Amenity);
                    } else if (e.Amenity_Type === 'Optional') {
                        finalOptional.push(e.Amenity);
                    }
                });
                console.log('these are the final amenities', finalAmenities);
                console.log('these are the optional amenities', finalOptional);
                this.amenitiesData = finalAmenities;
                this.optionalData = finalOptional;
                this.loadingBar = false;
        },
            (error) => {
                this.amenitiesData="";
                this.optionalData = "";
                this.loadingBar = false;
            });
    }

    onClickDeleteAmenity(type, amenity, groundId) {
        console.log('this is all amenities', this.allAmenities);
        this.allAmenities.forEach( (e) => {
            if (e.Amenity_Type === type && e.Amenity === amenity && e.Ground_Id === groundId) {
                this.selectedAmenity = e.Amenity_Id;
            }
        });
     this.confirmationservice.confirm({
        message: 'Are you sure?',
        header: 'Delete Item',
        accept: () => {
            console.log(this.selectedAmenity);
            this.deleteAmenity(this.selectedAmenity, groundId);
        },
        reject: () => { }
    })
    }

    deleteAmenity(inputParams, groundId) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        const outputParams = {
            'amenityId': inputParams,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        this.groundsservice.deleteAmenity(outputParams).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.loadingBar = false;
                this.getAmenities(groundId);
                this.messageService.add({ severity: 'success', summary: 'Amenities Update', detail: 'Amenity deleted successfully' });
           }
        }, err => { this.errorHandle(err) });
    }

    onEditGround(Id) {
     this.edit_ground = Id;
    }

    onaddAmenity(type, Id) {
      this.add_amenity = Id;
      this.amenityType = type;
       setTimeout(function () {
      $('.grd_aminti_txt input').val('');
      }.bind(this), 0);
    }

    onSaveAmenity(amenity, groundId, amenityType) {
        this.loadingBar = true;
     console.log('Data::', amenity, groundId, amenityType);
     const outputParams = {
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'cityId': sessionStorage.getItem('cityId'),
        'amenity': amenity,
        'groundId': groundId,
        'amenityType': amenityType
    };
    console.log('add amenity', outputParams);
    this.groundsservice.addAmenity(outputParams).subscribe(response => {
        console.log('The response is', response);
        if (response.status === 200) {
            this.loadingBar = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Amenities Update',
                detail: 'Amenity added successfully'
            });
            this.getAmenities(groundId);
        } else if (response.status === 201) {
            this.loadingBar = false;
            this.messageService.add({
                severity: 'warn',
                summary: 'Amenities Update',
                detail: 'Amenity already exists'
            });
            this.getAmenities(groundId);
        }
    }, err => {
        this.errorHandle(err)
    });
        document.getElementById('button3').click();
        this.add_amenity = false;
        this.amenityType = '';
    }

    oncancelAmenity() {
      this.add_amenity = false;
      this.amenityType = '';
      $('.grd_aminti_txt input').val("");
    }

    getGroundTypes(){
      this.GroundTyes=[{"label":"Indoor","value":"1"},{"label":"Outdoor","value":"2"}]
      this.selectedGroundType=this.GroundTyes[0].label;
      this.selectedGround=this.GroundTyes[0].value;
    }

    onCancelGround(){
     this.edit_ground=false;
    }

    onClickEditGround(ground) {
        this.showSports = true;
        this.hideSports = false;
        this.showEditGround = true;
        // $('.edit_ground1').attr('disabled', 'disabled');
        this.edit_groundName = ground.groundName;
        this.edit_groundId = ground.groundId;
        if(ground.groundType=='Outdoor'){
          this.edit_groundType=2;
        }
        else{
          this.edit_groundType=1;
        }
        if (ground.moreSports !== undefined) {
            this.edit_sportName = (ground.sport).concat(ground.moreSports);
        } else {
            this.edit_sportName = ground.sport;
        }
        console.log('this is the edit_sportname', this.edit_sportName);
    }

    addNewSport(sports) {
        // (document.getElementById("") as HTMLInputElement).checked = false;
        this.selectedSportArray = [];
        this.availableSports = [];
        this.showEditGround = false;
        $('.modal-backdrop').hide();
        $('.edit_ground2').attr('disabled', 'disabled');
        this.newSport = true;
        var sportNames = [];
        sports.forEach(function (a) {
            sportNames.push(a.sportName);
        });
        var filteredEvents = this.allSports.filter(function (event) {
            return !sportNames.includes(event.sportName)
        });
        this.availableSports = filteredEvents;
        this.viewSport = false;
    }
    veiwSport() {
        this.viewSport = true;
        this.showSports = false;
        this.hideSports = true;
    }
    hideSport() {
        this.viewSport = false;
        this.showSports = true;
        this.hideSports = false;
    }
    cancelGround() {
        this.viewSport = false;
    }
    getSports() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        this.groundsservice.getSports().subscribe(response => {
            let tempObjArray: Sport[] = [];
            if (response.status === 200) {
                if (response.body.length === 0) {
                    this.allSports = [];
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'info',
                        summary: 'Grounds Update',
                        detail: 'No sports exist'
                    });
                } else {
                    response.body.forEach((o) => {
                        let obj = new Sport();
                        obj.sportId = o['Sport_Id'];
                        obj.sportName = o['Sport_Name'];
                        obj.checked = false;
                        tempObjArray.push(obj);
                    });
                    this.allSports = tempObjArray;
                    this.loadingBar = false;
                }
            }
        },
            err => {
                this.errorHandle(err)
            });
    }
    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
        } else {
            this.loadingBar = false;
            this.error = true;
        }
    }
    change(e, type,param) {
        var atLeastOneIsChecked = $('input[name="check"]:checked').length > 0;
        this.atLeastOneIsChecked=atLeastOneIsChecked;

        if (e.target.checked) {
            $('.edit_ground2').removeAttr('disabled');
            if(param=='sportadd'){
                type['Custom_Price'] = "";
                // this.edit_sportName = this.edit_sportName.concat(type)
                this.selectedSportArray.push(type);
            }
            if(param=='add'){
                type['Custom_Price'] = 0;
                this.selectedSportArray.push(type);
            }
            this.allSports.forEach((element) => {
                if (element.sportId === type.sportId) {
                    element.checked = true;
                }
            });
        } else {
            let updateItem = this.selectedSportArray.find(this.findIndexToUpdate, type.sportName);
            this.allSports.forEach((element) => {
                if (element.sportId === type.sportId) {
                    element.checked = false;
                }
            });
            // $('.edit_ground2').attr('disabled', 'disabled');
            $('.edit_ground2').removeAttr('disabled');
            let index = this.selectedSportArray.indexOf(updateItem);
            this.selectedSportArray.splice(index, 1);
        }
    }

    priceChange(sport, e) {
        let price = '$'+e.target.value;
        this.selectedSportArray = this.selectedSportArray.filter((res: any) => {
            if (res._sportId === sport._sportId) {
                return res.Custom_Price = price;
            } else {
                return res;
            }
        });
    }

    findIndexToUpdate(type) {
        return type.sportName === this;
    }

    private loadGrounds() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        if (sessionStorage.getItem('authorizationToken') !== null || sessionStorage.getItem('authorizationToken') !== undefined) {
            this.allGrounds = [];
            const params = {
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'cityId': sessionStorage.getItem('cityId'),
                'parkId': localStorage.getItem('defaultParkId')
            };
            this.groundsservice.getGrounds(params).subscribe(response => {
                if (response.status === 200) {
                    console.log("grounds data is:"+response.body)
                    let tempObjArray: GroundsSports[] = [];
                    if (response.body.length === 0) {
                        this.loadingBar = false;
                        this.allGrounds = [];
                        this.messageService.add({
                            severity: 'info',
                            summary: 'No grounds exist',
                            detail: 'Please add a ground'
                        });
                    } else {
                        response.body.forEach((o) => {
                            let obj = new GroundsSports();
                            obj.gsId = o['GS_Id'];
                            obj.groundId = o['Ground_Id'];
                            obj.sportId = o['Sport_Id'];
                            obj.sportName = o['Sport_Name'];
                            obj.groundName = o['Ground_Name'];
                            obj.groundType = o['Ground_Type'];
                            obj.gsStatus = o['GS_Status'];
                            obj.customPrice=o['Custom_Price']!=null?(o['Custom_Price'].includes("$")?o['Custom_Price'].split("$")[1]:o['Custom_Price']):o['Custom_Price'];
                            tempObjArray.push(obj);
                        });
                        this.allGrounds = tempObjArray;
                        let groundsList = {};
                        for (let i = 0; i < this.allGrounds.length; i++) {
                            if (!groundsList[this.allGrounds[i].groundName]) {
                                groundsList[this.allGrounds[i].groundName] = {};
                                groundsList[this.allGrounds[i].groundName]['sport'] = [];
                                groundsList[this.allGrounds[i].groundName]['moreSports'] = [];
                                groundsList[this.allGrounds[i].groundName]['groundId'] = this.allGrounds[i].groundId;
                                groundsList[this.allGrounds[i].groundName]['groundType'] = this.allGrounds[i].groundType;
                                if (this.allGrounds[i].gsStatus === 'Active' && this.allGrounds[i].gsStatus !== null && this.allGrounds[i].gsStatus !== undefined) {
                                    let tempObj = {};
                                    tempObj['gsId'] = this.allGrounds[i].gsId;
                                    tempObj['sportId'] = this.allGrounds[i].sportId;
                                    tempObj['sportName'] = this.allGrounds[i].sportName;
                                    tempObj['gsStatus'] = this.allGrounds[i].gsStatus;
                                    tempObj['customPrice'] = this.allGrounds[i].customPrice;
                                    if (groundsList[this.allGrounds[i].groundName]['sport'].length < 4) {
                                        groundsList[this.allGrounds[i].groundName]['sport'].push(tempObj);
                                    } else if (groundsList[this.allGrounds[i].groundName]['sport'].length = 4) {
                                        groundsList[this.allGrounds[i].groundName]['moreSports'].push(tempObj);
                                    }
                                }
                            } else {
                                groundsList[this.allGrounds[i].groundName]['groundId'] = this.allGrounds[i].groundId;
                                groundsList[this.allGrounds[i].groundName]['groundType'] = this.allGrounds[i].groundType;
                                if (this.allGrounds[i].gsStatus === 'Active' && this.allGrounds[i].gsStatus !== null && this.allGrounds[i].gsStatus !== undefined) {
                                    let tempObj = {};
                                    tempObj['gsId'] = this.allGrounds[i].gsId;
                                    tempObj['sportId'] = this.allGrounds[i].sportId;
                                    tempObj['sportName'] = this.allGrounds[i].sportName;
                                    tempObj['gsStatus'] = this.allGrounds[i].gsStatus;
                                    tempObj['customPrice'] = this.allGrounds[i].customPrice;
                                    if (groundsList[this.allGrounds[i].groundName]['sport'].length < 4) {
                                        groundsList[this.allGrounds[i].groundName]['sport'].push(tempObj);
                                    } else if (groundsList[this.allGrounds[i].groundName]['sport'].length = 4) {
                                        groundsList[this.allGrounds[i].groundName]['moreSports'].push(tempObj);
                                    }
                                }
                            }
                        }
                        let groundsFinal = [];
                        Object.keys(groundsList).forEach(function (key) {
                            console.log('this is the current key', key);
                            let tempObject = {};
                            tempObject['groundName'] = key;
                            tempObject['groundId'] = groundsList[key]['groundId'];
                            tempObject['groundType'] = groundsList[key]['groundType'];
                            tempObject['sport'] = groundsList[key]['sport'];
                            tempObject['moreSports'] = groundsList[key]['moreSports'];
                            if (tempObject['moreSports'].length > 0) {
                                tempObject['moreSportsExist'] = true;
                            } else { tempObject['moreSportsExist'] = false; }
                            groundsFinal.push(tempObject);
                        });
                        this.grounds1 = groundsFinal;
                        this.loadingBar = false;
                    }
                }
            }, err => {
                this.errorHandle(err)
            });
        }
    }
    onClickDeleteSport(selectedSport) {
        $('.popover_close').hide();
        this.confirmationservice.confirm({
            message: 'Are you sure?',
            header: 'Delete Sport',
            accept: () => {
                this.deleteSport(selectedSport);
                $('.popover_close').show();
            },
            reject: () => {
                $('.popover_close').show();
            }
        })
    }
    deleteSport(inputParams) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        const outputParams = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'cityId': sessionStorage.getItem('cityId'),
            'gsId': inputParams.gsId
        };
        this.groundsservice.deleteSport(outputParams).subscribe(response => {
            if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Grounds Update',
                    detail: 'Sport deleted successfully'
                });
                this.loadGrounds();
            }
        }, err => {
            this.errorHandle(err)
        });
    }
    onClickDeleteGround(selectedGround) {
        this.confirmationservice.confirm({
            message: 'Are you sure?',
            header: 'Delete Ground',
            accept: () => {
                this.deleteGround(selectedGround);
            },
            reject: () => { }
        })
    }
    deleteGround(inputParams) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        const outputParams = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'cityId': sessionStorage.getItem('cityId'),
            'groundId': inputParams.groundId
        };
        this.groundsservice.deleteGround(outputParams).subscribe(response => {
            if (response.status === 200) {
                this.allGrounds = [];
                this.grounds1 = [];
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Grounds Update',
                    detail: 'Ground deleted successfully'
                });
                this.loadGrounds();
            }
            if (response.status === 201) {
                this.allGrounds = [];
                this.grounds1 = [];
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Grounds Update',
                    detail: 'Ground deleted successfully, error sending notification!'
                });
                this.loadGrounds();
            }
        }, err => {
            this.errorHandle(err)
        });
    }
    onClickAddGround() {
        this.checkValidation = false;
        this.selectedSportArray = [];
        this.addGroundDialog = true;
        $('.sports_check').prop('checked', false);
        $('.othrs_check').prop('checked', false);
        this.groundform.reset();
        this.isAddSport = false;
        this.isNotNewOther=true;
    }
    addGround() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        this.addGroundDialog = false;
        this.isAddSport = false;
        $('.modal-backdrop').hide();
        $('.sprts_chck_box input:checkbox').removeAttr('checked');
        this.isNotNewOther = true;
            if (this.groundData.groundType === '1') {
               var groundType = 'Indoor';
            } else {
               var groundType = 'Outdoor';
            }
            this.ground_name = this.groundData.groundName;
            if (this.groundData.sportName === null || this.groundData.sportName === undefined) {
                const outputParams = {
                    'authorizationToken': sessionStorage.getItem('authorizationToken'),
                    'cityId': sessionStorage.getItem('cityId'),
                    'parkId': localStorage.getItem('defaultParkId'),
                    'groundName': this.groundData.groundName,
                    'sport': JSON.stringify(this.selectedSportArray),
                    'sportPrice': this.groundform.value.sportPrice,
                    'groundType': groundType
                };   
            console.log('outputParams', outputParams);
            console.log(8888, this.atLeastOneIsChecked, this.groundform.controls['sportPrice'].status);
            if (( this.groundData.groundName === undefined || this.groundform.controls['groundName'].status==='INVALID')|| (this.selectedSportArray.length===0 && this.atLeastOneIsChecked==false) || this.groundData.groundType==undefined || this.groundform.controls['groundType'].status==='INVALID' || (this.isNewSport==true && this.groundform.controls['sportPrice'].status === 'INVALID')){
            this.loadingBar = false;
            this.checkValidation = true;
            var atLeastOneIsChecked1 = $('input[name="othercheck"]:checked').length > 0;
                if(atLeastOneIsChecked1){
                  this.isAddSport = true;
                  this.isNotNewOther=false;
                }
            }
            else{
                this.groundform.reset();
                document.getElementById('addGroundClose').click();
                this.checkValidation = false;
                this.groundsservice.addGround(outputParams).subscribe(response => {
                    console.log('The response is', response);
                    if (response.status === 200) {
                        this.loadingBar = false;
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Grounds Update',
                            detail: 'Ground added successfully'
                        });
                        this.loadGrounds();
                        this.isGroundAdded = true;
                        this.modal_header ="Create New Slot Alert";
                        this.modal_body = "There are no slots onboarded under the &nbsp;"+this.ground_name.bold()+"&nbsp;.Would you like to create one?"
                        this.modal_type = "slots"
                    }else if (response.status === 201) {
                        this.loadingBar = false;
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Grounds Update',
                            detail: 'Ground already exists'
                        });
                        this.loadGrounds();
                    }
                }, err => {
                    this.errorHandle(err)
                });
            } 
            this.selectedSportArray = [];
        } else {
            const outputParams = {
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'cityId': sessionStorage.getItem('cityId'),
                'parkId': localStorage.getItem('defaultParkId'),
                'groundName': this.groundData.groundName,
                'sport': this.groundData.sportName,
                'sportPrice': '$'+this.groundform.value.sportPrice,
                'groundType':groundType
            };
            console.log(7777, this.isNewSport, this.groundform.controls['sportPrice'].status);
            if (( this.groundData.groundName === undefined || this.groundform.controls['groundName'].status==='INVALID')||(this.groundData.sportName===undefined || this.groundform.controls['sportName'].status==='INVALID') || this.groundData.groundType==undefined || this.groundform.controls['groundType'].status==='INVALID' || (this.isNewSport == true && this.groundform.controls['sportPrice'].status === 'INVALID')){
                this.checkValidation = true;
                var atLeastOneIsChecked1 = $('input[name="othercheck"]:checked').length > 0;
                if(atLeastOneIsChecked1){
                    this.isAddSport = true;
                    this.isNotNewOther=false;
                }
            }
            else{
                console.log('Other sports', outputParams);  
                document.getElementById('addGroundClose').click();
                this.checkValidation = false;
                this.groundform.reset();
                console.log('outputParams', outputParams);
                this.groundsservice.addGround(outputParams).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Grounds Update',
                        detail: 'Ground added successfully'
                    });
                    this.loadGrounds();
                }
                }, err => {
                this.errorHandle(err)
                });
            }

        }
    }
    addGroundCancel() {
        document.getElementById('addGroundClose').click();
        this.addGroundDialog = false;
        this.groundform.reset();
    }
    Sport(event) {
        this.isNewSport = true;
        this.isAddSport = false;
        if (event.target.checked === true) {
            this.isNotOthers = false;
        } else {
            this.isNotOthers = true;
            this.isNewSport = false;
        }
    }
    addSport(event) {
        var atLeastOneIsChecked = $('input[name="othercheck"]:checked').length > 0;
         this.atLeastOneIsChecked=atLeastOneIsChecked; 

         if (event.target.checked === true) {
             this.isNewSport = true;
             this.isAddSport = true;
             this.isNotNewOther = false;
        } else {
            this.isNewSport = false;
            this.isAddSport = false;
            this.isNotNewOther = true;
        }
    }
    otherSportKey(name) {
        if (name.length > 0 && !this.isAddSport) {
            $('.edit_ground2').removeAttr('disabled');
        } else {
            $('.edit_ground2').attr('disabled', 'disabled');
        }
    }
    groundChangeKey(ground) {
        if (ground.length > 0) {
            $('.edit_ground1').removeAttr('disabled');
        } else {
            $('.edit_ground1').attr('disabled', 'disabled');
        }
    }
    createSport(sport) {
        $('.modal-backdrop').hide();
        this.isAddSport = false;
    }
    changeSport(e, val) {

    }
    
    updateGround(ground,groundTyp) {
        const sportObj=[];
        this.edit_sportName.forEach(o => {
            sportObj.push({GS_Id:o.gsId,_sportId:o.sportId,_sportName:o.sportName,Custom_Price:o.customPrice.includes("$")?o.customPrice.split("$")[1]:o.customPrice})
        });
        if(groundTyp=='1'){
          var groundType="Indoor";
        }
        else{
          var groundType="Outdoor";
        }
        this.loadingBar = true;
        this.hideSport();
        document.getElementById('editGroundClose').click();
        $('.modal-backdrop').hide();
        if (this.selectedSportArray.length === 0 && (this.other_sportName === undefined || this.other_sportName === '')) {
            const outputParams = {
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'cityId': sessionStorage.getItem('cityId'),
                'parkId': localStorage.getItem('defaultParkId'),
                'groundName': ground,
                'groundId': this.edit_groundId,
                'groundType': groundType,
                'sport':JSON.stringify(sportObj)
            };
            this.groundform.reset();
            this.groundsservice.editGround(outputParams).subscribe(response => {
                if (response.status === 200) {
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Grounds Update',
                        detail: 'Ground updated successfully'
                    });
                    this.loadGrounds();
                }
            }, err => {
                this.errorHandle(err)
            });
        } else if (this.selectedSportArray.length > 0 && (this.other_sportName === undefined || this.other_sportName === '')) {
            const outputParams = {
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'cityId': sessionStorage.getItem('cityId'),
                'parkId': localStorage.getItem('defaultParkId'),
                'groundName': ground,
                'groundId': this.edit_groundId,
                'groundType': groundType,
                'sport': JSON.stringify(this.selectedSportArray)
            };
            this.groundform.reset();
            this.groundsservice.editGround1(outputParams).subscribe(response => {
                if (response.status === 200) {
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Grounds Update',
                        detail: 'Ground updated successfully'
                    });
                    this.loadGrounds();
                }
                this.selectedSportArray = [];
                this.other_sportName = undefined;
            }, err => {
                this.errorHandle(err)
            });
        } else if ((this.other_sportName !== undefined || this.other_sportName !== '') && this.selectedSportArray.length === 0) {
            const outputParams = {
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'cityId': sessionStorage.getItem('cityId'),
                'parkId': localStorage.getItem('defaultParkId'),
                'groundName': ground,
                'groundId': this.edit_groundId,
                'groundType': groundType,
                'sport': this.other_sportName
            };
            this.groundform.reset();
            this.groundsservice.editGround1(outputParams).subscribe(response => {
                if (response.status === 200) {
                    this.other_sportName = undefined;
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Grounds Update',
                        detail: 'Ground updated successfully'
                    });
                    this.loadGrounds();
                }
            }, err => {
                this.errorHandle(err)
            });
        }
    }

    updateSport(sport) {
        this.hideSport();
        // this.edit_sportName.push(this.selectedSportArray)
        // console.log(this.edit_sportName)
        document.getElementById('addNewSportClose').click();
        $('.modal-backdrop').show();
        this.showEditGround = true;
        this.other_sportName = sport.sportName;
        this.availableSports = [];
        $('.sprts_chck_box input:checkbox').removeAttr('checked');
        this.isNotOthers = true;
        this.isNewSport = false;
        this.createNewSport.sportName = '';
    }
    cancelSport() {
        for(let i =0;i<this.availableSports.length;i++){
            let id = "#"+this.availableSports[i].sportName;
            (document.getElementById(id) as HTMLInputElement).checked = false;
        }
        $('.modal-backdrop').show();
        this.hideSport();
        this.showEditGround = true;
    }
}

export class Parameters {
    name: string;
    value: string;
}

export class GetParametersCallback implements Callback {

    constructor(public me: GroundsComponent, public cognitoUtil: CognitoUtil) {

    }

    callback() {

    }

    callbackWithParam(result: any) {

        for (let i = 0; i < result.length; i++) {
            let parameter = new Parameters();
            parameter.name = result[i].getName();
            parameter.value = result[i].getValue();
            this.me.parameters.push(parameter);
        }
        let param = new Parameters()
        param.name = 'cognito ID';
        param.value = this.cognitoUtil.getCognitoIdentity();
        this.me.parameters.push(param)
    }
}
