import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminsService } from '../../service/admins.service';
import { SlotsService } from '../../service/slots.service';
import { Ground } from '../../models/ground';
import { Sport } from '../../models/sport';
import { Slot } from '../../models/slot';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as _ from "lodash"
import { ExportExcelService } from '../../service/export-excel.service';
declare let $;



@Component({
  selector: 'app-reservation-history',
  templateUrl: './reservation-history.component.html',
  styleUrls: ['./reservation-history.component.css']
})
export class ReservationHistoryComponent implements OnInit {

  public resHistory: any;
  createSlot = new Slot();
  selectedPark: any;
  public allGrounds = [];
  selectedGround: any;
  public allSports = [];
  selectedSport: any;
  selectedDate: any;
  startdate: any;
  enddate: any;
  unauthorized = false;
  error = false;
  loadingBar: boolean = false;
  noReservations: boolean = false;
  reservationHistory=[];
  public moment: any = moment;
  slotcitySearch:string="";
  slotparkSearch:string="";
  slotgroundSearch:string="";
  slotsportSearch:string="";
  slotstatusSearch:string="";
  tableLength: number;
  isPagination: boolean = true;

  constructor(
    public adminsservice: AdminsService,
    public slotsservice: SlotsService,
    private excelService: ExportExcelService
  ) { }

  ngOnInit() {
   this.selectedPark = localStorage.getItem('defaultParkName');
   this.enddate = new Date(new Date(moment().tz('America/Chicago').toISOString()).setHours(0))
   this.startdate = new Date(new Date(moment(this.startdate).add(-10, 'days').toISOString()).setHours(0));
//    this.getGrounds();
   let initialParams = {
    'cityId': sessionStorage.getItem('cityId'),
    'authorizationToken': sessionStorage.getItem('authorizationToken')
   }
   this.getReservationHistory(initialParams);
  }

getReservationHistory(inputParams) {
    this.allGrounds = [], this.allSports = [];
    this.loadingBar = true;
  this.adminsservice.getReservationHistory(inputParams).subscribe((response:any) => {
   if (response.body.length === 0) {
      this.loadingBar = false;
  } else {
    this.loadingBar = false;
    this.reservationHistory=response.body; 
    this.myfndataTable();
  }
  }, err => { this.errorHandle(err) });

}

reInitDatatable() {
  $('#resblk').DataTable().destroy();
  setTimeout(function(){
    if(($('.table tbody tr td').html()=='No data available in table') || ($('.table tbody tr td').html()==undefined)){
      $('.no_records').css('margin-top', '15px');
      this.isPagination = false;
      this.myfndataTable()
    } else {
     this.isPagination = true;
     this.myfndataTable()
     $('.no_records').css('margin-top', '0px');
    }
  }.bind(this), 500);
}

  myfndataTable() {
    setTimeout(function(){
      var table = $('#resblk').dataTable({
        "bAutoWidth": false,
        "bPaginate": this.isPagination,
        "sPaginationType":"full_numbers",
        "iDisplayLength": 10,
        language: {
            "paginate": {
            "previous": '<i class="fa fa-backward">',
            "next":'<i class="fa fa-forward">',
            'first':'<i class="fa fa-step-backward">',
            "last":'<i class="fa fa-step-forward">'
            }
        }
      })
    }.bind(this), 0);
  }

  errorHandle(err) {
      if (err.status === 401) {
          this.loadingBar = false;
          this.unauthorized = true;
          this.loadingBar = false;
          this.error = true;
      } else {
          this.loadingBar = false;
          this.error = true;
      }
  }

  exportToExcel():void {
    this.excelService.exportAsExcelFile(this.reservationHistory, 'sample');
  }
}
