import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { City } from '../models/city';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class CitiesService {
   
  private dataSource = new BehaviorSubject('default message');
  currentMessage = this.dataSource.asObservable();

  constructor(private http: HttpClient) { }

  sendData(data: any) {
    this.dataSource.next(data)
  }

  sendBookingData(data: any) {
    this.dataSource.next(data)
  }

  sendViewData(data: any) {
    this.dataSource.next(data)
  }

  cityDetails = new EventEmitter<any>();


   public plans = [
  {
    "Plan_Id" : 1,
    "Plan_Name" : "Basic",
    "Parks_Limit" : "1",
    "Grounds_Per_Park" : "1",
    "Sports_Per_Ground" : "1",
    "Plan_Status" : "Active",
    "Price" : "$19.99",
    "Edited_By" : null
  },
{
    "Plan_Id" : 2,
    "Plan_Name" : "Advanced",
    "Parks_Limit" : "1",
    "Grounds_Per_Park" : "5",
    "Sports_Per_Ground" : "3",
    "Plan_Status" : "Active",
    "Price" : "$49.99",
    "Edited_By" : null
  },
  {
    "Plan_Id" : 3,
    "Plan_Name" : "Premium",
    "Parks_Limit" : "Unlimited",
    "Grounds_Per_Park" : "Unlimited",
    "Sports_Per_Ground" : "Unlimited",
    "Plan_Status" : "Active",
    "Price" : "contact",
    "Edited_By" : null
  },
  {
    "Plan_Id" : 4,
    "Plan_Name" : "Pay as you go",
    "Parks_Limit" : "Unlimited",
    "Grounds_Per_Park" : "Unlimited",
    "Sports_Per_Ground" : "Unlimited",
    "Plan_Status" : "Active",
    "Price" : "50cents",
    "Edited_By" : null
  },
  {
    "Plan_Id" : 5,
    "Plan_Name" : "Free",
    "Parks_Limit" : "1",
    "Grounds_Per_Park" : "1",
    "Sports_Per_Ground" : "1",
    "Plan_Status" : "Active",
    "Price" : "0",
    "Edited_By" : null
  }
];

public amenities={'GroundType':'Indoor/Outdoor',"data":["amenity1","amenity2","amenity3"]}


  public addCity(params): Observable<HttpResponse<any>> {
    const url = environment.apiUrl + '/city' + '?cityName=' + params.cityName +'&accountType='+ params.accountType + '&street='+ params.street + '&city='+ params.city + '&state='+ params.state + '&zipCode='+ params.zipCode + '&website='+ params.website + '&domain='+ params.domain + '&firstName='+ params.firstName + '&lastName='+ params.lastName + '&phoneNumber='+ params.phoneNumber + '&email='+ params.email + '&username='+ params.username + '&planId='+ params.planId + '&parksLimit='+ params.parksLimit + '&groundsPerPark='+ params.groundsPerPark + '&sportsPerGround='+ params.sportsPerGround + '&price='+ params.price + '&createdAt='+ params.createdAt + '&partner_id='+params.partner_id+'&partner_inv_id='+params.partner_inv_id+'&partner_inv_email='+params.partner_inv_email+'&amazonId='+params.amazonId;
    console.log("this is url::",url);
    return this.http.post<any>(url, '', {observe: 'response'});
  }
  public uploadServiceAgreement(doc, cityIdIn): Observable<HttpResponse<any>> {
    console.log("terms file is : ", doc);
    const url = environment.apiUrl + '/agreements'
    return this.http.post<any>(url, {	base64String : doc, cityId : cityIdIn}, {observe: 'response'});
  }
  public getCity(): Observable<HttpResponse<any>> {
    const url = environment.apiUrl + '/city';
    return this.http.get<any>(url, {observe: 'response'});
  }
  public getCityDetails(params): Observable<HttpResponse<any>> {
    console.log(params);
    const url = environment.facilityUrl + '/cities' + '?City_Id=' + params;
    return this.http.get<any>(url, { observe: 'response' });
  }

  public uploadLogo(image, cityId): Observable<HttpResponse<any>> {
      let params = new HttpParams();
      params = params.append('cityId', cityId);
      console.log('Params to update tax:', params);
      let headers = new HttpHeaders().set('authorizationtoken', sessionStorage.getItem('authorizationToken'));
      const url = environment.apiUrl + '/city/logo';
      return this.http.put<any>(url, {  image : image, cityId : cityId}, { observe: 'response', params: params, headers: headers });
  }

  public updateTax(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('tax', data.tax);
        console.log('Params to update tax:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/city/tax';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updateServiceFee(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('City_Id', data.City_Id);
        params = params.append('Service_Fee', data.Service_Fee);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.usersessionUrl + '/facility/cities/servicefee';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updatePayPal(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('cityId', data.cityId);
    params = params.append('clientId', data.clientId);
    params = params.append('secret', data.secret);
    console.log('Params to update tax:', params);
    let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    const url = environment.apiUrl + '/city/paypal';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
}

  getPlans(): Observable<HttpResponse<any>> {
    const url = environment.subscriptionsUrl+'/plans';
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAmenityDetails(params): Observable<HttpResponse<any>> {
    const url = environment.apiUrl + '/grounds/amenitiesbyground' + '?groundId=' + params;
    return this.http.get<any>(url, { observe: 'response' });
  }
  getWeather(params): Observable<HttpResponse<any>> {
    const url = environment.apiUrl + '/city/weather' + '?location=' + params;
    return this.http.get<any>(url, { observe: 'response' });
  }
}
