import { Component, OnInit,OnDestroy } from '@angular/core';
import { CitiesService } from './../../service/cities.service';
import { Router,ActivatedRoute } from "@angular/router";
import {Reservation} from './../../models/reservation';
import {Validators, FormControl, FormGroup, FormBuilder, AsyncValidator} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {HomeService} from '../../service/home.service';
declare let paypal: any;
declare let paypal_auth:any;
import {StepsModule} from 'primeng/steps';
import {MenuItem} from 'primeng/api';
import { environment } from '../../../environments/environment';
import {UserFormService} from './../../service/user-form.service';
import { UserLoginService } from '../../service/user-login.service';
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

    sports;
    Slot_Date
    router_link;
    firstName;
    radio_option=true;
    lastName;
    email;
    mobile;
    parks;
    reservationData = new Reservation();
    reservationform: FormGroup;
    termsCheck: boolean;
    addScript: boolean = false;
    paypalLoad: boolean = true;
    paypalToken: any;
    payment_option=false;
    addlaterScript: boolean = false;
    auto_fifo_option;
    items: MenuItem[];
    activeIndex: number = 0;
    paymentDetailsTab: boolean = false;
    userDetailsTab: boolean = true;
    confirmation_page:boolean=false;
    selectedSlotPaidStatus;
    paypal_authorizationId: any;
    paypal_amount: any;
    orderId: any;
    total_amount:any;
    countdownTimer:any;
    seconds:any;
    countdown:any;
    timeInterval:any;
    loading_spinner=false;
    user = 'false';
    superAdmin='false';
    cityAdmin='false';
    errInGuestDetails: boolean = false;
    paypal_client_id: string;
    paypal_secret_key: string;
    Tax_Percentage: any;
    payByCash:boolean=false;
    process_type: any;
    support_requests: boolean = false;
    sports_details=false;
    show_allSports=false;
    park_slotsDetails=false;
    reportIssue = false;
    feedback=false;
    Service_fee : any;
    loadingBar : boolean = false;


  constructor(  public citiesservice: CitiesService,
    public homeService: HomeService,
    private aroute: ActivatedRoute,
    public router: Router,
    private formbuilder: FormBuilder,
    public userFormService: UserFormService,
    public userService: UserLoginService,
    private msgService: MessageService,
    ) {

        this.reservationform = this.formbuilder.group({
            'sportId': new FormControl('', Validators.required),
            'groundId': new FormControl('', Validators.required),
            'selectedDate': new FormControl('', Validators.required),
            'timeSlot': new FormControl('', Validators.required),
            'firstName': new FormControl('', Validators.required),
            'lastName': new FormControl('', Validators.required),
            'teamName': new FormControl(''),
            'residentOf': new FormControl(''),
            'email': new FormControl('', [Validators.email, Validators.required]),
            'phoneNum': new FormControl('', [Validators.min(1000000000), Validators.max(9999999999), Validators.required]),
            'bookingType': new FormControl('', Validators.required),
            'termsCheck': new FormControl(''),
            'payByCash': new FormControl('')
        });

   }

  ngOnInit() {
  	this.loading_spinner=true;
  	this.termsCheck = false;
    this.user = localStorage.getItem('user');
    if(this.user !== 'true'){
     this.router_link='/home/signup'; 	
    }
    else{
    	setTimeout(function(){
    	this.reservationData.firstName = localStorage.getItem('firstName');
    	this.reservationData.lastName=localStorage.getItem('lastName');
    	this.reservationData.residentOf=localStorage.getItem('residentOf');
    	this.reservationData.teamName=localStorage.getItem('teamName');
        this.reservationData.email=localStorage.getItem('email');
        this.reservationData.phoneNumber=localStorage.getItem('phoneNumber'); 
        this.router_link='no';
        this.loading_spinner=false;
        }.bind(this), 5000); 	
    }
	this.citiesservice.currentMessage.subscribe(res => {
	console.log('Response1::', res);
	 if(res === 'default message'){
       this.router.navigate(['/home'])
	 }
	 else{
       this.sports=res;
	 }
	})
    
    this.homeService.currentMessage.subscribe(res => {
	console.log('Response2::', res);
	 if(res === 'default message'){
       this.router.navigate(['/home'])
	 }
	 else{
       this.parks=res;
       this.Slot_Date=moment(this.parks.Date).format("MMM Do YYYY")
       if(this.parks.Slot_Type=='Free'){
        $('.wizard_chck_hdr').addClass('free_cls');
           this.items = [{label: 'Bookings',command: (event: any) => {this.activeIndex = 0;}},{label: 'Confirmation',command: (event: any) => {this.activeIndex = 2;}}];
       }
       else{
          $('.wizard_chck_hdr').addClass('paid_cls');
          this.items = [{label: 'Bookings',command: (event: any) => {this.activeIndex = 0;}},{label: 'Payment',command: (event: any) => {this.activeIndex = 1;}},{label: 'Confirmation',command: (event: any) => {this.activeIndex = 2;}}];
       }
       let service_fee = this.parks.Slot_Service_Fee && this.parks.Slot_Service_Fee > 0 ? this.parks.Slot_Service_Fee : this.parks.Service_Fee
       let tax_percent = (this.parks.Tax_Percentage && !(isNaN(this.parks.Tax_Percentage))) ? this.parks.Tax_Percentage : 0
     this.Tax_Percentage = parseFloat(tax_percent);
     this.Service_fee = parseFloat(service_fee);
     this.total_amount=((parseFloat(this.parks.Price)*(parseFloat(this.Tax_Percentage)/100))+parseFloat(this.parks.Price)+parseFloat(this.Service_fee)).toFixed(2);
     
     this.seconds = 300;
     this.timeInterval=setInterval(() => {
     this.countdownTimer =this.secondPassed();
     }, 1000);
	 }
  })

  this.getPaypalDetails();
   
  }

  getPaypalDetails() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      console.log('get paypal res', res);
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
      }
    })
  }

  gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
    this.router.navigate(['/home/support-requests']);
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

	secondPassed() {
		var minutes = Math.round((this.seconds - 30)/60);
		var remainingSeconds = this.seconds % 60;
		if (remainingSeconds < 10) {
		remainingSeconds = 0 + remainingSeconds;  
		}
		if (this.seconds == 0) {
		clearInterval(this.countdownTimer);
		this.router.navigate(['/home'])
		} else {
		this.seconds--;
        this.countdown = minutes + ":" + remainingSeconds;
		}
	}

	ngOnDestroy(){
	 clearInterval(this.timeInterval);
	}


    goToMenu(param) {
        if (param === 1) {
            this.userDetailsTab = true; 
            this.paymentDetailsTab=false; 
            this.confirmation_page=false;                     
            this.activeIndex = 0;
        } else if (param === 2) {
            this.userDetailsTab = false;
            this.confirmation_page=false;  
            this.paymentDetailsTab=true;           
            this.activeIndex = 1;
        } else if (param === 3) {
            this.onFormSubmit();

            if (!this.errInGuestDetails) {
              this.userDetailsTab = false;
              this.paymentDetailsTab = false;
              this.confirmation_page = true;

              if (this.parks.Slot_Type == 'Free'){
               this.activeIndex = 1;
               setTimeout(() => {
                 this.router.navigate(['/home/success']);
               }, 2000);
              } else {
                this.router.navigate(['/home/success']);
                this.activeIndex = 2;
              }
            }
        }
    }


     onFormSubmit() {
        if (this.reservationData.teamName === '' || this.reservationData.teamName === undefined) {
            this.reservationData.teamName = null;
        }
        this.reservationData.selectedDate = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
        console.log('sport is: ', this.reservationData.sportId);
        const phone = '+1'.concat(this.reservationData.phoneNumber);
        const createdAt = moment().tz('America/Chicago').format('YYYY-MM-DD hh:mm:ss');
        if (this.reservationData.residentOf === '' || this.reservationData.residentOf === undefined || this.reservationData.residentOf === null ){
            this.reservationData.residentOf = null;
        }
        if (localStorage.getItem('id') === null || localStorage.getItem('id') === undefined || localStorage.getItem('id') === '') {
            this.reservationData.userId = null;
        } else {
            this.reservationData.userId = localStorage.getItem('id');
        }

        if (this.reservationData.firstName !== undefined && this.reservationData.lastName !== undefined && this.reservationData.email !== undefined && this.reservationData.phoneNumber !== undefined) {
          this.errInGuestDetails = false;
          if(this.selectedSlotPaidStatus==true && this.payByCash==true){
            this.process_type="offline"
          }
          else if(this.selectedSlotPaidStatus==true && this.payByCash==false){
            this.process_type="online";
          }
          else{
            this.process_type=null;
          }
          if (this.parks.Auto_FIFO === 'false' && this.selectedSlotPaidStatus === true) {
                const params = {
                  'phoneNumber': phone,
                  'slotId': this.parks.Slot_Id,
                  'slot': this.parks.Slot,
                  'slotStatus': this.parks.Slot_Status,
                  'slotFIFO': this.parks.Auto_FIFO,
                  'repId': this.parks.Rep_Id,
                  //'repName': this.repName,
                  'reservationDate': this.parks.Date,
                  'cityId': this.parks.City_Id,
                  'userId': this.reservationData.userId,
                  'groundId': this.parks.Ground_Id,
                  'sportId': this.sports.value,
                  'createdAt': createdAt,
                  'firstName': this.reservationData.firstName,
                  'lastName': this.reservationData.lastName,
                  'email': this.reservationData.email,
                  'teamName': this.reservationData.teamName,
                  'residentOf': this.reservationData.residentOf,
                  'stage': environment.logoStage,
                  'authorizationId': this.paypal_authorizationId,
                  'paypalAmount': this.paypal_amount,
                  'orderId': this.orderId,
                  'paymentMode':this.process_type
              };

              console.log('params 11111', params);
              
              // this.msgService.add({ severity: 'success', summary: 'Slot Booking', detail: "Slot request successful!" });
              this.loadingBar = true;
              this.userFormService.submitReservation(params).subscribe((data) => {
                console.log('Response is: ', data);
                this.loadingBar = false
                this.msgService.add({ severity: 'success', summary: 'Slot Booking', detail: "Slot request successful!" });
              });

          } else {
              if(this.selectedSlotPaidStatus==true && this.payByCash==true){
                this.process_type="offline"
              }
              else if(this.selectedSlotPaidStatus==true && this.payByCash==false){
                this.process_type="online";
              }
              else{
                this.process_type=null;
              }
                const params = {
                'phoneNumber': phone,
                'slotId': this.parks.Slot_Id,
                'slot': this.parks.Slot,
                'slotStatus': this.parks.Slot_Status,
                'slotFIFO': this.parks.Auto_FIFO,
                'repId': this.parks.Rep_Id,
                // 'repName': this.repName,
                'reservationDate': this.parks.Date,
                'cityId': this.parks.City_Id,
                'userId': this.reservationData.userId,
                'groundId': this.parks.Ground_Id,
                'sportId': this.sports.value,
                'createdAt': createdAt,
                'firstName': this.reservationData.firstName,
                'lastName': this.reservationData.lastName,
                'email': this.reservationData.email,
                'teamName': this.reservationData.teamName,
                'residentOf': this.reservationData.residentOf,
                'stage': environment.logoStage,
                'paymentMode':this.process_type
            };
            console.log('params 22222', params);
            this.loadingBar = true;
            // this.msgService.add({ severity: 'success', summary: 'Slot Booking', detail: "Slot request successful!" });

            this.userFormService.submitReservation(params).subscribe((data) => {
              console.log('Response is: ', data);
              this.loadingBar = false;
              this.msgService.add({ severity: 'success', summary: 'Slot Booking', detail: "Slot request successful!" });
            });

          }
        } else {
          this.errInGuestDetails = true;
        }
    }


   setPayPal(token, price) {
   	    this.loading_spinner=true;
        console.log('this is token:', token, 'this is amount:', price);
        this.paypalToken=token;
        setTimeout(function(){
           this.paypal_price = price;
        }.bind(this), 0);

        if (!this.addScript) {
          this.addPaypalScript().then(() => {
            setTimeout(function(){
               paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                amount: {
                value: this.paypal_price
                }
                }],
                application_context: {
                shipping_preference: "NO_SHIPPING"
                },
              });
            },
            onApprove: (data, actions) => {
              this.loadingBar = true;
              this.checkout = false;
              return actions.order.capture().then((payment) => {
                this.payPalReceipt = payment;
                if ( payment.status === 'COMPLETED') {
                console.log("payment success::", payment)
                try {
                this.msgService.add({ severity: 'success', summary: 'Your payment has been successful', detail: "Thank you for booking the slot" })
                //this.onFormSubmit();
                //this.router.navigate(['/home'])
                  this.goToMenu(3);
                } catch (e) {
                  console.error('Error:', e)
                }
                } else {
                    this.onPayment();
                    alert('Some error occured:' + payment.state);
                }
              })
            }
               }).render('#paypal-checkout-btn');
            }.bind(this), 0);
            this.paypalLoad = false;
            this.loading_spinner=false;
          })
        }
    }

     addPaypalScript() {
        console.log(this.paypalToken)
        this.addScript = true;
        return new Promise((resolve, reject) => {
          let scripttagElement = document.createElement('script');
          scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'capture';
          scripttagElement.onload = resolve;
          document.body.appendChild(scripttagElement);
        })
    }


  onPayment(){
    
    if(this.parks.Slot_Type === 'Paid' &&this.parks.Auto_FIFO === 'true' && this.parks.Slot_Status === 'Active') {
    	this.payment_option=true;
      	this.auto_fifo_option = false;
      	this.selectedSlotPaidStatus = true;
      	this.setPayPal(this.paypal_secret_key,this.total_amount);
    }
	else if(this.parks.Slot_Type === 'Paid' && this.parks.Auto_FIFO === 'false' && (this.parks.Slot_Status === 'Active' || this.parks.Slot_Status === 'Pending')){
	  this.payment_option=true;
      this.auto_fifo_option = true;
      this.selectedSlotPaidStatus = true;
      this.latersetPayPal(this.paypal_secret_key,this.total_amount);
	}
	else{
    if (this.reservationData.firstName !== undefined && this.reservationData.lastName !== undefined && this.reservationData.email !== undefined && this.reservationData.phoneNumber !== undefined) {
      this.payment_option=true;
    } else {
      this.payment_option=false;
    }
  }

  }

  latersetPayPal(token,price) {
  	    this.loading_spinner=true;
        this.paypalToken=token;
        if (!this.addlaterScript) {
        this.addlaterPaypalScript().then(() => {
        setTimeout(function(){
        paypal.Buttons({
        createOrder: function(data, actions) {
        return actions.order.create({
        purchase_units: [{
        amount: {
        value: price
        }
        }],
        application_context: {
        shipping_preference: "NO_SHIPPING"
        },
        intent: 'authorize'
        });
        },
        onApprove: (data, actions) => {
            this.loadingBar = true;
            this.checkout = false;
            // Authorize the transaction
            return actions.order.authorize().then((authorization) => {
              // Get the authorization id
                if (authorization.status === 'COMPLETED') {
                let authorizationID = authorization.purchase_units[0].payments.authorizations[0].id
                this.paypal_authorizationId = authorizationID;
                this.paypal_amount = authorization.purchase_units[0].payments.authorizations[0].amount.value;
                this.orderId = data.orderID;
                //this.router.navigate(['/home'])
                this.goToMenu(3);
                }
            });
          }
        }).render('#paypal-button-container');
     }.bind(this), 0);
        this.loading_spinner=false;
      })
     }
    }

    addlaterPaypalScript() {
        this.addlaterScript = true;
        return new Promise((resolve, reject) => {
          let scripttagElement = document.createElement('script');
          scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'authorize';
          scripttagElement.onload = resolve;
          document.body.appendChild(scripttagElement);
          console.log(scripttagElement);
        })
    }


  handleChange(param){
    if(param ===1){
    	this.router_link='/home/signup/user';
    }
    else if(param === 2){
        this.router_link='/home/signin';
    }
    else if(param === 3){
     this.router_link='guest';
    }
  }

  goTO(link){
    // this.router.navigate([link,{param:'checkout'}]);
    this.router.navigate([link,{param:'slot-detail/'+this.parks.hash_key}]);
  }

  logout () {
    this.user = 'false';
    this.cityAdmin='false';
    this.superAdmin='false';
    this.userService.logout();
    this.activeIndex=0;
    this.userDetailsTab = true;
    this.paymentDetailsTab=false;
    this.confirmation_page=false;
    this.payment_option=false;
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
  }

}
