import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { CognitoUtil } from '../../service/cognito.service';
import { SlotsService } from '../../service/slots.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Slot } from '../../models/slot';
import * as moment from 'moment';
import 'moment-timezone';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
declare let $;

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
 @Output() errorOut = new EventEmitter<any>();
 @Output() unauthorizedOut = new EventEmitter<any>();

slots_data: any[] = [];
isChangedToSlot: boolean = false;
loadingBar = false ;
isChangedToSchedule: boolean = false;
selectedPark: any;
selectedSlotPark: any;
editSlotForm: FormGroup;
editSlotcheduleForm: FormGroup;
public allSports = [];
selectedGround;
selectedGroundName;
selectedSport;
unauthorized = false;
error = false;
meridian = true;
selectedSlot = new Slot();
showSlotCustomDates: any;
showScheduleCustomDates: any;
ngStartTime = {};
ngEndTime = {};
repeatDays = {};
request_types:any=[];
showScheduleWeekDays: any;
checkValidation: boolean = false;
checkscheValidation: boolean = false;
atLeastOneIsChecked: any;
scheduleDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
openWeekDays = [{label:'Monday',value:'Monday'},{label:'Tuesday',value:'Tuesday'},{label:'Wednesday',value:'Wednesday'},{label:'Thursday',value:'Thursday'},{label:'Friday',value:'Friday'},{label:'Saturday',value:'Saturday'},{label:'Sunday',value:'Sunday'}];
requestedReservations: any[] = [];
payreq_id: any;
payreq_name: any;
payreq_email: any;
payreq_mobilenumber: any;
assign_type: string;
reservationSelect: any[] = [];
customApprove: boolean = false;
from_date_fltr: any;
to_date_fltr: any;
disabled_option: boolean = false;
slot_link: string;
copy_btn_txt: string = "Copy Link";
link_email: string;
email_msg: string;
emailErr: boolean = false;
shareSlot: any;
cancellationReason = '';
perPageTable: number;
currentPageItem:number;
public moment: any = moment;

grounds_list: any[] = [];
sports_list: any[] = [];
status_list: any[] = [];

fromDateFltr: string;
toDateFltr: string;
selectedGroundFltr: string;
selectedSportFltr: string;
selectedStatus: string;
minDate: any;
no_data: boolean = false;
requestType: string;

cust_slots_data: any[] = [];
ref_reqs_data: any[] = [];
infoslot_claims: any[] = [];
pwd_requests: any[] = [];
active_plan: string;
pendingApprovals: any;

parks_count : number;
ground_count : number;
slots_count : number;
show_alert : boolean = false;
modal_header : string;
modal_body : string;
modal_type : string;
request: any;
type: any;
slotPrice : any;
declineReason : string;
reasonOptions = [];
isReasonSelected : boolean = false;
newDeclineReason : string;
isReasonNotEntered : boolean = false;

  constructor(
      private formbuilder: FormBuilder,
      public cognitoUtil: CognitoUtil,
      public slotsService: SlotsService,
      private messageService: MessageService
    ) { 
   
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
	this.editSlotForm = this.formbuilder.group({
	'groundId': new FormControl('', Validators.required),
	'sportId': new FormControl('', Validators.required),
	'date': new FormControl('', Validators.required),
	'startTime': new FormControl('', Validators.required),
	'endTime': new FormControl('', Validators.required),
	'slotCategory': new FormControl('', Validators.required),
	'openDate': new FormControl('', Validators.required),
	'closeDate': new FormControl('', Validators.required),
	'autoFIFO': new FormControl('', Validators.required),
	'paidSlot': new FormControl('', Validators.required),
	'slotPrice': new FormControl('', Validators.required),
	'isPrivateSlot': new FormControl('', Validators.required),
	'payByCash': new FormControl(''),
	'password': new FormControl('', Validators.required),
	'cnf_pass': new FormControl('', Validators.required),
	'team': new FormControl('',Validators.required),
    'resident': new FormControl('',Validators.required),
    'informationSlot': new FormControl('',Validators.required),
    'comments':new FormControl('',Validators.required),
    'bookingURL':new FormControl('', [Validators.required,Validators.pattern(reg)]),
    'nonReserveSlot': new FormControl('',Validators.required),
    'publishInFb': new FormControl('',Validators.required),
    'fcfsSlot' : new FormControl('',Validators.required),
    'nonReserveSchedule': new FormControl('', Validators.required),
    'serviceFee':new FormControl('')
	}); 

    this.editSlotcheduleForm = this.formbuilder.group({
        'groundId': new FormControl('', Validators.required),
        'sportId': new FormControl('', Validators.required),
        'date': new FormControl('', Validators.required),
        'startDate': new FormControl('', Validators.required),
        'endDate': new FormControl('', Validators.required),
        'startTime': new FormControl('', Validators.required),
        'endTime': new FormControl('', Validators.required),
        'scheduleCategory': new FormControl('', Validators.required),
        'autoFIFO': new FormControl('', Validators.required),
        'paidSlot': new FormControl('', Validators.required),
        'slotPrice': new FormControl('', Validators.required),
        'openBefore': new FormControl('', Validators.required),
        'closeBefore': new FormControl('', Validators.required),
        'day': new FormControl('', Validators.required),
        'isPrivateSlot': new FormControl('', Validators.required),
        'password': new FormControl('', Validators.required),
        'cnf_pass': new FormControl('', Validators.required),
        'payByCash': new FormControl(''),
        'team': new FormControl('',Validators.required),
        'resident': new FormControl('',Validators.required),
        'informationSlot': new FormControl('',Validators.required),
        'comments':new FormControl('',Validators.required),
        'bookingURL':new FormControl('', [Validators.required,Validators.pattern(reg)]),
        'nonReserveSlot': new FormControl('',Validators.required),
        'publishInFb': new FormControl('',Validators.required),
        'fcfsSlot': new FormControl('',Validators.required),
        'nonReserveSchedule': new FormControl('', Validators.required),
        'serviceFee':new FormControl('')
    });
  
  }

  ngOnInit() {

    this.minDate = (moment())['_d'];

    this.perPageTable = 10;
    this.currentPageItem = 1;
    this.selectedGroundFltr = "";
    this.selectedSportFltr = "";
    this.selectedStatus = "";
    this.active_plan = localStorage.getItem('planName');

    this.selectedPark = localStorage.getItem('defaultParkName');
    setTimeout(() => {
      $("#from_date").click(() => {
        $("#from_date").datepicker('show');
      })
      $("#to_date").click(() => {
        $("#to_date").datepicker('show');
      })
      $('#from_date').change((e)=> {
        this.from_date_fltr = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        this.from_date_fltr = this.from_date_fltr == "Invalid date" ? undefined : this.from_date_fltr;
        $('.datepicker').hide();
      })
      $('#to_date').change((e)=> {
        this.to_date_fltr = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        this.to_date_fltr = this.to_date_fltr == "Invalid date" ? undefined : this.to_date_fltr;
        $('.datepicker').hide();
      })
    }, 2000);
    this.request_types = [
      {label: "Slot Requests", value: "slot_requests"},
      {label: "Referee Requests", value: "referee_requests"},
      {label: "Custom Slot Requests", value: "custom_slot_requests"},
      {label: "Information Slot Claims", value: "info_slot_claims"},
      {label: "Slot Password Requests", value: "slot_pwd_reqs"}
    ];
    this.requestType = this.request_types[0].label
      this.getSlotRequests();
      this.pendingApprovalsCount();
      this.getParksCount();
      
      if(localStorage.getItem('planName')=='Basic'){
        setTimeout(function(){ 
            $(".switch__toggle").prop('disabled', true);
            $(".info_slot").prop('disabled', false);
            $(".info_schedle").prop('disabled', false);
        }.bind(this),1000);
     }
     this.getDeclinedReasons();
  }

  getParksCount() {
    this.loadingBar = true;
    let city_id = sessionStorage.getItem('cityId');
    this.slotsService.getSummaryCounts(city_id).subscribe((response: any) =>{
      console.log(response.body);
      this.loadingBar = false;
      this.parks_count = response.body.parks;
      this.ground_count = response.body.grounds;
      this.slots_count = response.body.slots;
      if(this.parks_count < 1 || this.ground_count < 1 || this.slots_count < 1){
        this.show_alert = true;
        if(this.parks_count <1 ){
          this.modal_header ="Create New Park Alert";
          this.modal_body = "There are no parks onboarded. Would you like to create one?"
          this.modal_type = "parks"
        }
        else if(this.ground_count <1){
          this.modal_header ="Create New Ground Alert";
          this.modal_body = "There are no grounds onboarded. Would you like to create one?"
          this.modal_type ="grounds"
        }
        else if(this.slots_count <1){
          this.modal_header ="Create New Slot Alert";
          this.modal_body = "There are no slots onboarded. Would you like to create one?"
          this.modal_type = "slots";
        }
      }
    })
  }

  pendingApprovalsCount() {
    let city_id = sessionStorage.getItem('cityId');
      this.slotsService.pendingApprovalsCount(city_id)
        .subscribe((res: any) => {
            console.log(11111, res);
            this.pendingApprovals = res[0].Slots;
        });    
  }

  getRefereeReqs() {
    this.loadingBar = true;
    this.grounds_list = [], this.sports_list = [];
    let city_id = sessionStorage.getItem('cityId');
      this.slotsService.getRefereeReqs(city_id)
        .subscribe((res: any) => {
            this.loadingBar = false;
            if (res.length > 0) {
                this.no_data = false;
                this.ref_reqs_data=res;
                let grounds = _.groupBy(res, "Ground_Name");
                let sports = _.groupBy(res, "Sport_Name");
        
                for (var key in grounds) {
                    if (grounds.hasOwnProperty(key)) {
                        let obj = {
                            Ground_Name: key,
                            Ground_Id: grounds[key][0].Ground_Id
                        }
                        this.grounds_list.push(obj);
                    }
                }
        
                for (var key in sports) {
                    if (sports.hasOwnProperty(key)) {
                        let obj = {
                            Sport_Name: key,
                            Sport_Id: sports[key][0].Sport_Id
                        }
                        this.sports_list.push(obj);
                    }
                }
            } else {
                this.no_data = true;
            }
        });
      
  }

  onAcceptDecReq(request,type){
    // if (this.active_plan === "Free") {
    //   $("#restrictModal").modal('show');
    // } else {
    //   let slot_price = $('#cust_slot_id_'+request.Custom_Slot_Id).val();
    //   this.slotsService.acceptCustomRequest(request,type,environment.logoStage, slot_price).subscribe((res) => {
    //     console.log('accept edit req res', res);
    //     this.messageService.add({ severity: 'success', summary: 'Slot Custom Request', detail: res.body.msg });
    //     this.getCustomSlotReqs();
    //   });
    // }
    this.request = request;
    this.type = type;
    if (this.active_plan === "Free") {
      $("#restrictModal").modal('show');
    } 
    else {
      this.slotPrice = $('#cust_slot_id_'+request.Custom_Slot_Id).val();
      if(type === 'rejected'){
        this.declineReason = "";
        this.isReasonSelected = false;
        $('#declinereqmodal').modal('show')
      }
      else{
        let declinedReason = null;
        this.acceptOrRejectRequest(declinedReason)
      }
    }
 
  }

  getCustomSlotReqs() {
    this.grounds_list = [], this.sports_list = [], this.status_list = [];
    this.loadingBar=true;
      let city_id = sessionStorage.getItem('cityId');
      this.slotsService.getCustomSlotReqs(city_id)
        .subscribe((res: any) => {
            console.log('res', res);
            this.loadingBar=false;
            if (res.length > 0) {
                this.no_data = false;
                this.cust_slots_data=res;
                let grounds = _.groupBy(res, "Ground_Name");
                let sports = _.groupBy(res, "Sport_Name");
                let status_arr = _.groupBy(res, "Appproved_Status");
        
                for (var key in grounds) {
                    if (grounds.hasOwnProperty(key)) {
                        let obj = {
                            Ground_Name: key,
                            Ground_Id: grounds[key][0].Ground_Id
                        }
                        this.grounds_list.push(obj);
                    }
                }
        
                for (var key in sports) {
                    if (sports.hasOwnProperty(key)) {
                        let obj = {
                            Sport_Name: key,
                            Sport_Id: sports[key][0].Sport_Id
                        }
                        this.sports_list.push(obj);
                    }
                }
        
                for (var key in status_arr) {
                    this.status_list.push(key);
                }
            } else {
                this.no_data = true;
            }
        });
      
  }

  changeReqType() {
      this.fromDateFltr = null, this.toDateFltr = null;
    if (this.requestType == "Slot Requests") {
        this.getSlotRequests();
    } else if (this.requestType == "Custom Slot Requests") {
        this.getCustomSlotReqs();
    } else if (this.requestType == "Referee Requests") {
        this.getRefereeReqs();
    } else if (this.requestType == "Information Slot Claims") {
        this.getInfoSlotClaims();
    } else if (this.requestType == "Slot Password Requests") {
        this.getSlotPwdReqs();
    }
  }



  onPwdRequest(type,request){
    this.loadingBar = true;
    console.log(type,request.RS_Pwd_Id,type);
    this.slotsService.acceptdeclineRequest(request.RS_Pwd_Id,type).subscribe((res) => {
     this.loadingBar = false;
     console.log("res",res);
     this.messageService.add({ severity: 'success', summary: 'Request', detail: res.body.msg});
     this.getSlotPwdReqs();
    });
  }
  getSlotPwdReqs() {
      let city_id = localStorage.getItem("cityId");
    this.loadingBar=true;
    this.slotsService.getPasswordRequests(city_id).subscribe((res: any) => {
      console.log("res",res);
      this.pwd_requests = res;
      this.loadingBar=false;
    });
  }

  getInfoSlotClaims() {0
      this.loadingBar = true;
      let city_id = localStorage.getItem("cityId");
      this.slotsService.getInfoSlotClaims(city_id)
        .subscribe((info_slot_claims: any) => {
            this.loadingBar = false;
           console.log('info slot claims', info_slot_claims); 
           if (info_slot_claims.length > 0) {
               this.infoslot_claims = info_slot_claims;
           } else {
               this.no_data = true;
           }
        });
  }

  onAcceptDecInfoReq(request, type) {
    this.loadingBar = true;
    let req_type = type == 'accept' ? 'Approved': 'Rejected'
    this.slotsService.acceptDeclineClaim(request.Claim_Request_Id, req_type)
      .subscribe((res: any) => {
        this.loadingBar = false;
        console.log('accept decline claim res', res);
        this.messageService.add({ severity: 'success', summary: 'Information Slot Claim', detail: res.body.msg });
        this.getInfoSlotClaims();
      });
    
  }

  filterReservations() {
      console.log("in filter")
    this.loadingBar = true;
    let params = {
        from_date: (this.fromDateFltr != undefined || this.fromDateFltr !=null) ? moment(this.fromDateFltr).format("YYYY-MM-DD"): undefined,
        to_date: (this.toDateFltr != undefined || this.toDateFltr != null) ? moment(this.toDateFltr).format("YYYY-MM-DD"): undefined,
        ground_id: this.selectedGroundFltr,
        sport_id: this.selectedSportFltr,
        status: this.selectedStatus,
        city_id: sessionStorage.getItem('cityId'),
        authorizationToken: sessionStorage.getItem('authorizationToken')
    }, type;

    if (this.requestType == "Slot Requests") {
        type = "slots";
    } else if (this.requestType == "Custom Slot Requests") {
        type = "slots/custom";
    } else if (this.requestType == "Referee Requests") {
        type = "refereepickslotrequests";
    }

    this.slotsService.summaryFilter(params, type)
    .subscribe((res: any) => {
        this.loadingBar = false;
        if (res.body.length > 0) {
            if (this.requestType == "Slot Requests") {
                this.slots_data = res.body;
            } else if (this.requestType == "Custom Slot Requests") {
                this.cust_slots_data = res.body;
            } else if (this.requestType == "Referee Requests") {
                this.ref_reqs_data = res.body;
            }
            this.no_data = false;
        } else {
            this.no_data = true;
        }
        console.log('filter res', res);
    });
    
  }

  getSlotRequests(){
    this.grounds_list = [], this.sports_list = [], this.status_list = [];
    this.loadingBar=true;
    this.slotsService.getSlotRequests(sessionStorage.getItem('cityId')).subscribe(response => {
        this.loadingBar=false;
        if (response.length > 0) {
            this.no_data = false;
            this.slots_data=response;
            let grounds = _.groupBy(response, "Ground_Name");
            let sports = _.groupBy(response, "Sport_Name");
            let status_arr = _.groupBy(response, "Slot_Status");
    
            for (var key in grounds) {
                if (grounds.hasOwnProperty(key)) {
                    let obj = {
                        Ground_Name: key,
                        Ground_Id: grounds[key][0].Ground_Id
                    }
                    this.grounds_list.push(obj);
                }
            }
    
            for (var key in sports) {
                if (sports.hasOwnProperty(key)) {
                    let obj = {
                        Sport_Name: key,
                        Sport_Id: sports[key][0].Sport_Id
                    }
                    this.sports_list.push(obj);
                }
            }
    
            for (var key in status_arr) {
                this.status_list.push(key);
            }
        } else {
            this.no_data = true;
        }
    })
  }


  onInformationSlot(slot){
    if(slot==false){
        this.selectedSlot['team'] = false;
        this.selectedSlot['resident'] = false;
        this.selectedSlot['autoFIFO']=false;
        this.selectedSlot['paidSlot']=false;
        this.selectedSlot['fcfsSlot']=false;
        $(".switch__toggle").prop('disabled', true);
        $(".info_slot").prop('disabled', false);
    }
    else if(slot==true){
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        $(".switch__toggle").prop('disabled', false);
        $(".info_slot").prop('disabled', false);
        if(localStorage.getItem('planName')=='Basic'){
            this.selectedSlot['autoFIFO'] = true;
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            $(".switch__toggle").prop('disabled', true);
            $(".info_slot").prop('disabled', false);
        }
    }
}

onNonReserveSlot(slot){
    console.log("Non Resv",slot)
    if(slot){
        this.selectedSlot['team'] = false;
        this.selectedSlot['resident'] = false;
        this.selectedSlot['autoFIFO']=true;
        this.selectedSlot['informationSlot']=false;
        this.selectedSlot['fcfsSlot'] = false;
        // $("#home .switch__toggle").prop('disabled', true);
        // $("#home .nonresv_slot").prop('disabled', false);
        // $("#home .auto_fifo").prop('disabled', false);
    }
    else if(!slot){
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        this.selectedSlot['autoFIFO']=false;
        // $("#home .switch__toggle").prop('disabled', false);
    }
}

onNonReserveSchedule(slot) {
    console.log("Non Resv", slot)
    if (slot) {
        this.selectedSlot['team'] = false;
        this.selectedSlot['resident'] = false;
        this.selectedSlot['autoFIFO'] = true;
        this.selectedSlot['informationSlot'] = false;
        this.selectedSlot['fcfsSlot'] = false;
    }
    else if (!slot) {
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        this.selectedSlot['autoFIFO'] = false;
    }
}

onfcfsSlot(slot) {
    if(slot){
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        // this.selectedSlot['autoFIFO']=true;
        // this.selectedSlot['informationSlot']=false;
        // this.selectedSlot['nonReserveSlot']=false;
        // this.editSlotForm.get('slotCategory').disable();
    }
    else if(!slot){
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['informationSlot'] = false;
            this.selectedSlot['nonReserveSlot'] = false;
        // $("#home .switch__toggle").prop('disabled', false);
    }
}

onInformationSchedule(schedule){
    if(schedule==false){
        this.selectedSlot['team'] = false;
        this.selectedSlot['resident'] = false;
        this.selectedSlot['autoFIFO']=false;
        $(".switch__toggle").prop('disabled', true);
        $(".info_schedle").prop('disabled', false);
    }
    else if(schedule==true){
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        $(".switch__toggle").prop('disabled', false);
        $(".info_schedle").prop('disabled', false);
        if(localStorage.getItem('planName')=='Basic'){
            this.selectedSlot['autoFIFO'] = true;
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            $(".switch__toggle").prop('disabled', true);
            $(".info_slot").prop('disabled', false);
        }
    }
}

onfcfsSchedule(schedule) {
    if (schedule == false) {
        this.selectedSlot['team'] = false;
        this.selectedSlot['resident'] = false;
        this.selectedSlot['autoFIFO'] = false;
        this.selectedSlot['paidSlot'] = false;
        this.selectedSlot['nonReserveSlot'] = false;
        this.selectedSlot['informationSlot'] = false;
    }
    else if (schedule == true) {
        this.selectedSlot['team'] = true;
        this.selectedSlot['resident'] = true;
        if (localStorage.getItem('planName') == 'Basic') {
            this.selectedSlot['autoFIFO'] = true;
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
        }
    }
}

cancelSlotOptions(params){
  this.selectedSlotDetails(params);
}

  cancelSlot(paramsIn) {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    // document.getElementById('close-button').click();
    // document.getElementById('close-button4').click();
    // document.getElementById('close-button3').click();
    console.log('Cancel this slot', paramsIn);
    const params = {
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'slotId': paramsIn.slotId,
        'cityId': sessionStorage.getItem('cityId'),
        'repId': localStorage.getItem('repId'),
        'cancellationReason': this.cancellationReason
    };
    this.slotsService.cancelSlot(params).subscribe(response => {
        this.cancellationReason = '';
        console.log('The response is', response);
        if (response.status === 200) {
            // this.router.navigate(['/admin']);
            // this.router.navigate(['/admin/slots']);
            this.loadingBar = false;
            this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Cancelled successfully' });
            this.getSlotRequests();
            this.pendingApprovalsCount();
            // console.log("this is the this.dates::::", this.first_day);
            // console.log("this is the this.dates::::", this.last_day);
        } else if (response.status === 201) {
            this.loadingBar = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Slots Update', detail: 'Cancelled successfully. Unable to send notification to reservation requesters!'
            });
            this.getSlotRequests();
            this.pendingApprovalsCount();
        }
    },
        err => { this.errorHandle(err) });
}

    editSlot(dataIn){
      this.selectedSlotDetails(dataIn);
   	  this.getSportsByGround(dataIn.Ground_Id);
         this.selectedSlotPark=dataIn.Park_Name;
         this.selectedGroundName=dataIn.Ground_Name;
         if(this.selectedSlot['paidSlot']=='Information'){
            this.selectedSlot['informationSlot']=true;
            $(".switch__toggle").prop('disabled', true);
            $(".info_slot").prop('disabled', false);
            $(".info_schedle").prop('disabled', false);
        }
        else{
            this.selectedSlot['informationSlot']=false;
            $(".switch__toggle").prop('disabled', false);
            $(".info_slot").prop('disabled', false);
            $(".info_schedle").prop('disabled', false);
        }
        if (this.selectedSlot['paidSlot'] === 'Paid') 
        {
           this.selectedSlot['paidSlot'] = true;
        } 
        else{
            this.selectedSlot['paidSlot'] = false;
            this.selectedSlot['slotPrice'] = 0 
        }
        if (dataIn.Schedule_Id === '' || dataIn.Schedule_Id === undefined || dataIn.Schedule_Id === null) {
            document.getElementById('edit-slot').click();
        } else {
            document.getElementById('edit-slot-conformation').click();
            this.isChangedToSchedule = false;
            this.isChangedToSlot = false;
        }    
    }

     onSelectionSlot() {
        this.isChangedToSlot = true;
        this.isChangedToSchedule = false;
    }

   onSelectionSchedule(dataIn, type) {
    this.isChangedToSlot = false;
    this.isChangedToSchedule = true;
    if (type === 'edit') {
        this.loadingBar = true;
        const params = {
            'scheduleId': dataIn.scheduleId,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        this.slotsService.getScheduleDetails(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.setScheduleOpenDates(response.body[0].Schedule_Category);
                this.loadingBar = true;
                if (response.body[0].Schedule_Type === 'Paid') {
                    this.selectedSlot['scheduleType'] = true;
                    this.selectedSlot['schedulePrice'] = response.body[0].Price;
                } else {this.selectedSlot['scheduleType'] = false;
                this.selectedSlot['schedulePrice'] = '0'; }
                this.selectedSlot['startDate'] = new Date(new Date(response.body[0].Start_Date).toISOString());
                let d = new Date(this.selectedSlot['startDate']);
                console.log('this is the selected start before format:', this.selectedSlot['startDate']);
                d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
                this.selectedSlot['startDate'] = d;
                console.log('this is the selected start after format:', this.selectedSlot['startDate']);
                this.selectedSlot['endDate'] = new Date(new Date(response.body[0].End_Date).toISOString());
                let e = new Date(this.selectedSlot['endDate']);
                console.log('this is the selected start before format:', this.selectedSlot['endDate']);
                e.setMinutes(e.getMinutes() + e.getTimezoneOffset());
                this.selectedSlot['endDate'] = e;
                console.log('this is the selected start after format:', this.selectedSlot['endDate']);
                this.selectedSlot['scheduleCategory'] = response.body[0].Schedule_Category;
                this.selectedSlot['openBefore'] = response.body[0].Open_Before;
                this.selectedSlot['closeBefore'] = response.body[0].Close_Before;
                this.selectedSlot['slot'] = response.body[0].Slot;
                this.selectedSlot['startTime'] = (this.selectedSlot['slot'].split(' - '))[0];
                this.selectedSlot['endTime'] = (this.selectedSlot['slot'].split(' - '))[1];

                // open by week
                this.selectedSlot['weekStartDay'] = response.body[0].Week_StartDay;
                this.selectedSlot['openWeekBefore'] = response.body[0].Open_Week_Before;
                // open by week

                let startHour, endHour;
                console.log('this is the 24 hour format time:', this.selectedSlot['startTime']);
                startHour = (moment(this.selectedSlot['startTime'], ["h:mm A"]).format('HH:mm')).split(':')[0];
   
                endHour = (moment(this.selectedSlot['endTime'], ["h:mm A"]).format('HH:mm')).split(':')[0]
                this.ngStartTime = {
                    hour: startHour,
                    minute: ((this.selectedSlot['startTime']).split(':')[1]).split(" ")[0]
                };

                this.ngEndTime = {
                    hour: endHour,
                    minute: ((this.selectedSlot['endTime']).split(':')[1]).split(" ")[0]
                };
                let days = JSON.parse(response.body[0].Days);
                this.repeatDays['MON'] = days.Mon;
                this.repeatDays['TUE'] = days.Tue;
                this.repeatDays['WED'] = days.Wed;
                this.repeatDays['THU'] = days.Thu;
                this.repeatDays['FRI'] = days.Fri;
                this.repeatDays['SAT'] = days.Sat;
                this.repeatDays['SUN'] = days.Sun;
                this.loadingBar = false;
                this.isChangedToSchedule = true;
                this.isChangedToSlot = false;
            }
        },
            err => { this.errorHandle(err) });
    }
}


   selectedSlotDetails(event){
       console.log("!!!!!!!!!",event)
        this.setSlotOpenDates(event.Slot_Category);
        this.selectedSlot['openDate'] = new Date((moment(event.Open_Date).add(1, 'days')).format('llll'));
        this.selectedSlot['closeDate'] = new Date((moment(event.Close_Date).add(1, 'days')).format('llll'));
        this.selectedSlot['slotCategory'] = event.Slot_Category;
        this.selectedSlot['slotId'] = event.id;
        this.selectedSlot['sportId'] = event.Sport_Id;
        this.selectedSlot['oldSportId'] = event.Sport_Id;
        this.selectedSlot['sport'] = event.title;
        this.selectedSlot['groundId'] = event.Ground_Id;
        this.selectedSlot['date'] = event.Date;
        this.selectedSlot['oldDate'] = event.Date;
        this.selectedSlot['startDate'] = new Date(new Date(moment(event.Date).toISOString()).setHours(0));
        this.selectedSlot['endDate'] = new Date(event.end);
        this.selectedSlot['slot'] = event.Slot;
        this.selectedSlot['oldSlot'] = event.Slot;
        this.selectedSlot['slotStatus'] = event.Slot_Status;
        this.selectedSlot['scheduleId'] = event.Schedule_Id;
        this.selectedSlot['repId'] = event.Rep_Id;
        this.selectedSlot['parkId'] = event.Park_Id;
        this.selectedSlot['repName'] = event.Rep_Name;
        this.selectedSlot['startTime'] = (this.selectedSlot['slot'].split(' - '))[0];
        this.selectedSlot['autoFIFO'] = JSON.parse(event.Auto_FIFO);
        this.selectedSlot['paidSlot'] = event.Slot_Type;
        this.selectedSlot['slotPrice'] = event.Price;
        this.selectedSlot['hash_key'] = event.hash_key;
        this.selectedSlot['endTime'] = (this.selectedSlot['slot'].split(' - '))[1];
        this.selectedSlot['isPrivate'] = event.isPrivate == 'true' ? true : false;
        this.selectedSlot['password'] = event.password == 'null' ? '': event.password;
        this.selectedSlot['cnf_pass'] = "";
        this.selectedSlot['payByCash'] = event.Pay_By_Cash === 'true' ? true : false;
        this.selectedSlot['team'] = JSON.parse(event.Ask_Team_Name);
        this.selectedSlot['resident'] = JSON.parse(event.Ask_Resident_Of);
        this.selectedSlot['comments'] = event.Comments;
        this.selectedSlot['bookingURL'] =event.BookingUrl;

        let startHour, endHour;
        console.log('this is the 24 hour format time:', this.selectedSlot['startTime']);
        startHour = (moment(this.selectedSlot['startTime'], ["h:mm A"]).format('HH:mm')).split(':')[0];
        endHour = (moment(this.selectedSlot['endTime'], ["h:mm A"]).format('HH:mm')).split(':')[0]
        this.ngStartTime = {
            hour: startHour,
            minute: ((this.selectedSlot['startTime']).split(':')[1]).split(" ")[0]
        };

        this.ngEndTime = {
            hour: endHour,
            minute: ((this.selectedSlot['endTime']).split(':')[1]).split(" ")[0]
        };
        this.repeatDays['MON'] = false;
        this.repeatDays['TUE'] = false;
        this.repeatDays['WED'] = false;
        this.repeatDays['THU'] = false;
        this.repeatDays['FRI'] = false;
        this.repeatDays['SAT'] = false;
        this.repeatDays['SUN'] = false;
        console.log('this is the selected selectedSlot', this.selectedSlot);

   }

    setSlotOpenDates(params) {
        if (params !== 'custom') {
            this.showSlotCustomDates = false;
        } else {
            this.showSlotCustomDates = true;
        }
    }

    setScheduleOpenDates(params) {
        if (params !== 'custom') {
            if (params === 'default') {
               this.showScheduleWeekDays = true;
               this.showScheduleCustomDates = false;
            } else if (params === 'always') {
              this.showScheduleCustomDates = false;
              this.showScheduleWeekDays = false;
            }
        } else {
            this.showScheduleCustomDates = true;
            this.showScheduleWeekDays = false;
        }
    }

    confirmEdit(dataIn) {
        this.cognitoUtil.refresh();
        if (this.isChangedToSchedule === true) {
            console.log('scheduleId is', this.selectedSlot);
            document.getElementById('edit-schedule').click();
        } else if (this.isChangedToSlot === true) {
            console.log('slot details', dataIn)
            const params = { 'slotId': dataIn.slotId, };
            document.getElementById('edit-slot').click();
        }
        $("#radio1").prop("checked", false);
        $("#radio2").prop('checked', false);
    }

	getSportsByGround(ground) {
		this.selectedGround=ground;
		this.loadingBar = true;
		this.cognitoUtil.refresh();
		this.allSports = [];
		console.log('ground id to get sports', this.selectedGround);
		this.selectedSport = '';
		const params = {
		    'groundId': this.selectedGround,
		    'cityId': sessionStorage.getItem('cityId'),
		    'authorizationToken': sessionStorage.getItem('authorizationToken')
		};
		this.slotsService.getSports(params).subscribe(response => {
		    console.log('The response is', response);
		    if (response.status === 200) {
		        this.allSports = [];
		        if (response.body.length === 0) {
		            this.messageService.add({ severity: 'warn', summary: 'Grounds Update', detail: 'No sports in selected ground' });
		            this.loadingBar = false;
		        } else {
		            response.body.forEach((o) => {
		                console.log(o);
		                const temp = {};
		                temp['value'] = o.Sport_Id;
		                temp['label'] = o.Sport_Name;
		                this.allSports.push(temp);
		            });
		            this.loadingBar = false;
		            console.log('sportList data is: ', this.allSports);
		        }
		    }
		},
		    err => { this.errorHandle(err) });
	}

    // slot update
    updateSlot(dataIn, startTime, endTime) {
        this.cognitoUtil.refresh();
         if ((dataIn.sportId === undefined || this.editSlotForm.controls['sportId'].status==='INVALID')||(dataIn.date === undefined || this.editSlotForm.controls['date'].status==='INVALID')||(dataIn.startTime === undefined || this.editSlotForm.controls['startTime'].status==='INVALID')||(dataIn.endTime === undefined || this.editSlotForm.controls['endTime'].status==='INVALID')||(dataIn.slotCategory=='custom'&&(dataIn.openDate === undefined || this.editSlotForm.controls['openDate'].status==='INVALID'||dataIn.closeDate === undefined || this.editSlotForm.controls['closeDate'].status==='INVALID'))||(dataIn.slotCategory === undefined || this.editSlotForm.controls['slotCategory'].status==='INVALID')||(dataIn.paidSlot == true && (dataIn.slotPrice==undefined || this.editSlotForm.controls['slotPrice'].status==='INVALID')) || (dataIn.isPrivate == true && ((dataIn.password==undefined || this.editSlotForm.controls['password'].status==='INVALID') && dataIn.isPrivate == true) || (this.editSlotForm.controls['password'].value !== this.editSlotForm.controls['cnf_pass'].value) && dataIn.isPrivate == true))
          {
           this.checkValidation = true;
          }
          else{
                this.loadingBar = true;
                this.checkValidation = false;
                document.getElementById('close-button2').click();
                let slot = moment(startTime['hour'] + ':' + startTime['minute'], ['HH:mm']).format("hh:mm A") + ' - ' + moment(endTime['hour'] + ':' + endTime['minute'], ['HH:mm']).format("hh:mm A");
                let sDate = moment(dataIn.startDate).format('YYYY-MM-DD');
                let oDate = moment(dataIn.oldDate).format('YYYY-MM-DD');
                let openDate = moment(dataIn.openDate).format('YYYY-MM-DD');
                let closeDate = moment(dataIn.closeDate).format('YYYY-MM-DD');
​
                
                if(dataIn.informationSlot==true){
                   dataIn.paidSlot = 'Information';
                }
                else{
                if (dataIn.paidSlot === false || dataIn.paidSlot === undefined || dataIn.paidSlot === null ||
                dataIn.slotPrice === false || dataIn.slotPrice === undefined || dataIn.slotPrice === null) {
                    dataIn.paidSlot = 'Free';
                    dataIn.slotPrice = '0';
                } else {
                   dataIn.paidSlot = 'Paid';
                }
                }
                const params = {
                'slotId': dataIn.slotId,
                'newSportId': dataIn._sportId,
                'oldSportId': dataIn.oldSportId,
                'groundId': dataIn._groundId,
                'repId': dataIn.repId,
                'newTime': slot,
                'oldTime': dataIn.oldSlot,
                'newDate': sDate,
                'oldDate': oDate,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'slotCategory': dataIn.slotCategory,
                'openDate': openDate,
                'closeDate': closeDate,
                'autoFIFO': dataIn.autoFIFO,
                'slotPrice': dataIn.slotPrice,
                'paidSlot': dataIn.paidSlot,
                'isPrivate': dataIn.isPrivate==false?"":dataIn.isPrivate,
                'password': dataIn.isPrivate ? dataIn.password : "",
                'payByCash': dataIn.payByCash === null ? false : dataIn.payByCash,
                'Ask_Team_Name':dataIn.team,
                'Ask_Resident_Of':dataIn.resident,
                'Comments':dataIn.comments,
                'BookingUrl':dataIn.bookingURL,
                'Service_Fee':dataIn.serviceFee,
                'nonResvSlot': dataIn.nonReserveSlot
                };
                console.log('update slot data', params);
                this.slotsService.editSlot(params).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    this.loadingBar = false;
                    this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Slot updated successfully' });
                    this.getSlotRequests();
                } else if (response.status === 201) {
                    this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Unable to update slot, overlap with slot: ' + response.body.overlapSlot[0].Slot });
                    this.loadingBar = false;
                    this.getSlotRequests();
                }
                },
                err => { this.errorHandle(err) });
     }
    }

    updateSchedule(data, startTime, endTime) {
    console.log(this.editSlotcheduleForm.controls['day'].status)
     var atLeastOneIsChecked = $('input[name="day"]:checked').length > 0;
     this.atLeastOneIsChecked=atLeastOneIsChecked
     console.log(atLeastOneIsChecked)
     this.cognitoUtil.refresh();      
      if ((data.sportId === undefined || this.editSlotcheduleForm.controls['sportId'].status==='INVALID')||(data.startDate === undefined || this.editSlotcheduleForm.controls['startDate'].status==='INVALID')||(data.endDate === undefined || this.editSlotcheduleForm.controls['endDate'].status==='INVALID')||(data.startTime === undefined || this.editSlotcheduleForm.controls['startTime'].status==='INVALID')||(data.endTime === undefined || this.editSlotcheduleForm.controls['endTime'].status==='INVALID')|| atLeastOneIsChecked==false|| (data.scheduleCategory=='custom' && (data.openBefore==undefined || data.closeBefore==undefined || this.editSlotcheduleForm.controls['openBefore'].status==='INVALID'|| this.editSlotcheduleForm.controls['closeBefore'].status==='INVALID'))||(data.paidSlot == true && ((data.schedulePrice==undefined)|| this.editSlotcheduleForm.controls['slotPrice'].status==='INVALID')) || (data.isPrivate == true && ((data.password==undefined || this.editSlotcheduleForm.controls['password'].status==='INVALID') && data.isPrivate == true) || (this.editSlotcheduleForm.controls['password'].value !== this.editSlotcheduleForm.controls['cnf_pass'].value) && data.isPrivate == true))
       {
        this.checkscheValidation=true;
       }
       else{       
     this.checkscheValidation=false;
     this.loadingBar = true;
     document.getElementById('close-button9').click();
        let slot = moment(startTime['hour'] + ':' + startTime['minute'], ['HH:mm']).format("hh:mm A") + ' - ' + moment(endTime['hour'] + ':' + endTime['minute'], ['HH:mm']).format("hh:mm A");
        let sDate = moment.utc(data.startDate).format('YYYY-MM-DD');
        let eDate = moment(data.endDate).format('YYYY-MM-DD');
        let daysSelected = { 'Mon': this.repeatDays['MON'], 'Tue': this.repeatDays['TUE'], 'Wed': this.repeatDays['WED'], 'Thu': this.repeatDays['THU'], 'Fri': this.repeatDays['FRI'], 'Sat': this.repeatDays['SAT'], 'Sun': this.repeatDays['SUN'] };
        ​
    if(data.informationSlot==true){
        data.scheduleType = 'Information';
        }
        else{
            if (data.scheduleType === true) {
                data.scheduleType = 'Paid';
            } else {
                data.scheduleType = 'Free';
                data.schedulePrice = '0';
            }
        }
     const params = {
     'scheduleCategory': data.scheduleCategory,
     'openBefore': data.openBefore,
     'closeBefore': data.closeBefore,
     'autoFIFO': data.autoFIFO,
     'scheduleId': data.scheduleId,
     'sportId': data._sportId,
     'groundId': data._groundId,
     'repId': data.repId,
     'slot': slot,
     'startDate': sDate,
     'endDate': eDate,
     'days': JSON.stringify(daysSelected),
     'parkId': data.parkId,
     'cityId': sessionStorage.getItem('cityId'),
     'authorizationToken': sessionStorage.getItem('authorizationToken'),
     'schedulePrice': data.schedulePrice,
     'scheduleType': data.scheduleType,        
     'isPrivate': data.isPrivate,        
     'password': data.isPrivate ? data.password : "",        
     'payByCash': data.payByCash === null ? false : data.payByCash,
     'Ask_Team_Name':data.team,
     'Ask_Resident_Of':data.resident,
     'Comments':data.comments,
     'BookingUrl':data.bookingURL,
     'Service_Fee':data.serviceFee,
     'nonResvSlot': data.nonReserveSlot
    };
     console.log('update schedule data', params)
     this.slotsService.scheduleUpdate(params).subscribe(response => {
     console.log('The response is', response);
     if (response.status === 200) {
         this.loadingBar = false;
         this.messageService.add({ severity: 'success', summary: 'Schedule Update', detail: 'Schedule updated successfully' });
         this.getSlotRequests();
     } else if (response.status === 201) {
         this.messageService.add({ severity: 'warn', summary: 'Schedule Update', detail: 'Unable to update schedule, overlap with slot on: ' + (response.body[0].Date).split('T')[0] });
         this.loadingBar = false;
         this.getSlotRequests();
     } else if (response.status === 202) {
         this.messageService.add({ severity: 'warn', summary: 'Schedule Update', detail: 'Selected days not present in selected date range.' });
         this.loadingBar = false;
         this.getSlotRequests();
     } else if (response.status === 203) {
         this.messageService.add({ severity: 'warn', summary: 'Schedule Update Failed', detail: 'Some slots in this schedule are already reserved, Please process them manually in order to edit schedules.'});
         this.loadingBar = false;
         this.getSlotRequests();
     }
​
     },
    err => { this.errorHandle(err) });
   }
 }

 onDaySelection(day) {
    if (this.repeatDays[day] === false) {
        this.repeatDays[day] = true;
    } else {
        this.repeatDays[day] = false;
    }
 }

closeEdit() {
    this.selectedSlot = new Slot();
    $("#radio1").prop("checked", false);
    $("#radio2").prop('checked', false);
 }


errorHandle(err) {
    if (err.status === 401) {
        this.loadingBar = false;
        this.unauthorized = true;
        this.unauthorizedOut.emit(this.unauthorized)
    } else if (err.status === 400 || err.status === 404 || err.status === 500) {
        this.loadingBar = false;
        this.error = true;
        this.errorOut.emit(this.error)
    } else {
        this.loadingBar = false;
        this.error = true;
        this.errorOut.emit(this.error)
    }
}

assignSlot(slot) {
    console.log(22222, slot);
    this.loadingBar = true;
    this.selectedSlot['date'] = slot.Date;
    this.selectedSlot['startTime'] = (slot.Slot.split(' - '))[0];
    this.selectedSlot['endTime'] = (slot.Slot.split(' - '))[1];
    let data = {
      slotId: slot.Slot_Id,
      cityId: slot.City_Id,
      'authorizationToken': sessionStorage.getItem('authorizationToken')
    }
    this.slotsService.getReservationRequests(data)
    .subscribe((res: any) => {
      this.requestedReservations = res.body;
      this.loadingBar = false;
      this.customApprove = false;
      $("#assignPop").modal('show');
    });
}
assignReservation(type) {
    this.assign_type = type;
    document.getElementById('assign-slot-popup').click();
    document.getElementById('assign-reservation').click();
}
selectedReservation(paramsIn) {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    const params = {
      'authorizationToken': sessionStorage.getItem('authorizationToken'),
      'slotId': paramsIn[0].Slot_Id,
      'cityId': sessionStorage.getItem('cityId'),
      'repId': localStorage.getItem('repId'),
      'repName': localStorage.getItem('repName'),
      'reservationId': this.reservationSelect[0].Reservation_Id,
    };
  this.slotsService.assignSlot(params, this.assign_type).subscribe(response => {
  this.loadingBar = false;
  // this.reloadSlots();
    if (response.status === 200) {
        this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservations are processed successfully' });
        this.getSlotRequests();
        this.pendingApprovalsCount();
    } else if (response.status === 202) {
        this.payreq_id=response.body.reservationId;
        this.payreq_name=response.body.firstName+' '+response.body.lastName;
        this.payreq_email=response.body.email;
        this.payreq_mobilenumber=response.body.phoneNumber;
        setTimeout(function(){
        document.getElementById('payment_assign-fifo').click();
        }.bind(this), 0);
        this.getSlotRequests();
        this.pendingApprovalsCount();
    }
     else if (response.status === 201) {
        this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
        this.getSlotRequests();
        this.pendingApprovalsCount();
    }
},
    err => { this.errorHandle(err) });
}

onAssignSelect(reservation) {
    this.reservationSelect = [];
    if (this.reservationSelect.length === 0) {
        this.reservationSelect.push(reservation)
    }
    console.log('reservation data', this.reservationSelect)
}

deleteSlotOptions(params) {
    this.isChangedToSchedule = false;
    this.isChangedToSlot = false;
    $("#radio3").prop("checked", false);
    $("#radio4").prop('checked', false);
    this.selectedSlotDetails(params);
    if (params.Schedule_Id === '' || params.Schedule_Id === undefined || params.Schedule_Id === null) {
        console.log('Delete Object - No Schedule', params);
        document.getElementById('delete-slot').click();
    } else {
        console.log('Delete Object', params);
        document.getElementById('delete-slot-conformation').click();
    }
}

confirmDelete(params) {
    if (this.isChangedToSchedule === true) {
        console.log('schedule')
        document.getElementById('delete-schedule').click();
    } else if (this.isChangedToSlot === true) {
        console.log('slot')
        document.getElementById('delete-slot').click();
    }
}

deleteSchedule(params) {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    // document.getElementById('close-button').click();
    // document.getElementById('close-button4').click();
    // document.getElementById('close-button3').click();
    // document.getElementById('close-button1').click();
    console.log('Delete this schedule', params);
    const paramsOut = {
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'scheduleId': params.scheduleId,
        'cityId': sessionStorage.getItem('cityId'),
        'repId': localStorage.getItem('repId'),
    };
    this.slotsService.deleteSlotSchedule(paramsOut).subscribe(response => {
        console.log('The response is', response);
        if (response.status === 200) {
            this.loadingBar = false;
            this.getSlotRequests();
            this.messageService.add({ severity: 'warn', summary: 'Schedules Update', detail: 'Deleted all free slots, Reserved slots must be managed manually' });
            // console.log("this is the this.dates::::", this.first_day);
            // console.log("this is the this.dates::::", this.last_day);
        } else if (response.status === 201) {
            this.loadingBar = false;
            this.getSlotRequests();
            this.messageService.add({ severity: 'warn', summary: 'Schedules Update', detail: 'Deleted successfully! Unable to delete associated slots.' });
        }
    },
        err => { this.errorHandle(err) });
}

deleteSlot(params) {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    console.log('Delete this slot', params);
    const paramsOut = {
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'slotId': params.slotId,
        'cityId': sessionStorage.getItem('cityId'),
        'repId': localStorage.getItem('repId'),
    };
    this.slotsService.deleteSlot(paramsOut).subscribe(response => {
        console.log('The response is', response);
        if (response.status === 200) {
            if (response.body.msg === 'Slot deleted successfully. No associated reservation requests!') {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Deleted successfully' });
                this.getSlotRequests();
            } else if (response.body.msg === 'Slot deleted successfully. Sent notification to all reservation requesters!') {
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'success', summary: 'Slots Update',
                    detail: 'Deleted successfully, sent notifications'
                });
                this.getSlotRequests();
                this.pendingApprovalsCount();
            }
        } else if (response.status === 201) {
            if (response.body.msg === 'Slot deleted successfully. Unable to send notification to reservation requesters!') {
                this.messageService.add({
                    severity: 'warn', summary: 'Slots Update',
                    detail: 'Deleted successfully, error sending notifications'
                });
                this.loadingBar = false;
                this.getSlotRequests();
                this.pendingApprovalsCount();
            }
        }
    },
        err => { this.errorHandle(err) });
}

openShareSlotModal(slot) {
    console.log(121212, slot);
    this.shareSlot = slot;
    $('#shareSlotModal').modal('show');
    this.slot_link = location.origin+"/home/slot-detail/"+slot.hash_key;
}

copyLink() {
    let copyText = document.getElementById("copy_link") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
    $('#copy_link_btn').html('<i style="color: #fff;padding-right: 2px;" class="fa fa-check" aria-hidden="true"></i>Copied');
    $('#copy_link_btn').removeClass('btn-default');
    $('#copy_link_btn').addClass('btn-success');
}

changeBtnTxt() {
    $('#copy_link_btn').html('Copy Link');
    $('#copy_link_btn').addClass('btn-default');
    $('#copy_link_btn').removeClass('btn-success');
}

onChangeEmail() {
    console.log(222222, this.link_email);
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.link_email == "" || this.link_email == undefined) {
        this.emailErr = true;
        this.email_msg = "Email required";
    } else if (!re.test(String(this.link_email).toLowerCase())) {
        this.emailErr = true;
        this.email_msg = "Invalid Email";
    } else {
        this.emailErr = false;
    }
}

sendEmailLink() {
    if (!this.emailErr) {
        let data = {
            email: this.link_email,
            link:"home/slot-detail/"+this.shareSlot.hash_key,
            slot_id:this.shareSlot.Slot_Id,
            city_id: localStorage.getItem('cityId')
        };
        this.slotsService.sendCopyLinkToEmail(data).subscribe((response: any) => {
          console.log("email send::",data);
          $('#shareSlotModal').modal('hide');
          this.messageService.add({ severity: 'success', summary: 'Share Slot', detail: 'Slot details shared successfully' });
        }, err => {
            $('#shareSlotModal').modal('hide');
            this.messageService.add({ severity: 'error', summary: 'Message', detail:  err});
        })
    }
}

// schedule update
cancelEditSchedule() {
    this.loadingBar = false;
}

pageChanged(e) {
    this.currentPageItem = e;
}
acceptOrRejectRequest(declinedReason) {
    this.request.declinedReason = declinedReason;
    this.loadingBar = true;
    this.slotsService.acceptCustomRequest(this.request,this.type,environment.logoStage, this.slotPrice).subscribe((res) => {
      this.loadingBar = false;
      console.log('accept edit req res', res);
      this.messageService.add({ severity: 'success', summary: 'Slot Custom Request', detail: res.body.msg });
      this.getCustomSlotReqs();
      this.pendingApprovalsCount();
    });
  }

  onReasonSelect() {
    if(this.declineReason != undefined || this.declineReason != ""){
      this.isReasonSelected = true;
      let decline_reason = this.declineReason;
      console.log(decline_reason);
      this.oncloseDecline()
      this.acceptOrRejectRequest(decline_reason);
    }
   
  }
  oncloseDecline() {
    this.declineReason = "";
    $('#declinereqmodal').modal('hide')

  }
  onreasonchange() {
    this.isReasonSelected = true;
  }
  getDeclinedReasons() {
    this.loadingBar = true;
    this.slotsService.getDeclineCustomSlotReasons().subscribe((response) => {
      this.loadingBar = false;
      console.log(response);
      this.reasonOptions = response.body
    })
  }
  addNew() {
    this.newDeclineReason = "";
    $('#addReasonModal').modal('show')
  }

  addReason() {
    if(this.newDeclineReason!=""){
      this.isReasonNotEntered = false; 
      let data = {
        "Reason" : this.newDeclineReason,
        "Rep_Id" : localStorage.getItem("repId")
      }
      $('#addReasonModal').modal('hide')
      this.loadingBar = true;
      this.slotsService.addCustomSlotDeclineReason(data).subscribe((response) => {
        console.log(response);
        this.loadingBar = false;
        if(response.status == 200){
        //   $('#addReasonModal').modal('hide')
          this.getDeclinedReasons();
        }
      })
    }
    else{
      this.isReasonNotEntered = true
    }
  }


}
