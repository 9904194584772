import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from './service/user-login.service';
import { CognitoUtil, LoggedInCallback } from './service/cognito.service';
import { SlotsService } from './service/slots.service';
import { NotificationsService } from './service/notifications.service';
import { ParksService } from './service/parks.service'
import * as moment from 'moment';

declare let $;
@Component({
    selector: 'app-admin',
    templateUrl: './secure/landing/admin.html',
    // styleUrls: ['../assets/slotsAssets/css/style.css']
})
// export class SecureComponent implements OnInit, LoggedInCallback {
export class AdminComponent implements OnInit, LoggedInCallback {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    defaultPark: string;
    state: string;
    address: string;
    website: string;
    logoName: string;
    msgs: any;
    selectedPage: any;

    userData: any;
    repInitials: any;
    applicant: any = {};
    activeState;
    super_admin: boolean;
    partner: boolean;
    loadingBar = false;
    repName: string;
    notifCount: number;
    notifications: any[] = [];
    moment: any = moment;
    showCount: boolean = true;
    notificationCount: any;
    unreadCount: number;


    constructor(public router: Router, public userService: UserLoginService,
        public cognitoUtil: CognitoUtil, public slotsservice: SlotsService,
        private notificationnService: NotificationsService,
        private parksService : ParksService) {
        this.parksService.isNewParkAdded.subscribe((res) => {
            console.log("is New Park Added :"+res)
            this.defaultPark = localStorage.getItem("defaultParkName");
        })

        this.userService.isAuthenticated(this);
        this.cityName = sessionStorage.getItem('cityName');
        this.defaultPark = localStorage.getItem("defaultParkName");
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
        console.log("AdminComponent: constructor");


        this.slotsservice.currentMessage.subscribe(res => {
            console.log('Responseeeeee::', res);
            //if(res !=='default message'){
            // localStorage.setItem('defaultParkName',res);
            this.defaultPark = localStorage.getItem("defaultParkName");
            //}
        })
        this.slotsservice.isNotificationClicked.subscribe((response)=>{
            console.log(response);
            if(response){
                this.getNotifications();
            }
        })
       
        // this.notificationnService.subject.subscribe((response) => {
        //     console.log("notify count is" + response)
        //     if (response > 0) {
        //         this.showCount = true;
        //         // this.notifCount = response;
        //         this.getNotifications();

        //     }
        //     // localStorage.setItem("notificationsCount", response)
        // })

    }

    ngOnInit() {
        if (localStorage.superAdmin === 'true' && localStorage.partner !== 'true') {
            this.super_admin = true;
            this.partner = false;
            this.userData = [
                { "value": "0", "label": localStorage.getItem('repName') },
                { "value": "1", "label": "Logout" },]
        }
        else if (localStorage.partner === 'true' && localStorage.superAdmin !== 'true') {
            this.partner = true;
            this.userData = [
                { "value": "0", "label": localStorage.getItem('repName') },
                { "value": "1", "label": "Logout" },]
        }
        else {
            this.partner = false;
            this.super_admin = false;
            this.userData = [
                { "value": "0", "label": localStorage.getItem('repName') },
                { "value": "1", "label": "Billing" },
                { "value": "2", "label": "Switch Park" },
                { "value": "4", "label": "Switch Role" },
                { "value": "5", "label": "Preferences" },
                { "value": "3", "label": "Logout" },]
        }
        this.repInitials = localStorage.getItem('repInitials');
        this.repName = localStorage.getItem('repName');
        this.getNotifications();
        $("#notifications").hide();
    }

    hideCount() {
        this.showCount = false;
        let city_id = localStorage.getItem("cityId");
        this.updateNotifications(city_id);
    }

    getNotifications() {
        if (localStorage.superAdmin == 'true') { return false }

        let city_id = localStorage.getItem("cityId");
        this.slotsservice.getNotifications(city_id)
            .subscribe((res: any) => {
                console.log(1111, res);
                this.notifications = res.notifications;
                this.unreadCount = this.notifications.filter(notification =>
                    notification.Is_Read == null).length;
                console.log(this.unreadCount);
                let unseenNot = res.un_read;
                if(res.un_read == true){
                    this.showCount = true;
                    this.notifCount = this.unreadCount;
                    
                }
            });
    }
    updateNotifications(cityId) {
        this.slotsservice.updateNotifications(cityId).subscribe((response) => {
            console.log("update Notifications response :" + response);
            // sthis.getNotifications();
        })
    }

    updateUnreadNotifications(notifiId) {
        // let city_id = localStorage.getItem("cityId");
        this.slotsservice.updateUnreadNotifications(notifiId).subscribe((response) => {
            console.log("update Notifications response :" + response);
            this.getNotifications();
        })
    }

    updateUrl(event) {
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        console.log(2222222222, isLoggedIn);
        if (!isLoggedIn) {
            // this.router.navigate(['/home/login']);
            this.router.navigate(['/home/signin']);
        } else if (isLoggedIn && (localStorage.getItem('defaultParkId') === null ||
            localStorage.getItem('defaultParkId') === 'undefined') && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('partner') !== 'true') {
            this.router.navigate(['/parkDefaults']);
        } else if (isLoggedIn && localStorage.getItem('superAdmin') === 'true' && localStorage.getItem('partner') !== 'true') {
            this.router.navigate(['/admin/cityRequests']);
        } else if (isLoggedIn && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('defaultParkId') === 'temp' && localStorage.getItem('partner') !== 'true') {
            this.router.navigate(['/admin/parks']);
        }
        else if (localStorage.getItem('partner') == 'true' && isLoggedIn && localStorage.getItem('superAdmin') !== 'true') {
            this.router.navigate(['/admin/partners']);
        }
        else {
            //this.router.navigate(['/admin']); 
        }

    }

    toggleMenu() {
        var menuBox = document.getElementById('menu-box');
        if (menuBox.style.display === "block" || menuBox.style.display === "") {
            menuBox.style.display = "none";
        } else {
            menuBox.style.display = "block";
        }
    }

    gotoPage(params, index, admin_type) {
        console.log("params", params, index, admin_type);
        if (admin_type === false) {
            console.log("admin")
            this.activeState = index;
            if (params === '1') {
                this.router.navigate(['admin/billing', 'subscription']);
            }
            if (params === '3') {
                this.router.navigate(['/admin/logout']);
            }
            if (params === '2') {
                this.cognitoUtil.refresh();
                this.router.navigate(['/parkDefaults']);
            }
            if (params === '5') {
                this.router.navigate(['admin/preferences']);
            }
            if (params === '4') {
                this.loadingBar = true;
                let User_Enabled = localStorage.getItem('User_Enabled');
                let repId = localStorage.getItem('repId');
                const token = sessionStorage.getItem('authorizationToken');
                if (User_Enabled == 'false') {
                    console.log("false");
                    this.userService.getSwitchUser(repId).subscribe(res => {
                        console.log('update user::', res);
                        this.userService.getUserDetails(token).subscribe(data => {
                            console.log('response', data);
                            //  localStorage.clear();
                            localStorage.removeItem('cityAdmin');
                            localStorage.setItem('user', 'true');
                            localStorage.setItem('firstName', data[0]['First_Name']);
                            localStorage.setItem('lastName', data[0]['Last_Name']);
                            localStorage.setItem('userName', data[0]['Username']);
                            localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                            localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                            localStorage.setItem('phoneNumber', data[0]['Phone_Number'] === null ? '' : data[0]['Phone_Number']);
                            localStorage.setItem('email', data[0]['Email']);
                            localStorage.setItem('id', data[0]['User_Id']);
                            sessionStorage.setItem('cityId', data[0]['City_Id']);
                            localStorage.setItem('street', data[0]['Street']);
                            localStorage.setItem('city', data[0]['City']);
                            localStorage.setItem('state', data[0]['State']);
                            localStorage.setItem('zipcode', data[0]['Zip_Code']);
                            localStorage.setItem('sportsPreferences', JSON.stringify(data[0]['Sport_Names']));
                            localStorage.setItem('switchAdmin', 'true');
                            setTimeout(function () {
                                this.router.navigate(['/home']);
                            }.bind(this), 0);
                            this.loadingBar = false;
                        })
                    })
                }
                else {
                    console.log("true");
                    this.userService.getUserDetails(token).subscribe(data => {
                        console.log('response', data);
                        //  localStorage.clear();
                        localStorage.removeItem('cityAdmin');
                        localStorage.setItem('user', 'true');
                        localStorage.setItem('firstName', data[0]['First_Name']);
                        localStorage.setItem('lastName', data[0]['Last_Name']);
                        localStorage.setItem('userName', data[0]['Username']);
                        localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                        localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                        localStorage.setItem('phoneNumber', data[0]['Phone_Number'] === null ? '' : data[0]['Phone_Number']);
                        localStorage.setItem('email', data[0]['Email']);
                        localStorage.setItem('id', data[0]['User_Id']);
                        sessionStorage.setItem('cityId', data[0]['City_Id']);
                        localStorage.setItem('street', data[0]['Street']);
                        localStorage.setItem('city', data[0]['City']);
                        localStorage.setItem('state', data[0]['State']);
                        localStorage.setItem('zipcode', data[0]['Zip_Code']);
                        localStorage.setItem('sportsPreferences', JSON.stringify(data[0]['Sport_Names']));
                        localStorage.setItem('switchAdmin', 'true');
                        setTimeout(function () {
                            this.router.navigate(['/home']);
                        }.bind(this), 0);
                        this.loadingBar = false;
                    })
                }
            }
            if(params === '0') {
                this.router.navigate(['/admin/profile']);
            }
        }
        else {
            console.log("System Admin")
            this.activeState = index;
            if (params === '1') {
                this.router.navigate(['/admin/logout']);
            }
        }
    }

    openNotifications() {
        if (document.getElementById("notifications").style.display == "block") {
            document.getElementById("notifications").style.display = "none"
        }
        else if (document.getElementById("notifications").style.display == "none") {
            document.getElementById("notifications").style.display = "block"
        }
    }

    onNotifyClick(notify) {
        // if (notify.Is_Read == null) {
        //     (document.querySelector('.notify-message-unread ') as HTMLElement).style.backgroundColor = "#d3f8e2"
        // }
        if (notify.Entity == "Slot") {
            this.router.navigate(['/admin/slots']);
        } else if (notify.Entity == "CustomSlot") {
            this.router.navigate(['/admin/custom-slot-requests']);
        } else if (notify.Entity == "ClaimSlot") {
            this.router.navigate(['/admin/infoslot-claim-reqs']);
        }
        else if (notify.Entity == "OnBoardSlot"){
            this.router.navigate(['/admin/onboard-slot-requests'])
        }
        let notificationId = notify.Notification_Id;
        this.updateUnreadNotifications(notificationId);

    }

}


