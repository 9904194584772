import { environment } from './../../../../environments/environment';
import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../../service/user-login.service';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../../service/cognito.service';
import { UserIdleService } from 'angular-user-idle';
import * as jwt_decode from 'jwt-decode';
var popupTools = require('popup-tools');
import { ParksService } from '../../../service/parks.service';
import { HomeService } from '../../../service/home.service';
import { CitiesService } from '../../../service/cities.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { UsersService } from '../../../service/users.service'


declare let $;


@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})
export class SigninComponent implements CognitoCallback, LoggedInCallback, OnInit {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    cityName: string;
    address: string;
    website: string;
    email: string;
    loadingBar = false;
    password: string;
    errorMessage: string;
    mfaStep = false;
    mfaData = { destination: '', callback: null };
    urlType: string;
    forgot_option=false;
    from_url;
    public loading: boolean;
    parksList: any=[];
    tm_invt_id: any;
    selectble_plans: any[] = [];
    basicDetails: any[] = [];
    advancedDetails: any[] = [];
    freePlanDetails: any[] = [];
    payasyouGoDetails: any[] = [];
    premiumDetails: any[] = [];
    tabIndex: number = 0;
    sub_type: any;
    planType: any = null;
    plansSubmitted: boolean = false;
    selectedPlan: any;
    msgs: any;
    passwordType: string = 'password';


    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public userService: UserLoginService,
        private userIdle: UserIdleService,public parksservice: ParksService,private homeService: HomeService,
        private citiesService: CitiesService,
        private messageService: MessageService,
        private usersService : UsersService
    ) {
        this.cityName = sessionStorage.getItem('cityName');
        this.address = sessionStorage.getItem('address');
        this.website = sessionStorage.getItem('website');
        console.log('LoginComponent constructor');
        this.urlType = this.route.snapshot.queryParamMap.get('name');
        console.log('urlType:', this.urlType);
        this.from_url=this.route.snapshot.params.param;
        this.tm_invt_id=this.router.url.split('%3D')[1];
    }

    ngOnInit() {
        if(this.from_url=='checkout' && localStorage.getItem('user')==='true'){
            setTimeout(function(){
            this.router.navigate(['/home']);
            }.bind(this), 0); 
        }

        this.errorMessage = null;
        console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
        this.userService.isAuthenticated(this);
        // Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => console.log(count));

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() =>
            this.router.navigate(['/admin/logout']));
        // alert('Logged out due to inactivity');
        $('body').addClass('sign_main');
        this.getPlans();
    }

	ngOnDestroy() {
	  $('body').removeClass('sign_main');
    }
    
    showHidePass() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

	forgotPassword(){
      this.forgot_option=true;		
	}

    // single sign on auth
    loginWithAuthProvider(authType) {
        var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        if (authType === 'facebook') {
        URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
        } else if (authType === 'google') {
        URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
        } else if (authType === 'amazon') {
        URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
        }
        popupTools.popup(URL, 'My Popup', { width: 400, height: 450 }, ((err) => {
        console.log('window closed::');
        this.loadingBar = true;
        // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
        if (localStorage.authToken) {
        this.cognitoCallback(null, localStorage.authToken);
        }
        }))
    }
    // single sign on auth

    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    stop() {
        this.userIdle.stopTimer();
    }

    stopWatching() {
        this.userIdle.stopWatching();
    }

    startWatching() {
        this.userIdle.startWatching();
    }

    restart() {
        this.userIdle.resetTimer();
    }

    onLogin() {
        this.loading = true;
        if (this.email == null || this.password == null) {
            this.loading = false;
            this.errorMessage = 'All fields are required';
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.email.toLowerCase(), this.password, this);
        console.log('this is the this value:', this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.loading = false;
            this.loadingBar = false;
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
            // this.loading=false;
            if (this.errorMessage === 'User is not confirmed.') {
                console.log('redirecting');
                this.router.navigate(['/home/newaccountActivation', this.email]);
            } else if (this.errorMessage === 'User needs to set password.') {
                console.log('redirecting to set new password');
                this.router.navigate(['/home/newPassword']);
            } else if (this.errorMessage === "User is disabled.") {

                this.userService.getUserGroup(this.email).subscribe(response => {
                 console.log('response',response[0].GroupName)
                  if(response[0].GroupName=="Users"){
                    this.userService.getUserActivate(this.email).subscribe(res => {
                        this.loading=false;
                        console.log("response",res);
                        if(res.body.activate==true){
                        //   this.messageService.add({ 
                        //   severity: 'success', 
                        //   summary: 'Activation', 
                        //   detail: 'Welcome back to Fields Manager, Please login again' 
                        //   });
                        $("#welcomeBackModal").modal('show');
                        }
                        else{
                          this.messageService.add({ 
                          severity: 'error', 
                          summary: 'Activation', 
                          detail: 'please contact technical support at 214-206-8976' 
                          });
                        }
                      })
                  }
                  else if(response[0].GroupName.split('_')[1]=="CityAdmin"){
                    $("#subscriptionModal").modal("show");
                    this.loading=false;
                  }
                })
            }
        } else { // success
            let tokenInfo;
            if(result.idToken==undefined){
                tokenInfo = this.getDecodedAccessToken(result);
                console.log('this is the id token ###################', tokenInfo);
                sessionStorage.setItem('authorizationToken', result);
            }
            else{
                tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
            }
            let params = sessionStorage.getItem('authorizationToken');
            console.log('This is the token info', tokenInfo);
            if ((tokenInfo['cognito:username'] === 'systemadmin') || (tokenInfo['cognito:username'] === 'rohit') || (tokenInfo['cognito:groups'] && tokenInfo['cognito:groups'].length > 0 && tokenInfo['cognito:groups'][0] == 'SystemAdmin')) {
                console.log('this is the console for system admin');
                localStorage.setItem('superAdmin', 'true');
                localStorage.setItem('repName', 'System Admin');
                localStorage.setItem('repInitials', 'S'+'A');
                this.loading = false;
                this.router.navigate(['/admin/cityRequests']);
            } else 
            if(tokenInfo['cognito:username'].includes('Facebook')){
                console.log("facebook Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('userName', data[0]['Username']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    localStorage.setItem('street', data[0]['Street']);
                    localStorage.setItem('city', data[0]['City']);
                    localStorage.setItem('state', data[0]['State']);
                    localStorage.setItem('zipcode', data[0]['Zip_Code']);
                    localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));
                    
                    if(this.tm_invt_id != undefined || this.tm_invt_id != null || data[0]['User_Id']!=null){
                        this.homeService.joinTeamCnfm(this.tm_invt_id,data[0]['User_Id']).subscribe((res: any) => {
                        console.log('API::', res);
                        })
                    }
                    if(this.from_url==='checkout'){
                        this.router.navigate(['/home/bookings'])
                       }
                       // else{
                       //     if ( this.urlType === 'custom') {
                       //       this.router.navigate(['home/'])
                       //     } else {
                       //      // this.router.navigate(['/home']);
                       //      this.checknewUser(data[0]['User_Id'])
                       //     } 
                       // }
                       this.route.queryParams.subscribe(params => {
                           if (params['sportId'] && params['parkId'] && params['groundId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId'],
                                   parkId: params['parkId'],
                                   groundId: params['groundId']
                               }});
                           } else if (params['sportId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId']
                               }});
                           } else if (params['parkId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   parkId: params['parkId']
                               }});
                           }
                           else if (params['customSlot']) {
                               this.router.navigate(['/home/custom-slots']);
                           }
                           else if(params['fromLanding']){   
                             if(params['slotType'] === 'reservable'){
                               this.router.navigate(['/home/slot-detail/'+ params['slotKey']]);
                             } 
                             else{
                                this.router.navigate(['/home'],{queryParams: {
                                    slot_id : params['slotKey'],
                                }});
                             }
                            
                           }
                           else{
                               if ( this.urlType === 'custom') {
                                 this.router.navigate(['home/'])
                               } else {
                                  // this.router.navigate(['/home']);
                                  this.checknewUser(data[0]['User_Id'])
                               } 
                            }

                       })
                });
                   
            } else if(tokenInfo['cognito:username'].includes('Google')){
                console.log("google Data::",tokenInfo);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('userName', data[0]['Username']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    localStorage.setItem('street', data[0]['Street']);
                    localStorage.setItem('city', data[0]['City']);
                    localStorage.setItem('state', data[0]['State']);
                    localStorage.setItem('zipcode', data[0]['Zip_Code']);
                    localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));

                    if(this.tm_invt_id != undefined || this.tm_invt_id != null || data[0]['User_Id']!=null){
                        this.homeService.joinTeamCnfm(this.tm_invt_id,data[0]['User_Id']).subscribe((res: any) => {
                        console.log('API::', res);
                        })
                    }
                    if(this.from_url==='checkout'){
                        this.router.navigate(['/home/bookings'])
                    }
                    // else{
                    //        if ( this.urlType === 'custom') {
                    //          this.router.navigate(['home/'])
                    //        } else {
                    //         //  this.router.navigate(['/home']);
                    //         this.checknewUser(data[0]['User_Id'])
                    //        } 
                    // }
                    this.route.queryParams.subscribe(params => {
                        if (params['sportId'] && params['parkId'] && params['groundId'] && params['customSlot']) {
                            this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId'],
                                   parkId: params['parkId'],
                                   groundId: params['groundId']
                               }});
                        } else if (params['sportId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId']
                               }});
                        } else if (params['parkId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   parkId: params['parkId']
                               }});
                        }
                        else if (params['customSlot']) {
                               this.router.navigate(['/home/custom-slots']);
                        }
                        else if(params['fromLanding']){    
                            if(params['slotType'] === 'reservable'){
                               this.router.navigate(['/home/slot-detail/'+ params['slotKey']]);
                             } 
                             else{
                                this.router.navigate(['/home'],{queryParams: {
                                    slot_id : params['slotKey'],
                                }});
                             }
                        }
                        else{
                          if ( this.urlType === 'custom') {
                             this.router.navigate(['home/'])
                          } else {
                           // this.router.navigate(['/home']);
                          this.checknewUser(data[0]['User_Id'])
                          } 
                        }
                    })

                });
                   
            } else if(tokenInfo['cognito:username'].includes('LoginWithAmazon')){
                console.log("amazon Data::",tokenInfo);
                console.log("amazon Data::",tokenInfo['name']);
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('userName', data[0]['Username']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    localStorage.setItem('street', data[0]['Street']);
                    localStorage.setItem('city', data[0]['City']);
                    localStorage.setItem('state', data[0]['State']);
                    localStorage.setItem('zipcode', data[0]['Zip_Code']);
                    localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));


                    if(this.tm_invt_id != undefined || this.tm_invt_id != null || data[0]['User_Id']!=null){
                        this.homeService.joinTeamCnfm(this.tm_invt_id, data[0]['User_Id']).subscribe((res: any) => {
                        console.log('API::', res);
                        })
                    }
                    if(this.from_url==='checkout'){
                        this.router.navigate(['/home/bookings'])
                    }
                    // else{
                    //    if ( this.urlType === 'custom') {
                    //      this.router.navigate(['home/'])
                    //    } else {
                    //     // this.router.navigate(['/home']);
                    //     this.checknewUser(data[0]['User_Id'])
                    //    } 
                    // }
                    this.route.queryParams.subscribe(params => {
                        if (params['sportId'] && params['parkId'] && params['groundId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId'],
                                   parkId: params['parkId'],
                                   groundId: params['groundId']
                               }});
                        } else if (params['sportId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   sportId: params['sportId']
                               }});
                        } else if (params['parkId'] && params['customSlot']) {
                               this.router.navigate(['/home/custom-slots'], {queryParams: {
                                   parkId: params['parkId']
                               }});
                        }
                        else if (params['customSlot']) {
                               this.router.navigate(['/home/custom-slots']);
                        }
                        else if(params['fromLanding']){    
                            if(params['slotType'] === 'reservable'){
                               this.router.navigate(['/home/slot-detail/'+ params['slotKey']]);
                             } 
                             else{
                                this.router.navigate(['/home'],{queryParams: {
                                    slot_id : params['slotKey'],
                                }});
                             }
                        }
                        else{
                          if ( this.urlType === 'custom') {
                             this.router.navigate(['home/'])
                          } else {
                           // this.router.navigate(['/home']);
                          this.checknewUser(data[0]['User_Id'])
                          } 
                        }
                    })

                });
                   
            } else if (tokenInfo['cognito:groups'][0].includes('User')) {
                console.log('************ User is logged in ***********');
                localStorage.setItem('user', 'true');
                this.userService.getUserDetails(params).subscribe(data => {
                    this.loading = false;
                    console.log('this is the output from get user details:', data);
                    localStorage.setItem('firstName', data[0]['First_Name']);
                    localStorage.setItem('lastName', data[0]['Last_Name']);
                    localStorage.setItem('userName', data[0]['Username']);
                    localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                    localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email']);
                    localStorage.setItem('id', data[0]['User_Id']);
                    sessionStorage.setItem('cityId', data[0]['City_Id']);
                    localStorage.setItem('street', data[0]['Street']);
                    localStorage.setItem('city', data[0]['City']);
                    localStorage.setItem('state', data[0]['State']);
                    localStorage.setItem('zipcode', data[0]['Zip_Code']);
                    localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));


                    if(this.from_url==='checkout' && localStorage.getItem('invitationId') === null){
                        this.router.navigate(['/home/bookings'])
                    } 
                    else if(this.from_url!=undefined&&this.from_url.includes("slot-detail")&& localStorage.getItem('invitationId') === null){
                        this.router.navigate(['home/'+this.from_url])
                    }
                    else if(localStorage.getItem('invitationId') !== null) {
                        this.router.navigate(['/home/dashboard'], {queryParams: {invitation_id: localStorage.getItem('invitationId')}})
                    }
                    // else{
                    //      if ( this.urlType === 'custom') {
                    //       this.router.navigate(['home/'])
                    //      } else {
                    //         this.checknewUser(data[0]['User_Id'])
                    //         //  this.router.navigate(['/home']);
                    //      } 
                    // }
                    this.route.queryParams.subscribe(params => {
                        if (params['sportId'] && params['parkId'] && params['groundId'] && params['customSlot']) {
                            this.router.navigate(['/home/custom-slots'], {queryParams: {
                                sportId: params['sportId'],
                                parkId: params['parkId'],
                                groundId: params['groundId']
                            }});
                        } else if (params['sportId'] && params['customSlot']) {
                            this.router.navigate(['/home/custom-slots'], {queryParams: {
                                sportId: params['sportId']
                            }});
                        } else if (params['parkId'] && params['customSlot']) {
                            this.router.navigate(['/home/custom-slots'], {queryParams: {
                                parkId: params['parkId']
                            }});
                        }
                        else if (params['customSlot']) {
                            this.router.navigate(['/home/custom-slots']);
                        }
                        else if(params['fromLanding']){    
                            if(params['slotType'] === 'reservable'){
                               this.router.navigate(['/home/slot-detail/'+ params['slotKey']]);
                             } 
                             else{
                                this.router.navigate(['/home'],{queryParams: {
                                    slot_id : params['slotKey'],
                                }});
                             }
                        }
                         else{
                         if ( this.urlType === 'custom') {
                          this.router.navigate(['home/'])
                         } else {
                            this.checknewUser(data[0]['User_Id'])
                            //  this.router.navigate(['/home']);
                         } 
                    }
                    })
                    
                });
            } 
            else if(tokenInfo['cognito:groups'][0].includes('Partners')){
                console.log('************ Partner is logged in ***********');
                localStorage.setItem('partner', 'true');
                this.userService.getPartnerDetails(params).subscribe(data => {
                    this.loading = false;
                    console.log('this is the output from get partner details:', data);
                    localStorage.setItem('partnerId',data[0].Partner_Id);
                    localStorage.setItem('partner_email',data[0].Email)
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0).toUpperCase() + data[0]['Last_Name'].charAt(0).toUpperCase());
                    this.router.navigate(['/admin/partners']);
                });
            }
            else {
                console.log("cognito:group",tokenInfo['cognito:groups'][0].split('_')[1])
                localStorage.setItem('cognitoGroup',tokenInfo['cognito:groups'][0].split('_')[1])
                localStorage.setItem('cityAdmin', 'true');
                sessionStorage.setItem('cityId', tokenInfo['cognito:groups'][0].split('_')[0]);
                localStorage.setItem('cityId',tokenInfo['cognito:groups'][0].split('_')[0]);
                const params1 = {
                    'cityId': sessionStorage.getItem('cityId'),
                    'authorizationToken': sessionStorage.getItem('authorizationToken')
                };
                this.userService.getAdminDetails(params).subscribe(data => {
                    console.log('this is the output from get admin details:', data);
                    localStorage.setItem('repId', data[0]['Rep_Id']);
                    localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                    localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                    localStorage.setItem('paymentStatus',  data[0]['Payment_Status'])
                    localStorage.setItem('planId',  data[0]['Plan_Id'])
                    localStorage.setItem('planName',  data[0]['Plan_Name'])
                    localStorage.setItem('subscriptionId',  data[0]['Subscription_Id'])
                    localStorage.setItem('planPrice',  data[0]['Price'])
                    localStorage.setItem('planUpdatedAt',  data[0]['Plan_Updated_At'])
                    localStorage.setItem('sixMthPlan',  data[0]['active_six_months_subscription'])
                    localStorage.setItem('User_Enabled',  data[0]['User_Enabled'])
                    localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                    localStorage.setItem('email', data[0]['Email'])

                    this.parksservice.getParks(params1).subscribe(response => {
                    this.loading = false;
                    var noofParks;
                    //for no parks admin
                    if(localStorage.getItem('repName')=='madhu m'){
                        noofParks=0;
                    }
                    else{
                        noofParks=response.body.length;
                    }
                    //for no parks admin
                    
                    if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                        this.router.navigate(['/parkDefaults']);
                    }
                    else if(noofParks==0){
                        localStorage.setItem('defaultParkId', 'temp');
                        this.router.navigate(['/admin/parks']);
                    }
                    else {
                        localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                        localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                        this.router.navigate(['/admin']);
                    }
                })
                });
            }
        }
    }
    getDecodedAccessToken(token: string): any {
        console.log('inside the function');
        try {
            console.log('token info in function: ', jwt_decode(token));
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    async getParks() {
        const params = {
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        
        this.parksservice.getParks(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.parksList = response.body;
            }
        }) 
    }
   
    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
        this.mfaData.callback = (code: string) => {
            if (code == null || code.length === 0) {
                this.errorMessage = 'Code is required';
                return;
            }
            this.errorMessage = null;
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn && localStorage.getItem('superAdmin') === 'true') {
            console.log('this is the super admin isLoggedIn');
            this.router.navigate(['/admin/cityRequests']);
        } else if (isLoggedIn && (localStorage.getItem('defaultParkId') === null || localStorage.getItem('defaultParkId') === 'undefined') && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('user') !== 'true') {
            console.log('this is the park defaults isLoggedIn');
            this.router.navigate(['/parkDefaults']);
        } else if (isLoggedIn && localStorage.getItem('defaultParkId') !== null
            && localStorage.getItem('defaultParkId') !== 'undefined') {
            console.log('this is the no park defaults isLoggedIn');
            this.router.navigate(['/admin']);
        }else if (isLoggedIn && localStorage.getItem('user') === 'true') {
            this.router.navigate(['/home']);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false;   // necessary to prevent href navigation
    }

    goToSignUp(type) {
        $('#joinModal').modal('hide');
        this.router.navigate(['/home/signup',type])
    }

    getPlans() {
        this.citiesService.getPlans().subscribe(response => {
            let plans = response.body;
         for ( let i = 0; i < plans.length; i++) {
            this.selectble_plans.push({label: plans[i].Plan_Name, value: plans[i]});
            if (plans[i].Plan_Name === 'Basic') {
              this.basicDetails.push({
                  'label':plans[i].Plan_Name,
                  'value':plans[i],
                  'noofParks': plans[i].Parks_Limit,
                  'groundsperParks': plans[i].Grounds_Per_Park,
                  'sportsperGround': plans[i].Sports_Per_Ground,
                  'price': plans[i].Price
                })
            } else if (plans[i].Plan_Name === 'Advanced') {
                this.advancedDetails.push(
                    {
                    'label':plans[i].Plan_Name,
                    'value':plans[i],
                    'noofParks': plans[i].Parks_Limit,
                    'groundsperParks': plans[i].Grounds_Per_Park,
                    'sportsperGround': plans[i].Sports_Per_Ground,
                    'price': plans[i].Price})
            } else if(plans[i].Plan_Name === 'Premium'){
              this.premiumDetails.push(
                {
                'label':plans[i].Plan_Name,
                'value':plans[i],
                'noofParks': plans[i].Parks_Limit,
                'groundsperParks': plans[i].Grounds_Per_Park,
                'sportsperGround': plans[i].Sports_Per_Ground,
                'price': plans[i].Price == null ? "contact" : plans[i].Price
            })
            } else if(plans[i].Plan_Name === 'Free'){
                this.freePlanDetails.push(
                  {
                  'label':plans[i].Plan_Name,
                  'value':plans[i],
                  'noofParks': plans[i].Parks_Limit,
                  'groundsperParks': plans[i].Grounds_Per_Park,
                  'sportsperGround': plans[i].Sports_Per_Ground,
                  'price': plans[i].Price})
              }
            else{
              this.payasyouGoDetails.push(
                {
                'label':plans[i].Plan_Name,
                'value':plans[i],
                'noofParks': plans[i].Parks_Limit,
                'groundsperParks': plans[i].Grounds_Per_Park,
                'sportsperGround': plans[i].Sports_Per_Ground,
                'price': plans[i].Price})
            }
          }
        })
      }

      handleChange(e) {
        var index = e.index;
        if(index==0){
        this.tabIndex=0
        }
        else{
        this.tabIndex=1;
        }
      }

     onSubscription() {
          this.plansSubmitted = true;
          if (this.planType != null) {
              let selected_plan = this.tabIndex == 1 ? this.payasyouGoDetails[0].value : this.planType;
              console.log(selected_plan.Plan_Id, this.email);
              this.userService.reactivateUser(selected_plan.Plan_Id, this.email).subscribe((res:any) => {
                console.log("response::",res.status);
                $("#subscriptionModal").modal('hide');
                if(res.status==200){
                    this.messageService.add({ 
                    severity: 'success', 
                    summary: 'Activation', 
                    detail: 'Reactivation successfully, Please login again' 
                    });
                }
                else{
                    this.messageService.add({ 
                    severity: 'error', 
                    summary: 'Activation', 
                    detail: 'please contact technical support at 214-206-8976' 
                    });
                }
              })
          }
      }

      checknewUser(userid){
        this.loadingBar = true;
        this.usersService.getActivityHistory(userid).subscribe((response) => {
            this.loadingBar = false;
            if(response.status === 200){
                if(response.body.history.length > 1){
                   this.router.navigate(['/home'])
                }
                else{
                    this.router.navigate(['/home/welcome']);
                }    
            }
        })
    }

      
}
