import { environment } from './../../../environments/environment';
import { Component, OnInit,Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import {HomeService} from '../../service/home.service';
import {Sport} from '../../models/sport';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import * as moment from 'moment';
import {Moment} from 'moment';
import {trigger, animate, style, group, animateChild, query, stagger, transition, state} from '@angular/animations';
type Orientation = ( "prev" | "next" | "none" );
declare var google;
import { CitiesService } from './../../service/cities.service';
declare let $;
import * as _ from "lodash"
import { FacebookService, LoginResponse, LoginOptions, UIResponse, UIParams, FBVideoComponent } from 'ngx-facebook';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-slots-parks',
  templateUrl: './slots-parks.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition("void => prev", [
          style({transform: 'translateX(0)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(100px)', opacity: 1}))
        ]),
        transition("void => next", [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(-100px)', opacity: 5}))
        ])
      ]
    )
  ],
  styleUrls: ['./slots-parks.component.css']
})
export class SlotsParksComponent implements OnInit, OnChanges {

  @Input('location') updateLocation: boolean;
  @Input('park') selectedPark;


  public sportsList = [];
  public parksList=[];
  loadingBar = false;
  stagger = true;
  dates = [];
  selectedDate;
  weekOfYear;
  currentDate;
  public orientation: Orientation;
  sportsSuggestions:any;
  cityform: FormGroup;
  distanceData:any;
  distanceArray=[];
  searchgPlace;
  nearestParksData;
  citiesInfo = [];
  loadParks=false;
  count=0;
  slots;
  currentDay="Today";
  amenitiesData;
  optionalData;
  amenityList: boolean = false;
  optionalList: boolean = false;
  slotsData=[];
  final:any;
  parkArray=[];
  page;
  loading_spinner=false;
  no_data=false;
  fromDate=moment(new Date()).format('DD-MM-YYYY');
  toDate=moment(new Date()).format('DD-MM-YYYY');
  select_date=moment(new Date()).format('DD-MM-YYYY');
  parkSports: any[];
  showIcons=false;  
  shareId;
  position;
  grndSportId: any;
  slideDirection:any;
  public moment: any = moment;
  user: any;
  nextSlot: any;
  nextAvlSlots: any[] = [];
  availableSlots:any;
  bookedSlots:any;
  userId:any;

  constructor(public router: Router,public homeService: HomeService,private formbuilder: FormBuilder,public citiesservice: CitiesService, private fb: FacebookService) {
    fb.init({
      appId: '2653325178228252',
      version: 'v2.9'
    });
  }

  ngOnInit() {
    this.user = localStorage.getItem('user');
    this.userId=localStorage.getItem('id');
  	this.getSports();
    this.selectedDate = moment().startOf('day').format();
    this._changeDisplayedWeek(0);
    this.placesScript();
    this.cityform = this.formbuilder.group({
      'city': new FormControl('', Validators.required),
    }); 
    this.searchgPlace=localStorage.searchPlace;
    $('#overlay').fadeIn();
    $('.input-daterange').datepicker({})

    $('#from_date1').change((e)=> {
      $('.datepicker').hide();
      this.selectFromDate(e.target.value);
    })
    $('#to_date1').change((e)=> {
      $('.datepicker').hide();
      this.selectToDate(e.target.value);
    })

    $('[data-toggle="popover"]').popover();
    this.getNextSlot(this.currentDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.updateLocation){
        if(changes.updateLocation.currentValue!==false){
          this.searchgPlace = changes.updateLocation.currentValue;
        }
        else{
          this.searchgPlace=localStorage.getItem('searchPlace')
        }
    }
    this.selectDate(moment().startOf('day').format(),'custom');
  }

  nextSlotClick(date) {
    var start_date = moment(date);
    var end_date = moment(this.currentDate);
    var diff_days = start_date.diff(end_date, 'days');
    this.selectDate(date, 'custom');
    this.orientation = "next";
    this.slideDirection = 'left';
    this._changeDisplayedWeek(diff_days); 
  }

  getNextSlot(date) {
    let data = {
      date: date,
      parkId: this.selectedPark ? this.selectedPark.value : "",
      sportId: "",
      groundId: "",
      userId:this.userId
    }
    this.homeService.getNextavlSlot(data)
    .subscribe((res: any) => {
      this.nextAvlSlots = res;
      console.log('next avl slot', res);
      this.nextSlot = res[0];
    });
  }

  customSlotReq(){
    let park_id = this.selectedPark ? this.selectedPark.value : null;
    if(this.user=='true'){
      this.router.navigate(['/home/custom-slots'], { queryParams: {parkId: park_id}});
    }
    else{
      let park_id = this.selectedPark ? this.selectedPark.value : null;
      this.router.navigate(['/home/signin'], { queryParams: {
        parkId: park_id,
        customSlot: true
      }});
    }
  }

  selectFromDate(date) {
    this.fromDate = date;
  }
  selectToDate(date) {
    this.toDate = date;
  }
  applyDateRange() {
    this.selectDate(moment().startOf('day').format(),'range');
  }
  selectDate(date, type) {
    if(type=='custom'){
      this.currentDate=date;
      this.getNextSlot(this.currentDate);
      this.fromDate=moment(date).format('MM-DD-YYYY')
      this.toDate=moment(date).format('MM-DD-YYYY')
    }
    else{
      this.currentDate=false;
    }
    this.loading_spinner=true;    
     this.homeService.getParksByLocationDate(this.fromDate,this.toDate,this.searchgPlace,this.userId).subscribe((data) => {    
      this.loading_spinner=false;
      this.parksList=data;
      
      this.homeService.getSlotsCountByAllParks(this.userId).subscribe((res:any)=>{
        console.log("all parks::",res);
        this.availableSlots=res.active_slots_count;
        this.bookedSlots=res.booked_slots_count;
      })

      if (this.selectedPark) {
        console.log('selectedPark', this.selectedPark);
        this.parksList = this.parksList.filter(item => {
          return item.Park_Id === this.selectedPark.value;
        });
        
        this.homeService.getSlotsCountBySelectedPark(this.selectedPark.value).subscribe((res:any)=>{
          console.log("selected park::",res);
          this.availableSlots=res.active_slots_count;
          this.bookedSlots=res.booked_slots_count;
        })

      }
      this.parkSports = [];
      this.no_data = this.parksList.length === 0 || data.msg=='Location not found! Please allow location!' ?  true : false;
      if(this.parksList.length!==0 && data.msg!=='Location not found! Please allow location!')
      {
      this.parksList.forEach(park => {
       this.homeService.getSlotsByParkId(park.Park_Id,this.fromDate,this.toDate,this.searchgPlace,this.userId).subscribe((slot) => {
        var sports = _.groupBy(slot, (item) => { return item.Sport_Id });

        Object.keys(sports).map(k => {
          var obj = {
            sport: this.getSportData(k), 
            data: sports[k],
            parkId: park.Park_Id
          }
          this.parkSports.push(obj);
        });
        console.log('parkSports', this.parkSports);
       })
     });
    }
    });

    if(date==moment().startOf('day').format()){
      this.currentDay="Today"
    }
    else{
      this.currentDay=moment(date).format('ddd')
    }
  };


  pageChanged(e){
    this.page=e;
  }

  onNext(park,slot){
    this.router.navigate(['/home/slot-detail/'+slot.hash_key]);
  }

  placesScript(){
        var options = {
        types: ['(cities)'],
        componentRestrictions: {
        country: 'us'
        }
        };
        var input = document.getElementById('searchTextField3');
        setTimeout(function(){ 
          $(".pac-container:nth-last-child(2n+1)").prependTo(".ggl_cnt")
          $(".pac-container:nth-last-child(5n+1)").prependTo(".ggl_cnt3")
          $(".pac-container:nth-last-child(5n+2)").prependTo(".ggl_cnt4")
        }.bind(this),3000);
        var autocomplete = new google.maps.places.Autocomplete(input,options);
        google.maps.event.addDomListener(window, 'load');
         google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace();
        console.log("place::",place);
        localStorage.setItem('searchPlace',place.formatted_address)
    }); 
  }

  goTo(place){
    this.loading_spinner=true;
    this.loadParks=true;
    // this.nearestParks();
    this.searchgPlace=localStorage.searchPlace
  }

  // nearestParks(){
  //   var origin=localStorage.searchPlace;
  //   this.distanceArray=[];
  //    this.parksList.forEach((o) => {
  //     return new google.maps.DistanceMatrixService().getDistanceMatrix({'origins': [origin], 'destinations': [o.Park_Address], travelMode: 'DRIVING'}, (data: any) => {
  //         console.log('resultados distancia (mts) -- ', data)
  //         this.loading_spinner=false;
  //         this.no_data=false;
  //         if(data.rows[0].elements[0].status=="OK"){
  //         this.distanceArray.push({'distance':data.rows[0].elements[0].distance.value,'Park_Name':o.Park_Name,'Park_Address':o.Park_Address,'Park_Id':o.Park_Id});
  //         console.log(this.distanceArray);
  //         }
  //         else{
  //         this.distanceArray.push({'distance':0,'Park_Name':o.Park_Name,'Park_Address':o.Park_Address,'Park_Id':o.Park_Id});
  //         console.log(this.distanceArray);
  //         }
  //         this.parksList=this.distanceArray.sort(function(a, b){
  //         return a.distance-b.distance
  //         })
  //         console.log("near by parks::",this.parksList)
          
  //         var dateString=moment(this.currentDate).format('YYYY')+moment(this.currentDate).format('MM')+moment(this.currentDate).format('DD');

  //         var slotsData1 = [];
  //         if (this.parksList.length === 0) {
  //         } else {
  //             this.parksList.forEach((v) => {
  //               console.log(v);
  //               this.homeService.getSlotsbyPark(this.sport,dateString,v.Park_Id).subscribe((res) => {
  //                  res.forEach((m) => {
  //                    slotsData1.push({'Ground_Name':m.Ground_Name,'Slot':m.Slot,'Park_Id':m.Park_Id});
  //                     console.log("Slots Data::",slotsData1)
  //                     this.slotsData=slotsData1;
  //                     var result = _(this.slotsData)
  //                     .groupBy(x => x.Ground_Name)
  //                     .map((value, key) => ({Ground_Name: key, slots: value}))
  //                     .value();
  //                     this.final=result;
                      
  //                     v.checkStatus = {}
  //                     this.final.forEach((n) => {
  //                       this.parkArray=[];
  //                       n.slots.forEach((q) => {
  //                         this.parkArray.push(q.Park_Id)
  //                       })
  //                       var res = this.parkArray.includes(v.Park_Id);
  //                       v.checkStatus[n.Ground_Name] = res
  //                     })
  //                  });
  //               })
  //             });
  //         }
  //     });
  //   })       
  // } 


  search(ground,park,myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].Ground_Name === ground && myArray[i].Park_Id === park) {
          return myArray[i];
      }
    }
  }


  amenitiesList(ground,park){
      var resultObject = this.search(ground, park, this.slotsData);
          this.citiesservice.getAmenityDetails(resultObject.Ground_Id).subscribe((response) => {
            console.log("Amenity Data::",response);
              this.amenitiesData=[];
              let finalAmenities = [];
                let finalOptional = [];
                if (response.body.length > 0) {
                    console.log('response from get amenities', response);
                    response.body.forEach(e => {
                        if (e.Amenity_Type === 'Amenity') {
                            finalAmenities.push(e.Amenity);
                        } else if (e.Amenity_Type === 'Optional') {
                            finalOptional.push(e.Amenity);
                        }
                    });
                    console.log('these are the final amenities', finalAmenities);
                    console.log('these are the optional amenities', finalOptional);
                    this.amenitiesData = finalAmenities;
                    if (this.amenitiesData.length > 0) { this.amenityList = true; }
                    this.optionalData = finalOptional;
                    if (this.optionalData.length > 0) { this.optionalList = true; }
                }
          })
  }

  _changeDisplayedWeek(daysToAdd) {
    var selectedDate = moment(this.selectedDate).add(daysToAdd, 'days');
    this.selectedDate = selectedDate.format();
    this.weekOfYear = selectedDate.format('WW');
    this.dates = this._expandWeek(selectedDate);
  }

  _expandWeek(startDate) {
    var dates = [];
    var dayOfWeek = moment(startDate);
    for (var i = 0; i < 7; i++) {
    dates.push({
    weekDay: dayOfWeek.format('ddd'),
    shortDate: dayOfWeek.format('DD'),
    shortmonth:dayOfWeek.format('MMM'),
    date: dayOfWeek.format()
    });
    dayOfWeek.add(1, 'd');
    }
    return dates;
  }

  nextWeek = function () {
     this.orientation = "next";
     this.slideDirection = 'left';
     this._changeDisplayedWeek(1);
     this.count++;
  };

   prevWeek = function () {
    this.orientation = "prev";
    this.slideDirection = 'right';
    this._changeDisplayedWeek(-1);
    this.count--;
  };
  

  getSports() {
    this.homeService.getSports().subscribe((data) => {
        this.sportsList = [];
        if (data.length === 0) {
        } else {
            data.forEach((o) => {
                console.log(o);
                let obj = new Sport();
                const temp = {};
                temp['Sport_Id'] = o.Sport_Id;
                temp['Sport_Name'] = o.Sport_Name;
                temp['image'] = 'https://content.fieldsmanager.com/'+ environment.logoStage +'/sports-images/'+o.Sport_Id+'.png';
                this.sportsList.push(temp);
            });
        }
    });
  }

  getSportData(sport_id) {
    var sport = this.sportsList.filter(res => {
      return res.Sport_Id === parseInt(sport_id);
    });
    return sport[0];
  }

  getGroundsGrpBy(data) {
    var grounds = _.groupBy(data, (item) => { return item.Ground_Name });
    let grounds_arr = Object.keys(grounds).map(k => {
      return { 
        name: k, 
        data: grounds[k],
      }
    });
    return grounds_arr;
  }

  onShare(slot,index){
    if (this.shareId === slot.Ground_Id && this.position === index && this.grndSportId === slot.Sport_Id) {
      this.showIcons = !this.showIcons;
    } else {
      this.showIcons = true;
    }
    this.shareId = slot.Ground_Id;
    this.position = index;
    this.grndSportId = slot.Sport_Id;
  }
  
  hideIcon(){
    this.showIcons = false;
  }

  share() {
    const options: UIParams = {
      method: 'share',
      href: 'https://www.fieldsmanager.com/'
    };
    this.fb.ui(options)
      .then((res: UIResponse) => {
        console.log('Got the users profile', res);
      })
      .catch(this.handleError);
    this.hideIcon();
  }

  private handleError(error) {
    console.error('Error processing action', error);
  }
 
  
}
