import { environment } from './../../../../environments/environment';
import { Component } from "@angular/core";
import { UserRegistrationService } from "../../../service/user-registration.service";
import { CognitoCallback } from "../../../service/cognito.service";
import { Router } from "@angular/router";
@Component({
    selector: 'app-re-send',
    templateUrl: './re-send.component.html',
    styleUrls: ['./re-send.component.css']

})
export class ReSendComponent implements CognitoCallback {
    somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
    email: string;
    errorMessage: string;
    cityName: any;
    address: any;
    state: any;
    reg_uid: any;

    constructor(public registrationService: UserRegistrationService, public router: Router) {

    }
   
    ngOnInit() {
        $('body').addClass('sign_main');
        this.email = localStorage.getItem("reg_uname");
        this.reg_uid = localStorage.getItem("reg_uid");
    }

     ngOnDestroy() {
        $('body').removeClass('sign_main');
    }

    updateUrl(event) {
        console.log('failed url:', this.somevalue);
        this.somevalue = 'https://content.fieldsmanager.com/System.png';
        console.log('new url:', this.somevalue);
      }
    resendCode() {
        this.registrationService.resendCode(this.email, this);
    }

    cognitoCallback(error: any, result: any) {
        if (error != null) {
            this.errorMessage = "Something went wrong...please try again";
        } else {
            this.router.navigate(['/home/newaccountActivation', this.email, this.reg_uid]);
        }
    }
}