import { Component, OnInit } from '@angular/core';
import { AdminsService} from '../../service/admins.service';
import { UserLoginService } from '../../service/user-login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CognitoUtil, LoggedInCallback } from '../../service/cognito.service';
import { UserRegistrationService } from '../../service/user-registration.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { MessageService } from 'primeng/components/common/messageservice';
declare let $;
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {
  moment: any = moment;
  admin : string;
  profileImage: any;
  loadingBar: boolean = false;
  adminProfile : any;
  unauthorized : boolean= false;
  error : boolean = false;
  profileInfoForm: FormGroup;
  notifyInfoForm : FormGroup;
  secuirityInfoForm:FormGroup;
  oldFname : string;
  oldLname : string;
  firstname : string;
  lastname : string;
  email : string;
  phoneNumber : string;
  oldPhone : string;
  checkPhoneValidation : boolean = false;
  passwordType: string = "password";
  newPassType: string = "password";
  cnfPassType: string = "password";
  pre_tab_type:any = 'personal';
  personaltab : boolean = true;
  sessionstab:boolean = false;
  activeStatus : any;
  sessionHistory = [];

  constructor(private adminService : AdminsService, 
    private formbuilder: FormBuilder,
    private messageService: MessageService,
    public cognitoUtil: CognitoUtil,
    private router: Router,
    public userService: UserLoginService,
    private userRegistrationService :UserRegistrationService) { 


    this.myAccount();
    this.profileInfoForm = this.formbuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
    })
    this.notifyInfoForm = this.formbuilder.group({
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    this.secuirityInfoForm = this.formbuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    })
  }

  ngOnInit() {
    // this.userService.isAuthenticated(this);
    // this.admin = localStorage.getItem('repName');
    this.profileImage="../../../assets/img/prf_icon.png";
    this.getAdminProfile();
    this.getActiveStatus();
    this.getLoginHistory();
  }

  myAccount() {
    setTimeout(function(){
      var readURL = (input)=> {
          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.onload =  (e:any)=> {
                  $('.profile-pic').attr('src', e.target.result);
                  this.profileImage=e.target.result;
                  console.log('upload Image::',this.profileImage);
              }
              reader.readAsDataURL(input.files[0]);
          }
      }
      $(".file-upload").on('change', function(){
          readURL(this);
      });
      $(".upload-button").on('click', function() {
         $(".file-upload").click();
      });
    }.bind(this), 100);
  }
  getAdminProfile() {
    let repID = localStorage.getItem("repId");
    this.loadingBar = true;
    this.adminService.getAdminProfile(repID).subscribe((response) => {
      console.log(response);
      this.loadingBar = false;
      if(response.status === 200){
        this.adminProfile = response.body;
        console.log(this.adminProfile);
        localStorage.setItem('repName', response.body[0].First_Name + ' ' + response.body[0]['Last_Name']);
        this.admin = response.body[0].First_Name;
        this.firstname = response.body[0].First_Name;
        this.oldFname = response.body[0].First_Name;
        this.lastname = response.body[0].Last_Name;
        this.oldLname = response.body[0].Last_Name;
        this.email = response.body[0].Email;
        // this.phoneNumber = "9988596852"
        this.phoneNumber = response.body[0].Phone_Number;
        this.phoneNumber = this.phoneNumber.replace(/^\+[0-9]/, '');
        this.oldPhone = response.body[0].Phone_Number;
        this.oldPhone = this.oldPhone.replace(/^\+[0-9]/, '');
        console.log(this.phoneNumber)        
      }
    },
    err => {
      this.errorHandle(err)
    })
  }
  getActiveStatus() {
    let repID = localStorage.getItem("repId");
    this.loadingBar = true;
    this.adminService.getAdminActiveStatus(repID).subscribe((response) => {
      console.log(response);
      if(response.status == 200){
        this.activeStatus = response.body;
      }
    })
  }

  getLoginHistory(){
    let repID = localStorage.getItem("repId");
    this.loadingBar = true;
    this.adminService.getAdminLoginHistory(repID).subscribe((response) => {
      console.log(response);
      if(response.status == 200){
        this.sessionHistory = response.body.history;
        console.log(this.sessionHistory)
      }
    })
  }

  errorHandle(err) {
    if (err.status === 401) {
      this.loadingBar = false;
      this.unauthorized = true;
      // this.router.navigate(['/admin/unauthorized']);
    } else if (err.status === 400 || err.status === 404 || err.status === 500) {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    } else {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    }
  }

  onProfileEdit() {
    if(this.profileInfoForm.value.firstname!==undefined || this.profileInfoForm.value.lastname!==undefined){
      this.loadingBar = true;
      let params = {
        'first_name': this.profileInfoForm.value.firstname,
        'last_name': this.profileInfoForm.value.lastname,
        'Phone_Number' : this.oldPhone,
        'repID': localStorage.getItem('repId'),
      };
      this.adminService.updateAdminProfile(params).subscribe((response:any) => {
        this.loadingBar = false;
          if(response.status === 200){
            // localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
            this.getAdminProfile();
            // this.router.navigate(['/admin/profile']);
            this.messageService.add({
              severity: 'success',
              summary: 'Update admin profile',
              detail: 'Profile updated successfully!'
            });
          }
      })
     }
  }

  onNotifyEdit(){
    if (this.phoneNumber !== "null" && this.phoneNumber !== "") {
      this.checkPhoneValidation = false;
    }
    else{
      this.checkPhoneValidation = true;
    }
    this.loadingBar=true;
      let params = {
        'first_name': this.oldFname,
        'last_name': this.oldLname,
        'Phone_Number' : this.notifyInfoForm.value.phoneNumber,
        'repID': localStorage.getItem('repId'),
      };
      this.adminService.updateAdminProfile(params).subscribe((res: any) => {
        console.log('update phone res', res);
        this.loadingBar=false;
        this.getAdminProfile();
        this.messageService.add({
          severity: 'success',
          summary: 'Update phone number',
          detail: 'Profile Updated Successfully!'
        });
      });
  }
  showHidePass() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }

  showHideNewPass() {
    this.newPassType = this.newPassType === 'password' ? 'text' : 'password';
  }

  showHideCnfPass() {
    this.cnfPassType = this.cnfPassType === 'password' ? 'text' : 'password';
  }

  onSecuirityEdit(){
    if(this.secuirityInfoForm.value.newpassword==this.secuirityInfoForm.value.confirmpassword){

      if(this.secuirityInfoForm.value.oldpassword!==undefined || this.secuirityInfoForm.value.newpassword!==undefined){
        $("#passworddelConfirmation").modal('show')
      }
      this.checkPhoneValidation=false;
    }
    else{
      this.checkPhoneValidation=true;
    }
  }

  confirmPasswordChange() {
    // this.cognitoUtil.refresh();
    this.loadingBar=true;
    let params = {
        'PreviousPassword': this.secuirityInfoForm.value.oldpassword,
        'ProposedPassword': this.secuirityInfoForm.value.newpassword,
        'AccessToken': sessionStorage.getItem('accessToken'),
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'cityId' : localStorage.getItem('cityId')
    };
  //  this.adminService.changePassword(params).subscribe((response) => {
    this.userRegistrationService.changePassword(params).subscribe(response => {
      this.secuirityInfoForm.reset();
      this.loadingBar=false;
          if (response.body.name) {
             this.messageService.add({
               severity: 'error',
               summary: 'Password Update Failed!',
               detail: response.body.message
             });
          } else {
             this.messageService.add({
              severity: 'success',
              summary: 'Password successfully updated!',
              detail: response.body.message
            });
          }
     },
      err => { this.errorHandle(err)
   })
    
  }

  getPersonalInfo(type) {
    this.pre_tab_type = type;
    if(type === 'personal'){
      this.personaltab = true;
      this.sessionstab=false;
    }
    else {
      this.personaltab = false;
      this.sessionstab=true;
    }

  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log(2222222222, isLoggedIn);
    if (!isLoggedIn) {
        // this.router.navigate(['/home/login']);
        this.router.navigate(['/home/signin']);
    } else if (isLoggedIn && (localStorage.getItem('defaultParkId') === null ||
        localStorage.getItem('defaultParkId') === 'undefined') && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('partner') !== 'true') {
        this.router.navigate(['/parkDefaults']);
    } else if (isLoggedIn && localStorage.getItem('superAdmin') === 'true' && localStorage.getItem('partner') !== 'true') {
        this.router.navigate(['/admin/cityRequests']);
    } else if (isLoggedIn && localStorage.getItem('superAdmin') !== 'true' && localStorage.getItem('defaultParkId') === 'temp' && localStorage.getItem('partner') !== 'true') {
        this.router.navigate(['/admin/parks']);
    }
    else if (localStorage.getItem('partner') == 'true' && isLoggedIn && localStorage.getItem('superAdmin') !== 'true') {
        this.router.navigate(['/admin/partners']);
    }
    else {
        //this.router.navigate(['/admin']); 
    }

  }

  confirmDeleteAccount() {
    $('#cmgSoonModal').modal('show')
  }

  deleteAccount() {
    let cityid = localStorage.getItem('cityId');
    this.loadingBar = true;
    this.userService.getSubscriptionCheck(cityid).subscribe((response) => {
      console.log(response)
      console.log("response::",response[0].Slots)
      this.loadingBar=false;
      if(response[0].Slots==0){
       this.messageService.add({
         severity: 'success',
         summary: 'Delete Account',
         detail: 'We miss you. Your account has been deleted successfully'
       });
       setTimeout(function(){
      //  this.router.navigate(['/home']);
      this.router.navigate(['/admin/logout']);
       localStorage.clear();
       }.bind(this), 2000);
      }
      else{
       $("#futureSlotAvailModal").modal('show');
      }
     },err => {
       console.log('this is the error', err);
       this.messageService.add({ 
         severity: 'error', 
         summary: 'Delete Account', 
         detail: 'please contact technical support at 214-206-8976' 
        });
 
    })
  }

}