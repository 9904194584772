import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserLoginService } from '../../service/user-login.service';
import * as moment from 'moment';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router, ActivatedRoute } from '@angular/router';
declare let $;
declare let paypal: any;

@Component({
  selector: 'app-custom-slots',
  templateUrl: './custom-slots.component.html',
  styleUrls: ['./custom-slots.component.css']
})
export class CustomSlotsComponent implements OnInit {

  customSlotForm: FormGroup;
  user: any;
  moment: any = moment;
  custSlotFormsbmt: boolean = false;
  loadingBar: boolean = false;
  userId: any;
  slot_duration: any;
  total_price: any;
  groundsList: any[] = [];
  sportsListDD: any[] = [];
  parksList: any[] = [];
  searchPlace: any;
  avlblSlots: any[] = [];
  selected_park_id: any;
  selected_ground_id: any;
  selected_sport_id: any;
  selected_date: any;
  meridian = true;
  support_requests: boolean = false;
  sports_details=false;
  show_allSports=false;
  park_slotsDetails=false;
  reportIssue = false;
  feedback=false;
  nextAvlSlots: any[] = [];
  nextSlot: any;
  slot_custom_price: any;
  customSlots=[];
  loading_spinner: boolean = false;
  payment_option: boolean = false;
  paypalToken: any;
  paypal_client_id: string;
  paypal_secret_key: string;
  addScript: boolean = false;
  paypalLoad: boolean = true;
  payPalReceipt: any;
  paypal_price: any;
  timeError : boolean= false;
  phoneNumber : string = '';
  checkValidation : boolean;

  constructor(
    private _fb: FormBuilder,
    private userService: UserLoginService,
    private homeService: HomeService,
    private messageService:MessageService,
    private router: Router,
    private route:ActivatedRoute
  ) {
      
      this.customSlotForm = this._fb.group({
        park: ["", Validators.required],
        ground: ["", Validators.required],
        sport: ["", Validators.required],
        date: [moment(Date.now()).add(3, 'days').format('MM-DD-YYYY'), Validators.required],
        startTime: [null, Validators.required],
        endTime: [null, Validators.required]
      });

      this.route.queryParams.subscribe(params => {
        if (params['parkId']) {
          this.customSlotForm.controls.park.setValue(params['parkId']);
          this.onChangePark();
        }
        if (params['groundId'] && params['parkId']) {
          this.customSlotForm.controls.ground.setValue(params['groundId']);
          this.onChangeGround();
        }
        if (params['sportId'] && params['groundId'] && params['parkId']) {
          this.customSlotForm.controls.sport.setValue(params['sportId']);
          this.changeSport();
        }
      });
   }

  ngOnInit() {
    this.user = localStorage.getItem('user');
    this.userId = localStorage.getItem('id');
    this.searchPlace = localStorage.getItem('searchPlace');
    this.selected_date = moment(new Date()).format('YYYY-MM-DD');

    this.getParks(this.searchPlace);
    let date = new Date()
    date.setDate(date.getDate() + 3);
      setTimeout(function(){     
      $('.input-daterange').datepicker({
        startDate: date
      })
      $('#from_date1').change((e)=> {
        this.selected_date = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        this.getAvilSlots(this.customSlotForm.value);
        $('.datepicker').hide();
      })
      }.bind(this),3000);
      this.getNextSlot(moment(new Date()).format('YYYY-MM-DD'), null, null, null);
      this.getCustomSlots();
      this.getPaypalDetails();
  }

  getPaypalDetails() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      console.log('get paypal res', res);
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
      }
    })
  }

  getCustomSlots(){
    this.homeService.getCustomSlots(this.userId)
      .subscribe((res: any) => {
       this.customSlots=res;
    })
  }
  
  get park() {
    return this.customSlotForm.controls['park'];
  }
  get ground() {
    return this.customSlotForm.controls['ground'];
  }
  get sport() {
    return this.customSlotForm.controls['sport'];
  }
  get date() {
    return this.customSlotForm.controls['date'];
  }
  get startTime() {
    return this.customSlotForm.controls['startTime'];
  }
  get endTime() {
    return this.customSlotForm.controls['endTime'];
  }

  getNextSlot(date, parkId, groundId, sportId) {
    let data = {
      date: date,
      parkId: parkId ? parkId : "",
      sportId: sportId ? sportId : "",
      groundId: groundId ? groundId : "",
      userId:this.userId
    }
    this.homeService.getNextavlSlot(data)
    .subscribe((res: any) => {
      // this.nextAvlSlots = res;
      this.avlblSlots = res;
      console.log('next avl slot', res);
      this.nextSlot = res[0];
    });
  }

  onClickNextSlot() {
    this.avlblSlots = this.nextAvlSlots;
  }

  getParks(location){
    this.parksList = [];
    this.homeService.getParks(location).subscribe((data) => {
      this.parksList = [];
      if (data.length === 0) {
      } else {
        data.forEach((o) => {
          const temp = {};
          temp['value'] = o.Park_Id;
          temp['label'] = o.Park_Name;
          temp['address']=o.Park_Address;
          this.parksList.push(temp);
        }); 
      }
    });
  }


  gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
    this.router.navigate(['/home/support-requests']);
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

  onNext(slot) {
    this.router.navigate(['/home/slot-detail/'+slot.hash_key]);
 }

  onChangePark() {
    this.loadingBar = true;
    this.getAvilSlots(this.customSlotForm.value);
    let park_id = this.customSlotForm.value.park;
    this.homeService.groundsList(park_id)
    .subscribe((res: any) => {
      this.groundsList = res;
      this.loadingBar = false;
    });
  }

  onChangeGround() {
    this.customSlotForm.controls.sport.setValue('');
    this.loadingBar = true;
    this.getAvilSlots(this.customSlotForm.value);
    let park_id = this.customSlotForm.value.park;
    let ground_id = this.customSlotForm.value.ground;
    this.homeService.sportsList(park_id, ground_id)
    .subscribe((res: any) => {
      this.sportsListDD = res;
      this.loadingBar = false;
    });
  }

  changeSport() {
    this.getAvilSlots(this.customSlotForm.value);

    let sport_obj = this.sportsListDD.filter(item => item.Sport_Id == this.customSlotForm.value.sport)[0];

    if (this.customSlotForm.controls.startTime.valid && this.customSlotForm.controls.endTime.valid) {
      var startTime = moment(this.customSlotForm.value.startTime, "hh:mm A");
      var endTime = moment(this.customSlotForm.value.endTime, "hh:mm A");
      var duration = moment.duration(endTime.diff(startTime));
      let hours = Math.round(duration.asHours());
      this.slot_custom_price = this.calSlotDuration(hours,sport_obj.Custom_Price);
    }
  }

  onSelectStartTime() {
    let sport_obj = this.sportsListDD.filter(item => item.Sport_Id == this.customSlotForm.value.sport)[0];
    if(this.customSlotForm.get('endTime').value != null && this.customSlotForm.get('endTime').value < this.customSlotForm.get('startTime').value){
      this.timeError = true;
    }
    else {
      this.timeError = false;
    }
    if (this.customSlotForm.controls.sport.valid && this.customSlotForm.controls.endTime.valid) {
      var startTime = moment(this.customSlotForm.value.startTime, "hh:mm A");
      var endTime = moment(this.customSlotForm.value.endTime, "hh:mm A");
      var duration = moment.duration(endTime.diff(startTime));
      let hours = Math.round(duration.asHours());
      this.slot_custom_price = this.calSlotDuration(hours,sport_obj.Custom_Price);
    }
  }

  onSelectEndTime() {
    let sport_obj = this.sportsListDD.filter(item => item.Sport_Id == this.customSlotForm.value.sport)[0];
    if(this.customSlotForm.get('startTime').value != null && this.customSlotForm.get('endTime').value < this.customSlotForm.get('startTime').value){
      this.timeError = true;
    }
    else {
      this.timeError = false;
    }
    if (this.customSlotForm.controls.startTime.valid && this.customSlotForm.controls.sport.valid) {
      var startTime = moment(this.customSlotForm.value.startTime, "hh:mm A");
      var endTime = moment(this.customSlotForm.value.endTime, "hh:mm A");
      var duration = moment.duration(endTime.diff(startTime));
      let hours = Math.round(duration.asHours());
      this.slot_custom_price = this.calSlotDuration(hours,sport_obj.Custom_Price);
    }
  }

  changeDate() {
    this.getAvilSlots(this.customSlotForm.value);
  }

  getAvilSlots(data) {
    if (data.park !== "" && data.ground !== "" && data.sport !== "" && this.customSlotForm.controls.park.valid && this.customSlotForm.controls.ground.valid && this.customSlotForm.controls.sport.valid) {
      this.loadingBar = true;

      this.homeService.getAvilSlots(data, this.selected_date, this.userId)
      .subscribe((res: any) => {
        this.loadingBar = false;
        this.avlblSlots = res;
        if (res.length === 0) {
          this.getNextSlot(moment(this.selected_date).format('YYYY-MM-DD'), data.park, data.ground, data.sport);
        }
      });
    }
  }

  goToslotDetails(slot) {
    this.router.navigate(['/home/slot-detail/'+slot.hash_key]);
  }

  customSlotSubmit() {
    this.custSlotFormsbmt = true;
    if (this.customSlotForm.valid && this.timeError == false) {
      this.loadingBar = true;
      let startTime = moment(this.customSlotForm.value.startTime).format('hh:mm A');
      let endTime = moment(this.customSlotForm.value.endTime).format('hh:mm A');
      let slot = startTime + ' - ' + endTime;
      var startTime1 = moment(this.customSlotForm.value.startTime, "hh:mm A");
      var endTime1 = moment(this.customSlotForm.value.endTime, "hh:mm A");
      var duration = moment.duration(endTime1.diff(startTime1));
      let hours = Math.round(duration.asHours());

      let sport_obj = this.sportsListDD.filter(item => item.Sport_Id == this.customSlotForm.value.sport)[0];
      let Custom_Price=this.calSlotDuration(hours,sport_obj.Custom_Price);
      let data = {
        Park_Id: this.customSlotForm.value.park,
        Ground_Id: this.customSlotForm.value.ground,
        Sport_Id: this.customSlotForm.value.sport,
        Date: this.selected_date ? this.selected_date : moment(Date.now()).format('YYYY-MM-DD'),
        Slot: slot,
        User_Id: localStorage.getItem('id'),
        Custom_Price:Custom_Price
      }
      console.log("params::",data);
      this.homeService.reqCustomSlot(data)
      .subscribe((res: any) => {
        this.loadingBar = false;
        this.custSlotFormsbmt = false;
        this.customSlotForm.reset();
        this.customSlotForm.patchValue({
          park: "",
          ground: "",
          sport: "",
          date: moment(Date.now()).add(3, 'days').format('MM-DD-YYYY')
        })
        this.getCustomSlots();
        if (res.body.msg === "overlap") {
          this.messageService.add({ severity: 'warning', summary: 'Custom Slot Request', detail: "Slot overlaped!" });
        } else {
          this.messageService.add({ severity: 'success', summary: 'Custom Slot Request', detail: "Slot request successful!" });
        }
      });
   }
  }

  calSlotDuration(hours,price) {
    let cusprice=price!=null?(price.includes("$")?price.split("$")[1]:price):price;
    this.slot_duration = hours;
    this.total_price = this.slot_duration*cusprice;
    return this.total_price
  }

  onPayment(slot) {
    this.payment_option=true;
    this.loading_spinner=true;
      this.paypalToken=this.paypal_secret_key;
      setTimeout(function(){
        this.paypal_price = slot.Price;
      }.bind(this), 0);
     if (!this.addScript) {
       this.addPaypalScript().then(() => {
         setTimeout(()=>{
            paypal.Buttons({
         createOrder: (data, actions) => {
          $("#paymentModal").modal('hide');
           return actions.order.create({
             purchase_units: [{
             amount: {
             value: this.paypal_price
             }
             }],
             application_context: {
             shipping_preference: "NO_SHIPPING"
             },
           });
         },
         onApprove: (data, actions) => {
           return actions.order.capture().then((payment) => {
             console.log("payment success::", payment)
             this.payPalReceipt = payment;
             if ( payment.status === 'COMPLETED') {
              // let payment_data = {
              //   Transaction_Id: payment.id,
              //   Intent: payment.intent,
              //   Status: payment.status,
              //   Payer_Email: payment.payer.email_address,
              //   Payer_Id: payment.payer.payer_id,
              //   Payer_Name: payment.payer.name.given_name+' '+payment.payer.name.surname,
              //   Phone_Number: payment.payer.phone.phone_number.national_number,
              //   Amount: payment.purchase_units[0].payments.captures[0].amount.value,
              //   Payee_Id: payment.purchase_units[0].payee.merchant_id,
              //   Payee_Email: payment.purchase_units[0].payee.email_address,
              //   Capture_Id: payment.purchase_units[0].payments.captures[0].id,
              //   Currency: payment.purchase_units[0].amount.currency_code,
              //   Create_Time: payment.create_time,
              //   Update_Time: payment.update_time,
              // }

              this.homeService.updateCustomSlot(slot.Custom_Slot_Id)
                .subscribe((res: any) => {
                  this.messageService.add({ 
                    severity: 'success', 
                    summary: 'Payment Success', 
                    detail: 'Booking successful!' 
                   });
                   this.getCustomSlots();
                });
                } else {
                 this.messageService.add({ 
                   severity: 'error', 
                   summary: 'Payment Failed', 
                   detail: 'Booking failed!' 
                   });
                }
           })
         }
            }).render('#paypal-checkout-btn');
         }, 0);
         this.paypalLoad = false;
         this.loading_spinner=false;
       })
     }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'capture';
    // scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=USD&commit=true&intent='+'capture';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
 }

  logout () {
    this.user = 'false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  onCustomSlotSubmit() {
    this.custSlotFormsbmt = true;
    if (this.customSlotForm.valid && this.timeError == false) {
      if(!localStorage.getItem('phoneNumber')){
        $("#addMobileModal").modal("show")
      }
      else{
        this.customSlotSubmit()
      }
    }
  }

  addMobileNumber() {
    if (this.phoneNumber !== "null" && this.phoneNumber !== "") {
      this.checkValidation = false;
      $("#addMobileModal").modal("hide")
      this.loadingBar=true;
      let params = {
        'phoneNumber': this.phoneNumber,
        'userId': this.userId,
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'AccessToken': sessionStorage.getItem('accessToken')
      };
      console.log(this.phoneNumber)
      this.homeService.updatePhone(params)
      .subscribe((res: any) => {
        console.log('update phone res', res);
        this.userService.updateCognitoAttributes('phone_number', "+1"+this.phoneNumber);
        localStorage.setItem('phoneNumber',this.phoneNumber);
        this.loadingBar=false;
        this.messageService.add({
          severity: 'success',
          summary: 'Update phone number',
          detail: res.body.msg
        });
        this.customSlotSubmit();
      });
    }
    else{
      this.checkValidation=true;
      this.loadingBar=false;
    }

  }

  checkNUmberisEntered(){
    if(this.phoneNumber.length > 0){
      this.checkValidation = false;
    }
  }

}
