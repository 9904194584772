import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../service/user-login.service'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  user : any;
  constructor( public router: Router,
    private userService : UserLoginService) {
     this.user  = localStorage.getItem('user')
  }

  ngOnInit() {
  }

  onBookSlots() {
    this.router.navigate(['/home'])
  }

  onEditProfile(){
    this.router.navigate(['/home/dashboard'], { queryParams: { account: 'myAccount' } });
  }
  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }
  gotoSupportReqs() {
    this.router.navigate(['/home/support-requests']);
  }

  logout () {
    this.user = 'false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

}
