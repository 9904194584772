import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-edit-slot-requests',
  templateUrl: './edit-slot-requests.component.html',
  styleUrls: ['./edit-slot-requests.component.css']
})
export class EditSlotRequestsComponent implements OnInit {
  current_slot_id: any;
  requests: any[] = [];
  moment: any = moment;
  loadingBar: boolean = false;
  unauthorized: any;
  error: any;
  

  constructor(private slotsService: SlotsService,private messageService:MessageService) { }

  ngOnInit() {
    this.loadingBar = true;
    this.current_slot_id = sessionStorage.getItem('cityId');
    this.getSlotRequests();
  }

  getSlotRequests() {
    this.slotsService.getEditSlotReqs(this.current_slot_id)
    .subscribe((res: any)=> {
      this.loadingBar = false;
      console.log('get edit slot reqs =====', res);
      if (res) {
        this.requests = res;
      }
    });
  }

  onAcceptReq(request) {
    var data = {
      slotId: request.Slot_Id,
      repId: request.Rep_Id,
      cityId: request.City_Id,
      groundId: request.Ground_Id,
      slotCategory: request.Slot_Category,
      autoFIFO: request.Auto_FIFO,
      paidSlot: request.Slot_Type,
      slotPrice: request.Price,
      newSportId: request.Sport_Id,
      oldSportId: request.Sport_Id,
      newTime: request.Req_Slot,
      oldTime: request.Slot,
      newDate: request.Req_Date,
      oldDate: request.Slot_Date,
      openDate:request.Open_Date,
      closeDate:request.Close_Date,
      authorizationToken: sessionStorage.getItem('authorizationToken')
    }

    this.slotsService.acceptEditReq(data)
    .subscribe((res: any) => {
      console.log('accept edit req res', res);
      this.messageService.add({ severity: 'success', summary: 'Slot Confirm Request', detail: res.body.msg });
    });
    
  }

}