import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { forEach } from '@angular/router/src/utils/collection';
import { Slot } from '../models/slot';
import { Ground } from '../models/ground';
import { Reservation } from '../models/reservation';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SlotsService {

    private dataSource = new BehaviorSubject(localStorage.getItem('defaultParkName'));
    currentMessage = this.dataSource.asObservable();
    isNotificationClicked = new BehaviorSubject(false);

    constructor(private http: HttpClient) { }

    sendData(data: any) {
        this.dataSource.next(data)
    }
    
    public getSlots(data): Observable<HttpResponse<any>> {
        console.log('in load grounds service call');
        let params = new HttpParams();
        params = params.append('parkId', data.parkId);
        //params = params.append('cityId', data.cityId);
        params = params.append('groundId', data.groundId);
        params = params.append('endDate', data.endDate);
        params = params.append('startDate', data.startDate);
        if (data.sportId !== '' && data.sportId !== undefined) {
            params = params.append('sportId', data.sportId);
        }
        console.log('Params to get slots in service call:', params);
        //let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // const options = { params: params};
        const url = environment.apiUrl + '/slots/slotsbyparkandground';
        return this.http.get<any>(url, { observe: 'response', params: params});
    }
    public cancelByDate(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('type', data.type);
        params = params.append('id', data.Id);
        params = params.append('date', data.date);
        params = params.append('cityId', data.cityId);
        console.log('Params to cancel', data.type, ':', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots/cancelbydate';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public getReservationDetails(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('cityId', data.cityId);
        console.log('Params to reactivate slot:', params);
        let headers = new HttpHeaders();
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const options = { params: params, headers: headers };
        const url = environment.slotUrl + '/reservations/slot';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public getReservationRequests(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('cityId', data.cityId);
        console.log('Params to reactivate slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const options = { params: params, headers: headers };
        const url = environment.apiUrl + '/reservations/requestsbyslot';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public assignSlotFifo(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('cityId', data.cityId);
        params = params.append('Process_Type', "FIFO");
        console.log('Params to assign slot FIFO:', params);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // let headers = new HttpHeaders();
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.slotUrl + '/reservations/approve';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    // slot assign random
    public assignSlotRandom(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('cityId', data.cityId);
        params = params.append('Process_Type', "Random");
        console.log('Params to assign slot random:', params);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // let headers = new HttpHeaders();
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.slotUrl + '/reservations/approve';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    // slot assign
    public assignCustomSlot(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('cityId', data.cityId);
        params = params.append('reservationId', data.reservationId);
        params = params.append('Process_Type', "Custom");
        console.log('Params to assign slot custom:', params);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // let headers = new HttpHeaders();
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.slotUrl + '/reservations/approve';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public getGrounds(data): Observable<HttpResponse<any>> {
        console.log('thses are the data  from get Grounds by park', data);
        let params = new HttpParams();
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        params = params.append('cityId', data.cityId);
        params = params.append('parkId', data.parkId);
        const options = { observe: 'response', params: params, headers: headers };
        const url = environment.apiUrl + '/grounds/park';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public deleteSlotSchedule(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('scheduleId', data.scheduleId);
        params = params.append('cityId', data.cityId);
        params = params.append('repId', data.repId);
        console.log('Params to delete slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/schedules';
        return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public deleteSlot(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('cityId', data.cityId);
        params = params.append('repId', data.repId);
        console.log('Params to delete slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots';
        return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public deleteReservation(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('slotId', data.slotId);
        params = params.append('reservationId', data.reservationId);
        console.log('Params to delete reservation:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/reservations';
        return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public cancelSlot(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('cityId', data.cityId);
        params = params.append('repId', data.repId);
        params = params.append('cancellationReason', data.cancellationReason);
        console.log('Params to cancel slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots/cancel';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public reactivateSlot(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('cityId', data.cityId);
        console.log('Params to reactivate slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots/activate';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public resetReservations(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('cityId', data.cityId);
        console.log('Params to reactivate slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/reservations/reset';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    public getSlot(data): any {

    }

    public editSchedule(data): any {

    }

    public getSports(data): Observable<HttpResponse<any>> {
        console.log('this is the data from get Sports by ground', data);
        let params = new HttpParams();
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        params = params.append('groundId', data.groundId);
        params = params.append('cityId', data.cityId);
        const options = { params: params, headers: headers };
        const url = environment.apiUrl + '/groundssports/ground';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }

    public getScheduleDetails(data): Observable<HttpResponse<any>> {
        console.log('this is the data from get Sports by ground', data);
        let params = new HttpParams();
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        params = params.append('scheduleId', data.scheduleId);
        params = params.append('cityId', data.cityId);
        const options = { params: params, headers: headers };
        const url = environment.apiUrl + '/schedules/byid';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }

    // update slot collection
    public editSlot(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('cityId', data.cityId);
        params = params.append('newSportId', data.newSportId);
        params = params.append('oldSportId', data.oldSportId);
        params = params.append('groundId', data.groundId);
        params = params.append('newTime', data.newTime);
        params = params.append('oldTime', data.oldTime);
        params = params.append('newDate', data.newDate);
        params = params.append('oldDate', data.oldDate);
        params = params.append('slotCategory', data.slotCategory);
        params = params.append('openDate', data.openDate);
        params = params.append('closeDate', data.closeDate);
        params = params.append('autoFIFO', data.autoFIFO);
        params = params.append('slotPrice', data.slotPrice);
        params = params.append('paidSlot', data.nonResvSlot ? 'non-reservable': data.paidSlot);
        params = params.append('isPrivate', data.isPrivate);
        params = params.append('password', data.password);
        params = params.append('Pay_By_Cash', data.payByCash);
        params = params.append('Ask_Team_Name', data.Ask_Team_Name);
        params = params.append('Ask_Resident_Of', data.Ask_Resident_Of);
        params = params.append('Comments', data.Comments);
        params = params.append('BookingUrl', data.BookingUrl);
        params = params.append('Service_Fee', data.Service_Fee);
        console.log('Params to edit slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    // update schedule
    public scheduleUpdate(data): Observable<HttpResponse<any>> {
        // console.log('schedule update',data)
        let params = new HttpParams();
        params = params.append('scheduleId', data.scheduleId);
        params = params.append('sportId', data.sportId);
        params = params.append('groundId', data.groundId);
        params = params.append('repId', data.repId);
        params = params.append('slot', data.slot);
        params = params.append('startDate', data.startDate);
        params = params.append('endDate', data.endDate);
        params = params.append('days', data.days);
        params = params.append('parkId', data.parkId);
        params = params.append('cityId', data.cityId);
        params = params.append('scheduleCategory', data.scheduleCategory);
        params = params.append('startBefore', data.openBefore);
        params = params.append('endBefore', data.closeBefore);
        params = params.append('autoFIFO', data.autoFIFO);
        params = params.append('schedulePrice', data.schedulePrice),
        params = params.append('scheduleType', data.nonResvSchedule ? 'non-reservable': data.scheduleType),
        params = params.append('daysBeforeWeekStart', data.daysBeforeWeekStart);
        params = params.append('weekStart', data.weekStart);
        params = params.append('isPrivate', data.isPrivate);
        params = params.append('password', data.password);
        params = params.append('Pay_By_Cash', data.payByCash);
        params = params.append('Ask_Team_Name', data.Ask_Team_Name);
        params = params.append('Ask_Resident_Of', data.Ask_Resident_Of);
        params = params.append('Comments', data.Comments);
        params = params.append('BookingUrl', data.BookingUrl);
        params = params.append('Service_Fee', data.Service_Fee);
        console.log('Params to edit slot:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/schedules';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    // slot creation
    public addSlot(data) {
        console.log('new slot create', data);
        let params = new HttpParams();
        params = params.append('repId', data.repId);
        params = params.append('sportId', data.sportId);
        params = params.append('groundId', data.groundId);
        params = params.append('parkId', data.parkId);
        params = params.append('date', data.date);
        params = params.append('slot', data.slot);
        params = params.append('cityId', data.cityId);
        params = params.append('slotCategory', data.slotCategory);
        params = params.append('openDate', data.openDate);
        params = params.append('closeDate', data.closeDate);
        params = params.append('autoFIFO', data.autoFIFO);
        params = params.append('slotPrice', data.slotPrice);
        params = params.append('slotType', data.nonResvSlot ? 'non-reservable': data.paidSlot);
        params = params.append('isPrivate', data.isPrivate);
        params = params.append('password', data.password);
        params = params.append('Pay_By_Cash', data.payByCash);
        params = params.append('Ask_Team_Name', data.Ask_Team_Name);
        params = params.append('Ask_Resident_Of', data.Ask_Resident_Of);
        params = params.append('Comments', data.Comments);
        params = params.append('BookingUrl', data.BookingUrl);
        params = params.append('Service_Fee', data.Service_Fee);
        params = params.append('access_token', data.access_token);
        console.log('add slot ========', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    // schedule creation
    addSchedule(data): Observable<HttpResponse<any>> {
        console.log('new schedule create', data);
        let params = new HttpParams();
        params = params.append('repId', data.repId);
        params = params.append('sportId', data.sportId);
        params = params.append('groundId', data.groundId.Ground_Id);
        params = params.append('parkId', data.parkId);
        params = params.append('startDate', data.startDate);
        params = params.append('endDate', data.endDate);
        params = params.append('slot', data.slot);
        params = params.append('cityId', data.cityId);
        params = params.append('days', data.days);
        params = params.append('scheduleCategory', data.scheduleCategory);
        params = params.append('startBefore', data.startBefore);
        params = params.append('endBefore', data.endBefore);
        params = params.append('autoFIFO', data.autoFIFO);
        params = params.append('schedulePrice', data.schedulePrice);
        params = params.append('scheduleType', data.nonResvSchedule ? 'non-reservable': data.scheduleType);
        params = params.append('daysBeforeWeekStart', data.daysBeforeWeekStart);
        params = params.append('weekStart', data.weekStart);
        params = params.append('isPrivate', data.isPrivate);
        params = params.append('password', data.password);
        params = params.append('Pay_By_Cash', data.payByCash);
        params = params.append('Ask_Team_Name', data.Ask_Team_Name);
        params = params.append('Ask_Resident_Of', data.Ask_Resident_Of);
        params = params.append('Comments', data.Comments);
        params = params.append('BookingUrl', data.BookingUrl);
        params = params.append('Service_Fee', data.Service_Fee);
        params = params.append('access_token', data.access_token);
        console.log('Params to add schedule:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/schedules';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    slotDetails(slot_id): Observable<HttpResponse<any>> {
        const url = environment.apiUrl + '/slots/details?hash_key='+slot_id;
        return this.http.get<any>(url);
    }

    verifySlotPwd(slot_id, password): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slot_id', slot_id);
        params = params.append('password', password);
        const url = environment.apiUrl + '/slots/validate';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    passwordRequest(userId,slotId,cityId): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slot_id', slotId);
        params = params.append('user_id', userId);
        params = params.append('city_id', cityId);
        const url = environment.apiUrl + '/slots/password/request';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    getPasswordRequests(city_id): Observable<HttpResponse<any>> {
        const url = environment.apiUrl + '/slots/password/request?city_id='+city_id;
        return this.http.get<any>(url);
    }

    acceptdeclineRequest(pwdId,type): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('rs_pwd_id', pwdId);
        params = params.append('status', type);
        const url = environment.apiUrl + '/slots/password/request';
        return this.http.put<any>(url, '', { observe: 'response', params: params});
    }

    sendCopyLinkToEmail(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('email', data.email);
        params = params.append('link', data.link);
        params = params.append('city_id',data.city_id);
        params = params.append('slot_id',data.slot_id);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/admin/shareurl';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    getPartnerDetail(city_id): Observable<HttpResponse<any>> {
        const url = environment.apiUrl + '/admin/partnerdetails?city_id='+city_id;
        return this.http.get<any>(url);
    }

    getEditSlotReqs(city_id) {
        const url = environment.apiUrl + '/reservations/edit/requests?city_id='+city_id;
        return this.http.get<any>(url);
    }

    acceptEditReq(data) {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('cityId', data.cityId);
        params = params.append('groundId', data.groundId);
        params = params.append('slotCategory', data.slotCategory);
        params = params.append('autoFIFO', data.autoFIFO);
        params = params.append('paidSlot', data.paidSlot);
        params = params.append('slotPrice', data.slotPrice);
        params = params.append('newSportId', data.newSportId);
        params = params.append('oldSportId', data.oldSportId);
        params = params.append('newTime', data.newTime);
        params = params.append('oldTime', data.oldTime);
        params = params.append('newDate', data.newDate);
        params = params.append('oldDate', data.oldDate);
        params = params.append('openDate', data.openDate);
        params = params.append('closeDate', data.closeDate);
        
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots';
        return this.http.put<any>(url, '', { observe: 'response', params: params,headers:headers});
    }

    getCustomSlotRequests(city_id) {
        const url = environment.apiUrl + '/slots/customslots?City_Id='+city_id;
        return this.http.get<any>(url);
    }

    acceptCustomRequest(data,type,stage, slot_price) {
        let params = new HttpParams();
        params = params.append('Custom_Slot_Id', data.Custom_Slot_Id);
        params = params.append('Sport_Id', data.Sport_Id);
        params = params.append('City_Id', data.City_Id);
        params = params.append('Ground_Id', data.Ground_Id);
        params = params.append('Park_Id', data.Park_Id);
        params = params.append('Date', data.Date);
        params = params.append('firstName', data.First_Name);
        params = params.append('lastName', data.Last_Name);
        params = params.append('Slot', data.Slot);
        params = params.append('User_Id', data.User_Id);
        params = params.append('Slot_Type',data.Slot_Type)
        params = params.append('Approved_Status', type);
        params = params.append('stage', stage);
        params = params.append('Price', slot_price);
        params = params.append('Declined_Reason',data.declinedReason)
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/slots/customslots/acceptdecline';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    public assignSlot(data, assign_type): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('slotId', data.slotId);
        params = params.append('repId', data.repId);
        params = params.append('repName', data.repName);
        params = params.append('cityId', data.cityId);
        params = params.append('reservationId', data.reservationId);
        params = params.append('Process_Type', assign_type);
        console.log('Params to assign slot:', params, assign_type);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // const url = environment.slotUrl + '/reservations/'+assign_type;
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.slotUrl + '/reservations/approve';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    getSlotRequests(city_id) {
        const url = environment.facilityUrl + '/representatives/summary/slots?city_id='+city_id;
        return this.http.get<any>(url);
    }

    getCustomSlotReqs(city_id) {
        const url = environment.facilityUrl + '/representatives/summary/slots/custom?city_id='+city_id;
        return this.http.get<any>(url);
    }

    getRefereeReqs(city_id) {
        const url = environment.facilityUrl + '/representatives/summary/referee/pickslotrequests?city_id='+city_id;
        return this.http.get<any>(url);
    }

    summaryFilter(data, type) {
        let params = new HttpParams();
        if (type == "refereepickslotrequests") {
            params = params.append('city_id', data.city_id);
            params = params.append('ground_id', data.ground_id);
            params = params.append('sport_id', data.sport_id);
            params = params.append('from_date', data.from_date);
            params = params.append('to_date', data.to_date);
        } else {
            params = params.append('city_id', data.city_id);
            params = params.append('ground_id', data.ground_id);
            params = params.append('sport_id', data.sport_id);
            params = params.append('status', data.status);
            params = params.append('from_date', data.from_date);
            params = params.append('to_date', data.to_date);
        }
        const url = environment.facilityUrl + '/representatives/summary/'+type;
        return this.http.get<any>(url, { observe: 'response', params: params});
    }

    saveS3Url(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('Rep_id', data.rep_id);
        params = params.append('Key', data.key);
        params = params.append('Bucket', data.bucket);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.usersessionUrl + '/slots/info/bulk/upload';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });       
    }

    getPreSignedUrl(rep_id) {
        const url = environment.facilityUrl + '/signedurl?rep_Id='+rep_id;
        return this.http.get<any>(url);
    }

    uploadToPreSignedUrl(preSignedUrl, file, ext) {
        let headers = new HttpHeaders().set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').set('Content-Disposition', 'attachment');
        // const formData: FormData = new FormData();
        // formData.append(ext, file, file.name);
        return this.http.put<any>(preSignedUrl,file,{headers:headers})
    }

    delS3File(file_key) {
        let params = new HttpParams();
        params = params.append('delete', 'true');
        params = params.append('key', file_key);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.facilityUrl + '/object/delete';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    pendingApprovalsCount(city_id) {
        const url = environment.usersessionUrl + '/facility/slots/pending?City_Id='+city_id;
        return this.http.get<any>(url);
    }

    assignInfoSlot(data) {
        let params = new HttpParams();
        params = params.append('name',data.name);
        params = params.append('slotId', data.slotId);
        params = params.append('cityId', data.cityId);
        params = params.append('repId', data.repId);
        params = params.append('firstName', data.firstName);
        params = params.append('lastName', data.lastName);
        params = params.append('email', data.email);
        params = params.append('phoneNumber', data.phoneNumber);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.usersessionUrl + '/facility/slots/reserve';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });       
    }

    getInfoSlotClaims(city_id) {
        const url = environment.usersessionUrl + '/facility/slots/claimRequests?City_Id='+city_id;
        return this.http.get<any>(url);
    }

    acceptDeclineClaim(req_id, type) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let params = new HttpParams();
        params = params.append('Request_Status', type);
        params = params.append('Claim_Request_Id', req_id);
        const url = environment.usersessionUrl + '/facility/slots/claimRequests/process';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    public getSummaryCounts(city_id) {
        let params = new HttpParams().set('City_Id', city_id);
        console.log('Params to get parks:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.usersessionUrl + '/facility/representatives/summary/count';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }

    getNotifications(city_id): Observable<HttpResponse<any>> {
        const url = environment.usersessionUrl + '/facility/representatives/notifications?City_Id='+city_id;
        return this.http.get<any>(url);
    }

    public updateNotifications(city_id) : Observable<HttpResponse<any>> {
        let params = new HttpParams().set('City_Id', city_id);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url=  environment.usersessionUrl + '/facility/representatives/notifications';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers })
    }
    public updateUnreadNotifications(notification_id) : Observable<HttpResponse<any>> {
        let params = new HttpParams().set('Notification_Id', notification_id);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url=  environment.usersessionUrl + '/facility/representatives/notifications';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers })
    }

    public getDeclineCustomSlotReasons() : Observable<HttpResponse<any>>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = environment.slotUrl + '/customslots/declinedreason';
        return this.http.get<any>(url,{ observe: 'response', headers: headers })
    }

    public addCustomSlotDeclineReason(data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Content-Type','application/json');
        let params = new HttpParams();
        params = params.append('Reason', data.Reason);
        params = params.append('Rep_Id', data.Rep_Id);
        const url = environment.slotUrl + '/customslots/declinedreason';
        return this.http.post<any>(url,'',{observe: 'response', params: params, headers: headers})

    }

    public assignSlotToUser(data) : Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Content-Type','application/json');
        let params = new HttpParams();
        params = params.append('Slot_Id', data.Slot_Id);
        params = params.append('User_Id', data.User_Id);
        params = params.append('City_Id',data.City_Id);
        params = params.append('First_Name',data.First_Name);
        params = params.append('Last_Name',data.Last_Name);
        params = params.append('User_Mobile',data.User_Mobile);
        params = params.append('User_Email',data.Email);
        params = params.append('AssignedBy',data.AssignedBy)
        params = params.append('Assignee_Email',data.Assignee_Email),
        params = params.append('Assignee_Mobile',data.Assignee_Mobile)
        const url = environment.usersessionUrl + '/slots/slots/assign';
        return this.http.post<any>(url,'',{observe: 'response', params: params, headers: headers})
    }

    public getUserAllotedSlots(userID) :Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let params = new HttpParams();
        params = params.append('User_Id', userID);
        const url = environment.usersessionUrl + '/slots/slots/assign';
        return this.http.get<any>(url,{ observe: 'response', params: params, headers: headers })
    }

    public declineAssignedSlot(slotId) : Observable<HttpResponse<any>> {
        let params = new HttpParams().set('Slot_Id', slotId);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url=  environment.usersessionUrl + '/slots/slots/decline';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers })
    }

}