import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'groupby'
})
export class GroupbyPipe implements PipeTransform {

  transform(data: any, args?: any): any {
    var grounds = _.groupBy(data, (item) => { return item.Ground_Name });
    let grounds_arr = Object.keys(grounds).map(k => {
      return {
        name: k,
        data: grounds[k]
      }
    });
    return grounds_arr;
  }

}
