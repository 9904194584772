import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs/observable/from';
import { UserLoginService } from '../../service/user-login.service';
import { CitiesService } from '../../service/cities.service'

@Component({
  selector: 'app-slot-reservation-success',
  templateUrl: './slot-reservation-success.component.html',
  styleUrls: ['./slot-reservation-success.component.css']
})
export class SlotReservationSuccessComponent implements OnInit {

  user : any;
  constructor( public router: Router,
    private userService : UserLoginService,private citiesService:CitiesService)
  {
     this.user  = localStorage.getItem('user')
  }

  ngOnInit() {
  }

  onDashboard(){
     this.citiesService.sendData('default message');
     this.router.navigate(['/home/dashboard']);
  }


  gotoSupportReqs() {
    this.router.navigate(['/home/support-requests']);
  }

  logout () {
    this.user = 'false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }


}
