export const environment = {
    production: true,
    stage: 'production',
    region: 'us-east-1',
    identityPoolId: 'us-east-1:5ac6a375-3da8-414f-a109-ab0704279b7d',
    userPoolId: 'us-east-1_XhljN8EQt',
    clientId: '85sf8vi38ae21aglme8cij6j4',
    apiUrl: 'https://q3qapxnbvf.execute-api.us-east-1.amazonaws.com/prod',
    newapiUrl: 'https://mqmw5tqspk.execute-api.us-east-1.amazonaws.com/prod',
    slotUrl: "https://m7qdhy4ga8.execute-api.us-east-1.amazonaws.com/prod",
    facilityUrl: 'https://0a9syw9tz5.execute-api.us-east-1.amazonaws.com/prod',
    subscriptionsUrl: "https://m8b7xdhjkk.execute-api.us-east-1.amazonaws.com/prod",
    usersessionUrl:"https://api.fieldsmanager.io",
    logoStage: 'prod',
    URL: '.fieldsmanager.com',
    webSocketUrl : "wss://h7uazyi0s1.execute-api.us-east-1.amazonaws.com/prod",
    paypalURL:'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    paypaldirectURL:'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    facebookAuthURL:'https://fm-prod.auth.us-east-1.amazoncognito.com/authorize?client_id=85sf8vi38ae21aglme8cij6j4&response_type=token',
    googleAuthURL:'https://fm-prod.auth.us-east-1.amazoncognito.com/authorize?client_id=85sf8vi38ae21aglme8cij6j4&response_type=token',
    amazonAuthURL:'https://fm-prod.auth.us-east-1.amazoncognito.com/authorize?client_id=85sf8vi38ae21aglme8cij6j4&response_type=token',
    logout: 'https://fm-prod.auth.us-east-1.amazoncognito.com/logout?&client_id=85sf8vi38ae21aglme8cij6j4&logout_uri=',
    cityId: '157'
};
