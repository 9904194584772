import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-infoslot-claim-reqs',
  templateUrl: './infoslot-claim-reqs.component.html',
  styleUrls: ['./infoslot-claim-reqs.component.css']
})
export class InfoslotClaimReqsComponent implements OnInit {

  current_slot_id: any;
  claim_requests: any[] = [];
  moment: any = moment;
  loadingBar: boolean = false;
  unauthorized: any;
  error: any;

  constructor(
    private slotsService: SlotsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getInfoSlotClaimReqs();
  }

  getInfoSlotClaimReqs() {
    this.loadingBar = true;
    let city_id = localStorage.getItem("cityId");
    this.slotsService.getInfoSlotClaims(city_id)
      .subscribe((res: any) => {
        this.loadingBar = false;
        this.claim_requests = res;
      });
  }

  onAcceptReq(request, type) {
    this.loadingBar = true;
    let req_type = type == 'accept' ? 'Approved': 'Rejected'
    this.slotsService.acceptDeclineClaim(request.Claim_Request_Id, req_type)
      .subscribe((res: any) => {
        this.loadingBar = false;
        console.log('accept decline claim res', res);
        this.messageService.add({ severity: 'success', summary: 'Information Slot Claim', detail: res.body.msg });
        this.getInfoSlotClaimReqs();
      });
    
  }

  onDeclineReq(request, type) {
    this.onAcceptReq(request, type)
  }

}
