import { CitiesService } from './../../service/cities.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ElementRef ,ViewChild } from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { UserLoginService } from '../../service/user-login.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';

declare let paypal: any;
declare let $;


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  @ViewChild('content') content: ElementRef;

  somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/System.png';
  basicDetails= [];
  advancedDetails= [];
  premiumDetails= [];
  freePlanDetails=[];
  payAsYouGoDetails = [];
  selectble_plans= [];
  toggleMenu: any;
  sub_active_tab;
  pay_active_tab;
  active_plan;
  addScript: boolean = false;
  paypalLoad: boolean = true;
  paypalToken: any;
  payment_option=false;
  paypal_client_id: string;
  paypal_secret_key: string;
  total_amount: any;
  loading_spinner: boolean = false;
  msgs: any;
  paypal_price: any;
  payPalReceipt: any;
  active_plan_id: any;
  subscription_id: any;
  active_plan_price:any;
  payment_status: any;
  invoice_data: any;
  moment: any = moment;
  loadingBar: boolean = false;
  subscription_bills=[];
  sub_type;
  tabIndex: any = 0;
  planType: any = null;
  upgradedetails;
  active_plan_updatedAt: any;
  error: any;
  unauthorized: any;
  six_mth_plan_price: any;
  plan_discount: any;
  subscr_final_price: any;
  six_mth_plan_active: any;
  six_mth_plan_bill_date: any;
  currentPageItem: number;
  perPageTable: number;
  selectedPlanName: any;
  selectedPlanPrice: any;
  repId;
  cityId;

  constructor(public router: Router,
    public citiesService: CitiesService,
    private homeService: HomeService,
    private messageService: MessageService,
    private userService: UserLoginService

  ) { }

  ngOnInit() {

    this.currentPageItem = 1;
    this.currentPageItem = 1;
    this.perPageTable = 10;
    this.repId=localStorage.getItem('repId');
    this.cityId=localStorage.getItem('cityId');
    this.active_plan = localStorage.getItem('planName');
    this.selectedPlanName = this.active_plan;
    this.active_plan_id = localStorage.getItem('planId');
    this.active_plan_price=localStorage.getItem('planPrice');
    this.selectedPlanPrice = this.active_plan_price;
    if (this.active_plan_price === "50cents") {
      this.six_mth_plan_price = 6*(50 * 0.01);
    } else {
      this.six_mth_plan_price = 6*parseFloat(this.active_plan_price.split("$")[1]);
    }
    this.six_mth_plan_active=localStorage.getItem('sixMthPlan');
    this.plan_discount = Math.round(this.six_mth_plan_price *0.20);
    this.subscr_final_price = this.six_mth_plan_price - this.plan_discount;
    this.planType = this.active_plan_id;
    this.subscription_id = localStorage.getItem('subscriptionId');
    this.active_plan_updatedAt=localStorage.getItem('planUpdatedAt');
    this.getSubscriptionBills();
    this.getPlans();
    this.getPaypalDetails();
  }

  getSubscriptionBills(){
    this.loadingBar = true;
    this.homeService.getSubscriptionBills(this.subscription_id)
    .subscribe((res: any) => {
      console.log("Subscription Bills::",res);
      this.loadingBar = false;
      if (res !== "No Bills due") {
        if (res.length > 0) {
          this.six_mth_plan_bill_date = res[res.length - 1].Bill_Date;
        }
        this.subscription_bills = res;
      } else {
        this.subscription_bills = [];
      }
    });
  }

  cancelSubscription(){
    this.loadingBar=true;
    this.userService.getSubscriptionCheck(this.cityId).subscribe(res => {
     console.log("response::",res[0].Slots)
     this.loadingBar=false;
     if(res[0].Slots==0){
       $("#noSlotAvailModal").modal('show');
     }
     else{
      $("#futureSlotAvailModal").modal('show');
     }
    })
  }

  deactivateUser(){
    this.loadingBar=true;
    this.userService.deactivateUser(this.cityId).subscribe((res:any) => {
      console.log("response::",res)
      this.loadingBar=false;
      if(res.status==200){
        this.messageService.add({ 
        severity: 'success', 
        summary: 'Cancel Success', 
        detail: 'Subscription cancelled successfully' 
        });
        setTimeout(function(){
          localStorage.clear();
          this.router.navigate(['/admin/logout']);
        }.bind(this), 3000);
      }
      else{
        this.messageService.add({ 
          severity: 'error', 
          summary: 'Cancel Failed', 
          detail: 'please contact technical support at 214-206-8976' 
          });
      } 
     }, err => {
      console.log('this is the error', err);
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Cancel Failed', 
        detail: 'please contact technical support at 214-206-8976' 
       });
    })
  }

  pageChanged(e) {
    this.currentPageItem = e;
  }
  

  getInvoiceDetails(billId,planName) {
    this.loadingBar = true;
    this.homeService.getInvoiceData(this.subscription_id,billId)
    .subscribe((res: any) => {
      this.invoice_data = res[0];
        setTimeout(function(){
          this.makePdf();
          this.loadingBar=false;
        }.bind(this), 5000);
    });
    
  }

  getPaypalDetails() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      console.log('get paypal res', res);
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
      }
    })
  }

  onSelectPlan(plan) {
    if (this.sub_type == "2") {
      this.selectedPlanName = this.payAsYouGoDetails[0].label;
      this.selectedPlanPrice = this.payAsYouGoDetails[0].price;
        this.six_mth_plan_price = 6*(50 * 0.01);
    } else {
      this.selectedPlanName = plan.label;
      this.selectedPlanPrice = plan.price;
      this.six_mth_plan_price = 6*parseFloat(plan.price.split("$")[1]);
    }

    this.plan_discount = Math.round(this.six_mth_plan_price *0.20);
    this.subscr_final_price = this.six_mth_plan_price - this.plan_discount;
  }


  getPlans() {
    this.citiesService.getPlans().subscribe(response => {
      let plans = response.body;
      console.log('plans res', response.body);
     for ( let i = 0; i < plans.length; i++) {
        this.selectble_plans.push({label: plans[i].Plan_Name, value: plans[i]});
        if (plans[i].Plan_Name === 'Basic') {
          this.basicDetails.push(
              {
              'label':plans[i].Plan_Name,
              'value':plans[i].Plan_Id,
              'noofParks': plans[i].Parks_Limit,
              'groundsperParks': plans[i].Grounds_Per_Park,
              'sportsperGround': plans[i].Sports_Per_Ground,
              'price': plans[i].Price
            })
        } else if (plans[i].Plan_Name === 'Advanced') {
            this.advancedDetails.push(
                {
                'label':plans[i].Plan_Name,
                'value':plans[i].Plan_Id,
                'noofParks': plans[i].Parks_Limit,
                'groundsperParks': plans[i].Grounds_Per_Park,
                'sportsperGround': plans[i].Sports_Per_Ground,
                'price': plans[i].Price
              })
        }
        else if (plans[i].Plan_Name === 'Free') {
          this.freePlanDetails.push(
              {
              'label':plans[i].Plan_Name,
              'value':plans[i].Plan_Id,
              'noofParks': plans[i].Parks_Limit,
              'groundsperParks': plans[i].Grounds_Per_Park,
              'sportsperGround': plans[i].Sports_Per_Ground,
              'price': plans[i].Price
            })
        }
        else if (plans[i].Plan_Name === 'Premium') {
          this.premiumDetails.push(
            {
            'label':plans[i].Plan_Name,
            'value':plans[i].Plan_Id,
            'noofParks': plans[i].Parks_Limit,
            'groundsperParks': plans[i].Grounds_Per_Park,
            'sportsperGround': plans[i].Sports_Per_Ground,
            'price': plans[i].Price
          })
        } else {
          this.payAsYouGoDetails.push(
            {
            'label':plans[i].Plan_Name,
            'value':plans[i].Plan_Id,
            'noofParks': plans[i].Parks_Limit,
            'groundsperParks': plans[i].Grounds_Per_Park,
            'sportsperGround': plans[i].Sports_Per_Ground,
            'price': plans[i].Price
          })
        }
      }
    })
  }


  handleChange(e) {
    var index = e.index;
    if(index==0){
    this.tabIndex=0
    }
    else{
    this.tabIndex=1

      this.selectedPlanName = this.payAsYouGoDetails[0].label;
      this.selectedPlanPrice = this.payAsYouGoDetails[0].price;
      this.six_mth_plan_price = 6*(50 * 0.01);

      this.plan_discount = Math.round(this.six_mth_plan_price *0.20);
      this.subscr_final_price = this.six_mth_plan_price - this.plan_discount;

    }
  }


  onUpgrade(){
    this.loadingBar = true;
    if ($('#sub .ui-tabview-panel').attr('aria-hidden') === "false") {
      if(this.planType==1){
        this.upgradedetails=this.basicDetails[0];
      }
      else if(this.planType==2){
        this.upgradedetails=this.advancedDetails[0];
      }
      else if(this.planType==3){
        this.upgradedetails=this.premiumDetails[0];
      }
      else if(this.planType==5){
        this.upgradedetails=this.freePlanDetails[0];
      }
    }
    else{
      this.upgradedetails={
      'label':'Pay as you go',
      'value':4,
      'noofParks': 'Unlimited',
      'groundsperParks': 'Unlimited',
      'sportsperGround': 'Unlimited',
      'price': '50cents'
     }
    }
   console.log("upgrade params::",this.upgradedetails);
    this.homeService.upgradePlanDetails(this.upgradedetails,this.subscription_id).subscribe((res: any) => {
      this.loadingBar = false;
      console.log("upgrade plan::",res);
      this.messageService.add({ 
        severity: 'success', 
        summary: 'Upgrade Success', 
        detail: 'Plan upgrade successful!' 
       });
       this.getAdminDetails();
       this.getSubscriptionBills();
    }, err => {
      console.log('this is the error', err);
      this.messageService.add({ 
        severity: 'error', 
        summary: 'Upgrade Failed', 
        detail: 'Plan upgrade failed!' 
       });
       this.getAdminDetails();
       this.getSubscriptionBills();
    })
  }

  getAdminDetails() {
    let params = sessionStorage.getItem('authorizationToken');
    this.userService.getAdminDetails(params).subscribe(data => {
      localStorage.setItem('planId',  data[0]['Plan_Id'])
      this.active_plan_id = localStorage.getItem("planId");
      localStorage.setItem('planName',  data[0]['Plan_Name'])
      this.active_plan = localStorage.getItem("planName");
      localStorage.setItem('planPrice',  data[0]['Price'])
      this.active_plan_price = localStorage.getItem("planPrice");
      localStorage.setItem('planUpdatedAt',  data[0]['Plan_Updated_At'])
      this.active_plan_updatedAt = localStorage.getItem("planUpdatedAt");
      localStorage.setItem('sixMthPlan',  data[0]['active_six_months_subscription'])
      this.six_mth_plan_active = localStorage.getItem("sixMthPlan");
    });
  }

  setPayPal(token, price,billId,Plan_Id) {
    this.loading_spinner=true;
    console.log('this is token:', token, 'this is amount:', price);
    this.paypalToken=token;
    setTimeout(function(){
      this.paypal_price = price;
    }.bind(this), 0);
   if (!this.addScript) {
     this.addPaypalScript().then(() => {
       setTimeout(()=>{
          paypal.Buttons({
       createOrder: (data, actions) => {
        // $("#paymentModal").modal('hide');
         return actions.order.create({
           purchase_units: [{
           amount: {
           value: this.paypal_price
           }
           }],
           application_context: {
           shipping_preference: "NO_SHIPPING"
           },
         });
       },
       onApprove: (data, actions) => {
         return actions.order.capture().then((payment) => {
           console.log("payment success::", payment)
           $("#paymentModal").modal('hide');
           this.payPalReceipt = payment;
           if ( payment.status === 'COMPLETED') {
           let payment_data = {
             Subscription_Bill_Id:billId,
             Subscription_Id: this.subscription_id,
             Plan_Id: Plan_Id,
             Transaction_Id: payment.id,
             Intent: payment.intent,
             Status: payment.status,
             Payer_Email: payment.payer.email_address,
             Payer_Id: payment.payer.payer_id,
             Payer_Name: payment.payer.name.given_name+' '+payment.payer.name.surname,
             Phone_Number: payment.payer.phone.phone_number.national_number,
             Amount: payment.purchase_units[0].payments.captures[0].amount.value,
             Payee_Id: payment.purchase_units[0].payee.merchant_id,
             Payee_Email: payment.purchase_units[0].payee.email_address,
             Capture_Id: payment.purchase_units[0].payments.captures[0].id,
             Currency: payment.purchase_units[0].amount.currency_code,
             Create_Time: payment.create_time,
             Update_Time: payment.update_time,
           }
           
           this.homeService.saveInvoice(payment_data)
            .subscribe((res: any) => {
              console.log('save invoice res', res);
              this.getSubscriptionBills();
            });
           
           this.messageService.add({ 
             severity: 'success', 
             summary: 'Payment Success', 
             detail: 'Subscription successful!' 
            });
           } else {    
              this.messageService.add({ 
                severity: 'error', 
                summary: 'Payment Failed', 
                detail: 'Subscription failed!' 
               });
           }
         })
       }
          }).render('#paypal-checkout-btn');
       }, 0);
       this.paypalLoad = false;
       this.loading_spinner=false;
     })
   }
}

onSubscribe() {
  this.payment_option=true;
  $("#subscrModal").modal('hide');
  this.loading_spinner=true;
    this.paypalToken=this.paypal_secret_key;
    setTimeout(function(){
      this.paypal_price = this.subscr_final_price.toFixed(2);
    }.bind(this), 0);
   if (!this.addScript) {
     this.addPaypalScript().then(() => {
       setTimeout(()=>{
          paypal.Buttons({
       createOrder: (data, actions) => {
        // $("#paymentModal").modal('hide');
         return actions.order.create({
           purchase_units: [{
           amount: {
           value: this.paypal_price
           }
           }],
           application_context: {
           shipping_preference: "NO_SHIPPING"
           },
         });
       },
       onApprove: (data, actions) => {
         return actions.order.capture().then((payment) => {
           console.log("payment success::", payment)
           $("#paymentModal").modal('hide');
           this.payPalReceipt = payment;
           if ( payment.status === 'COMPLETED') {
            let payment_data = {
              Subscription_Id: this.subscription_id,
              Plan_Id: this.active_plan_id,
              Transaction_Id: payment.id,
              Intent: payment.intent,
              Status: payment.status,
              Payer_Email: payment.payer.email_address,
              Payer_Id: payment.payer.payer_id,
              Payer_Name: payment.payer.name.given_name+' '+payment.payer.name.surname,
              Phone_Number: payment.payer.phone.phone_number.national_number,
              Amount: payment.purchase_units[0].payments.captures[0].amount.value,
              Payee_Id: payment.purchase_units[0].payee.merchant_id,
              Payee_Email: payment.purchase_units[0].payee.email_address,
              Capture_Id: payment.purchase_units[0].payments.captures[0].id,
              Currency: payment.purchase_units[0].amount.currency_code,
              Create_Time: payment.create_time,
              Update_Time: payment.update_time,
            }

            this.homeService.subscrSixmthPlan(payment_data)
            .subscribe((res: any) => {
              console.log('6 months plan', res);
              this.getAdminDetails();
              this.getSubscriptionBills();
            });


           this.messageService.add({ 
             severity: 'success', 
             summary: 'Payment Success', 
             detail: 'Subscription successful!' 
            });
           } else {
            this.messageService.add({ 
              severity: 'error', 
              summary: 'Payment Failed', 
              detail: 'Subscription failed!' 
              });
           }
         })
       }
          }).render('#paypal-checkout-btn');
       }, 0);
       this.paypalLoad = false;
       this.loading_spinner=false;
     })
   }
}

addPaypalScript() {
   this.addScript = true;
   return new Promise((resolve, reject) => {
     let scripttagElement = document.createElement('script');
     scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'capture';
    //  scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=USD&commit=true&intent='+'capture';
     scripttagElement.onload = resolve;
     document.body.appendChild(scripttagElement);
   })
}

onPayment(price,plan,billId,Plan_Id){
  this.payment_option=true;
  if( plan == 'Basic' || plan == 'Advanced' || plan == 'Premium'){
    this.total_amount=price.split("$")[1];
  }
  else if(plan=='Pay As You Go'){
    this.total_amount=price.split("cents")[0]*0.05;
  }
  this.setPayPal(this.paypal_secret_key,this.total_amount,billId,Plan_Id);
}

  makePdf() {
    $('.content').show();
    var doc = new jspdf('p', 'pt', 'a4');
    var options = {
      pagesplit: true,
      background:"#fff",
      retina: true,
      margin: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 20,
        useFor: 'page'
      }
    }
    doc.addHTML(this.content.nativeElement,20,50,options, function() {
      doc.save("FM-Invoice.pdf");
    });
    $('.content').hide();
  }
}
