import { Component, OnInit } from '@angular/core';
import { CityRequestsService } from '../../service/city-requests.service';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
​
@Component({
  selector: 'app-city-admins',
  templateUrl: './city-admins.component.html',
  styleUrls: ['./city-admins.component.css']
})
export class CityAdminsComponent implements OnInit {
​
  unauthorized = false;
  loadingBar = false;
  cityAdmins=[];
  noAdmins = false;
  error = false;
  subscription_bills=[];
  six_mth_plan_bill_date: any;
  moment: any = moment;
​  msgs: any;

​
  constructor(public cityrequestsservice: CityRequestsService,private homeService: HomeService,private messageService: MessageService) { }
​
  ngOnInit() {
    this.getCityAdmins();
  }
​
  getCityAdmins(){
    this.loadingBar = true;
    this.cityAdmins = [];
    this.cityrequestsservice.getAdmins().subscribe((response:any) => {
        console.log('The response is', response);
        if (response.length === 0) {
            this.noAdmins = true;
            this.loadingBar = false;
            console.log('No requests are there');
        } else {
            console.log('Requests data:', response);
            response.forEach(element => {
                let temp = [];
                temp['cityId'] = element['City_Id'];
                temp['cityName'] = element['City_Name'];
                temp['Username'] = element['Username'];
                temp['cityStatus'] = element['City_Status'];
                temp['Email']=element['Email'];
                temp['City_Status']=element['City_Status'];
                temp['Subscription_Id']=element['Subscription_Id'];
                this.cityAdmins.push(temp);
            });
            this.loadingBar = false;
            console.log('returnsList:', this.cityAdmins);
        }
    }, err => { this.errorHandle(err) });
  }
​
  getSubscriptionBills(subscription_id){
    this.loadingBar = true;
    this.homeService.getSubscriptionBills(subscription_id)
    .subscribe((res: any) => {
      console.log("Subscription Bills::",res);
      this.loadingBar = false;
      if (res !== "No Bills due") {
        if (res.length > 0) {
          this.six_mth_plan_bill_date = res[res.length - 1].Bill_Date;
        }
        this.subscription_bills = res;
      } else {
        this.subscription_bills = [];
      }
    });
  }
 
  errorHandle(err) {
    if (err.status === 401) {
        this.loadingBar = false;
        this.unauthorized = true;
        // this.router.navigate(['/admin/unauthorized']);
    } else if (err.status === 400 || err.status === 404 || err.status === 500) {
        this.loadingBar = false;
        this.error = true;
        // this.router.navigate(['/admin/error']);
    } else {
        this.loadingBar = false;
        this.error = true;
        // this.router.navigate(['/admin/error']);
    }
}
​
activatedeactivate(cityId,type){
  this.loadingBar = true;
  const params = {
      'City_Id': cityId,
      'City_Status': type
  };
  this.cityrequestsservice.activatedeactivateRequest(params).subscribe(response => {
      console.log('The response is', response);
      if (response.status === 200 || response.status === 203) {
          this.getCityAdmins();
          this.loadingBar = false;
          this.messageService.add({ severity: 'success', summary: 'Cities Update', detail: 'City request approved successfully' });
      } else if (response.status === 201) {
          this.getCityAdmins();
          this.loadingBar = false;
          this.messageService.add({ severity: 'warn', summary: 'Cities Update', detail: 'Request approved, but unable to send notification!' });
      }
  }, err => { this.errorHandle(err) });
}
}