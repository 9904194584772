import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {
  transform(value: any,filter:any): any{
    if(value!== undefined && value!== null){
         if(filter=='parks'){
          return _.uniqBy(value, 'Park_Name');
         }
         else if(filter == 'grounds'){
          return _.uniqBy(value, 'Ground_Name');
         }
         else if(filter == 'sports'){
          return _.uniqBy(value, 'Sport_Name');
         }
         else if(filter == 'city'){
          return _.uniqBy(value, 'City');
         }
         else if(filter == 'ref_status'){
          return _.uniqBy(value, 'Ref_Slot_Req_Status');
         }
    }
    return value;
}

}
