import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { RegistrationUser } from "../public/auth/register/registration.component";
import { NewPasswordUser } from "../public/auth/newpassword/newpassword.component";
import * as AWS from "aws-sdk/global";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class UserRegistrationService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil, private http: HttpClient) {

    }

    register(user: RegistrationUser, callback: CognitoCallback): void {
        console.log("UserRegistrationService: user is " + user);

        // const phone = '+1'.concat(this.registrationUser.phone_number);
        // this.registrationUser.phone_number = phone;

        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: user.email
        };
        let dataUsername = {
            Name: 'username',
            Value: user.username
        };
        let dataFirstname = {
            Name: 'firstname',
            Value: user.firstname
        };
        let dataLastname = {
            Name: 'lastname',
            Value: user.lastname
        };
        let dataPhoneNumber = {
            Name: 'phone_number',
            Value: '+1' + user.phone_number
        };
        let dataPassword = {
            Name: 'password',
            Value: user.repassword
        };
        attributeList.push(new CognitoUserAttribute(dataEmail));
        // attributeList.push(new CognitoUserAttribute(dataUsername));
        // attributeList.push(new CognitoUserAttribute(dataFirstname));
        // attributeList.push(new CognitoUserAttribute(dataLastname));
        attributeList.push(new CognitoUserAttribute(dataPhoneNumber));
        // attributeList.push(new CognitoUserAttribute(dataPassword));

        this.cognitoUtil.getUserPool().signUp(user.username, user.password, attributeList, null, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                console.log("UserRegistrationService: registered user is " + result);
                callback.cognitoCallback(null, result);
            }
        });

    }

    accountActivation(username: string, confirmationCode: string, callback: CognitoCallback): void {

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    resendCode(username: string, callback: CognitoCallback): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    // public changePassword(data): Observable<HttpResponse<any>> {
    //     let params = new HttpParams();
    //     params = params.append('PreviousPassword', data.PreviousPassword);
    //     params = params.append('ProposedPassword', data.ProposedPassword);
    //     params = params.append('cityId', data.cityId);
    //     let headers = new HttpHeaders();
    //     headers.append('Content-Type', 'application/json');
    //     const url = environment.apiUrl + '/users/changepassword';
    //     return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    // }

    public changePassword(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('PreviousPassword', data.PreviousPassword);
        params = params.append('ProposedPassword', data.ProposedPassword);
        params = params.append('cityId', data.cityId);
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const url = environment.apiUrl + '/users/changepassword';
        // const url = environment.apiUrl + '/admin/changepassword';
        console.log('Params to update tax:', params, headers, data.AccessToken);
        return this.http.put<any>(url, {AccessToken: data.AccessToken}, { observe: 'response', params: params, headers: headers });
    }

    public userAddToGroup(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('username', data.username);
        params = params.append('firstName', data.firstname);
        params = params.append('lastName', data.lastname);
        params = params.append('email', data.email);
        params = params.append('phoneNumber', data.phone_number);
        params = params.append('cityId', sessionStorage.getItem('cityId'));
        console.log('Params to add user to group:', params);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/users';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    public partnerAddToGroup(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('username', data.username);
        params = params.append('firstName', data.firstname);
        params = params.append('lastName', data.lastname);
        params = params.append('email', data.email);
        params = params.append('phoneNumber', data.phone_number);
        params = params.append('cityId', sessionStorage.getItem('cityId'));
        console.log('Params to add user to group:', params);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/partners/add';
        return this.http.post<any>(url, '', { observe: 'response', params: params });
    }

    newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
        console.log(newPasswordUser);
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        console.log("UserLoginService: Params set...Authenticating the user");
        let cognitoUser = new CognitoUser(userData);
        console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    }
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err, null);
            }
        });
    }

    public updateDetails(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('first_name', data.first_name);
        params = params.append('last_name', data.last_name);
        params = params.append('user_id', data.user_id);
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const url = environment.apiUrl + '/users/updatedetails';
        // const url = 'https://nry8t2vqxj.execute-api.us-east-2.amazonaws.com/dev/users/updatedetails';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }


}
