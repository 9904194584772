import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
import { environment } from './../../../environments/environment';
declare let $;

@Component({
  selector: 'app-custom-slot-requests',
  templateUrl: './custom-slot-requests.component.html',
  styleUrls: ['./custom-slot-requests.component.css']
})
export class CustomSlotRequestsComponent implements OnInit {

  requests: any[] = [];
  moment: any = moment;
  loadingBar: boolean = false;
  unauthorized: any;
  error: any;
  cityId:any;
  // active_plan:string;
  request: any;
  type: any;
  slotPrice : any;
  active_plan:string;
  declineReason : string;
  reasonOptions = [];
  isReasonSelected : boolean = false;
  newDeclineReason : string;
  isReasonNotEntered : boolean = false;

  constructor(private slotsService: SlotsService,private messageService:MessageService) { }

  ngOnInit() {
    this.loadingBar=true;
    this.active_plan = localStorage.getItem('planName');
    this.cityId= sessionStorage.getItem('cityId');
    this.getCustomSlotRequets();
    this.getDeclinedReasons();
  }


  getCustomSlotRequets(){
    this.slotsService.getCustomSlotRequests(this.cityId).subscribe((res: any) => {
       console.log("requets::",res);
       this.loadingBar=false;
       this.requests=res;
    })
  }

  getDeclinedReasons() {
    this.loadingBar = true;
    this.slotsService.getDeclineCustomSlotReasons().subscribe((response) => {
      this.loadingBar = false;
      console.log(response);
      this.reasonOptions = response.body
    })
  }

  // onAcceptDecReq(request,type){
  //   if (this.active_plan === "Free") {
  //     $("#restrictModal").modal('show');
  //   } else {
  //     let slot_price = $('#cust_slot_id_'+request.Custom_Slot_Id).val();
  //     this.slotsService.acceptCustomRequest(request,type,environment.logoStage, slot_price).subscribe((res) => {
  //       console.log('accept edit req res', res);
  //       this.messageService.add({ severity: 'success', summary: 'Slot Custom Request', detail: res.body.msg });
  //       this.getCustomSlotRequets();
  //     });
  //   }
  // }
  onAcceptDecReq(request,type){
    this.request = request;
    this.type = type;
    // if (this.active_plan === "Free") {
    //   $("#restrictModal").modal('show');
    // } 
    // else {
      this.slotPrice = $('#cust_slot_id_'+request.Custom_Slot_Id).val();
      if(type === 'rejected'){
        this.declineReason = "";
        this.isReasonSelected = false;
        $('#declinereqmodal').modal('show')
      }
      else{
        let declinedReason = null;
        this.acceptOrRejectRequest(declinedReason)
      }
    // }
  }
  acceptOrRejectRequest(declinedReason) {
    this.request.declinedReason = declinedReason;
    this.loadingBar = true;
    this.slotsService.acceptCustomRequest(this.request,this.type,environment.logoStage, this.slotPrice).subscribe((res) => {
      this.loadingBar = false;
      console.log('accept edit req res', res);
      this.messageService.add({ severity: 'success', summary: 'Slot Custom Request', detail: res.body.msg });
      this.getCustomSlotRequets();
    });
  }

  onReasonSelect() {
    if(this.declineReason != undefined || this.declineReason != ""){
      this.isReasonSelected = true;
      let decline_reason = this.declineReason;
      console.log(decline_reason);
      this.oncloseDecline()
      this.acceptOrRejectRequest(decline_reason);
    }
   
  }
  oncloseDecline() {
    this.declineReason = "";
    $('#declinereqmodal').modal('hide')

  }
  onreasonchange() {
    this.isReasonSelected = true;
  }
  addNew() {
    this.newDeclineReason = "";
    $('#addReasonModal').modal('show')
  }

  addReason() {
    if(this.newDeclineReason!=""){
      this.isReasonNotEntered = false; 
      let data = {
        "Reason" : this.newDeclineReason,
        "Rep_Id" : localStorage.getItem("repId")
      }
      $('#addReasonModal').modal('hide')
      this.loadingBar = true;
      this.slotsService.addCustomSlotDeclineReason(data).subscribe((response) => {
        console.log(response);
        this.loadingBar = false;
        if(response.status == 200){
          // $('#addReasonModal').modal('hide')
          this.getDeclinedReasons();
        }
      })
    }
    else{
      this.isReasonNotEntered = true
    }
  }
}
