import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $;

@Component({
  selector: 'app-reusable-alert',
  templateUrl: './reusable-alert.component.html',
  styleUrls: ['./reusable-alert.component.css']
})
export class ReusableAlertComponent implements OnInit {

  @Input() modalHeader;
  @Input() modalBody;
  @Input() modalType;
  constructor(private router : Router) { }

  ngOnInit() {
  	 $('#reusablemodal').modal('show');
  }
  goToParks() {
  	if(this.modalType === 'parks'){
      this.router.navigate(['/admin/parks']);
  	}
  	else if(this.modalType === 'grounds'){
  		this.router.navigate(['/admin/grounds'])
  	}
  	else if(this.modalType === 'slots'){
  		this.router.navigate(['/admin/slots'])
  	}
  }

}
