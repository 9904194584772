import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import {MessageService} from 'primeng/components/common/messageservice';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'app-slot-password-requests',
  templateUrl: './slot-password-requests.component.html',
  styleUrls: ['./slot-password-requests.component.css']
})
export class SlotPasswordRequestsComponent implements OnInit {

  pw_requests:any=[];
  city_id:any;
  loadingBar=false;
  public moment: any = moment;
  unauthorized: any;
  error: any;


  constructor(private slotService: SlotsService,private messageService: MessageService) { }

  ngOnInit() {
    this.city_id= sessionStorage.getItem('cityId');
    this.slotPasswordRequests();
  }

  slotPasswordRequests(){
    this.loadingBar=true;
    this.slotService.getPasswordRequests(this.city_id).subscribe((res) => {
      console.log("res",res);
      this.pw_requests = res;
      this.loadingBar=false;
    });
    
  }

  onRequest(type,request){
    console.log(type,request.RS_Pwd_Id,type);
    this.slotService.acceptdeclineRequest(request.RS_Pwd_Id,type).subscribe((res) => {
     console.log("res",res);
     this.messageService.add({ severity: 'success', summary: 'Request', detail: res.body.msg});
     this.slotPasswordRequests();
    });
  }

}
