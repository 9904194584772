import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoginService } from '../../service/user-login.service';
declare let $;

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent implements OnInit {
  
  reportIssueForm: FormGroup;
  issueFormSubmitted: boolean = false;
  userName: string;
  userId:any;
  loadingBar: boolean = false;
  resvId:any;
  user = 'false';
  superAdmin='false';
  cityAdmin='false';
  feedback_url:any;
  support_requests: boolean = false;
  sports_details=false;
  show_allSports=false;
  park_slotsDetails=false;
  reportIssue = false;
  feedback=false;



  constructor(
    private formbuilder: FormBuilder,
    private homeService: HomeService,
    private msgService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserLoginService
  ) { 
      this.route.params.subscribe((params)=> {
        if (params.reservation_id !== undefined) {
        this.resvId = params.reservation_id;
        }
      })

  }

  ngOnInit() {
    this.userId = localStorage.getItem('id');
    this.user = localStorage.getItem('user');
  	this.reportIssueForm = this.formbuilder.group({
      'user': new FormControl(''),
      'priority': new FormControl(null, Validators.required),
      'subject': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
    });
    this.userName = localStorage.getItem('firstName');
  }

  onReportIssue() {
    this.loadingBar=true;
    this.issueFormSubmitted = true;
    if (this.reportIssueForm.valid) {
      this.issueFormSubmitted = false;
      this.reportIssueForm.value.user = this.userName;
      $.ajax({
        url:'https://agiledev.bizcloudexperts.com/issues.json?key=96849c1514edb5df919e5d594e87a7973d2581a7',
        dataType:'json',
        type:'POST',
        data:{
          "issue": {
            "project_id": 2,
            "subject": this.reportIssueForm.value.subject,
            "priority_id": this.reportIssueForm.value.priority,
            "description": this.reportIssueForm.value.description
          }
        },
        success:(res)=>{
          if (res) {
            let data = {
              'subject': res.issue.subject,
              'description': res.issue.description,
              'user_id': this.userId,
              'reservation_id': this.resvId,
              'redmine_id': res.issue.id,
              'project_id': res.issue.project.id,
              'project_name': res.issue.project.name,
              'status_id': res.issue.status.id,
              'status_name': res.issue.status.name,
              'author_id': res.issue.author.id,
              'author_name': res.issue.author.name,
              'priority_id': res.issue.priority.id,
              'priority_name': res.issue.priority.name,
              'created_on': res.issue.created_on,
              'updated_on': res.issue.updated_on,
            }
            this.homeService.createIssue(data)
            .subscribe((res: any)=> {
              console.log('create issue res', res);
              this.loadingBar=false;
              this.reportIssueForm.reset();
              this.reportIssueForm.value.user = this.userName;
              this.msgService.add({
                severity: 'success',
                summary: 'Issue',
                detail: res.body.msg
              });
              // this.backToLanding.next('report-issue');
                 this.router.navigate(['/home']);
            });
          }
        }
      });
    }
    else{
      this.loadingBar=false;
    }
  }

  cancelReport() {
    // this.backToLanding.next('report-issue');
    this.router.navigate(['/home']);
  }

  public get isueSeverity() : string {
    return this.reportIssueForm.controls.severity.value;
  }
  public get isuePriority() : string {
    return this.reportIssueForm.controls.priority.value;
  }
  public get isueSubject() : string {
    return this.reportIssueForm.controls.subject.value;
  }
  public get isueDesc() : string {
    return this.reportIssueForm.controls.description.value;
  }

   gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
    this.router.navigate(['/home/support-requests'])
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

   logout () {
    this.user = 'false';
    this.cityAdmin='false';
    this.superAdmin='false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

}
