import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import { ExportExcelService } from '../../service/export-excel.service';
declare let $;
import * as _ from "lodash"
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
  selector: 'app-referee-requests',
  templateUrl: './referee-requests.component.html',
  styleUrls: ['./referee-requests.component.css']
})
export class RefereeRequestsComponent implements OnInit {

  picked_ref_reqs:any=[];
  slotcitySearch:string="";
  slotparkSearch:string="";
  slotgroundSearch:string="";
  slotsportSearch:string="";
  slotstatusSearch:string="";
  public moment: any = moment;
  loadingBar: boolean = false;
  isPagination: boolean = true;
  error:any;
  unauthorized:any;
  selectedReferee: any = "";
  city_id: any;



  constructor(
    public homeservice: HomeService,
    private excelService: ExportExcelService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
     //this.getRefereeRequests();
     this.city_id = sessionStorage.getItem('cityId');
     this.getPickedRequests();
  }

  // myfndataTable() {
  //   setTimeout(function(){
  //     var table = $('#resblk').dataTable({
  //       "bAutoWidth": false,
  //       "bPaginate": this.isPagination,
  //       "sPaginationType":"full_numbers",
  //       "iDisplayLength": 10,
  //       language: {
  //           "paginate": {
  //           "previous": '<i class="fa fa-backward">',
  //           "next":'<i class="fa fa-forward">',
  //           'first':'<i class="fa fa-step-backward">',
  //           "last":'<i class="fa fa-step-forward">'
  //           }
  //       }
  //     })
  //   }.bind(this), 0);
  // }

  // reInitDatatable() {
  //   $('#resblk').DataTable().destroy();
  //   setTimeout(function(){
  //     console.log('========', ($('.table tbody tr td').html()));
  //     if(($('.table tbody tr td').html()=='No data available in table') || ($('.table tbody tr td').html()==undefined)){
  //       $('.no_records').css('margin-top', '15px');
  //       this.isPagination = false;
  //       this.myfndataTable()
  //     } else {
  //      this.isPagination = true;
  //      this.myfndataTable()
  //      $('.no_records').css('margin-top', '0px');
  //     }
  //   }.bind(this), 500);
  // }

  // getRefereeRequests(){
  //   this.loadingBar=true;
  //   const params = {
  //     'cityId': sessionStorage.getItem('cityId'),
  //     'authorizationToken': sessionStorage.getItem('authorizationToken')
  // };
  //   this.homeservice.getRefereeRequets(params).subscribe((response:any) => {
  //     console.log("referee requests::",response);
  //     this.loadingBar=false;
  //     this.myfndataTable();
  //     if(response.msg===undefined){
  //       this.referee_requests=response;
  //     }
  //     else{
  //       this.referee_requests=[];
  //     }
  //   })
  // }

  // exportToExcel():void {
  //   this.excelService.exportAsExcelFile(this.referee_requests, 'sample');
  // }


 getPickedRequests(){
  this.loadingBar=true;
    const params = {
      'cityId': sessionStorage.getItem('cityId'),
      'authorizationToken': sessionStorage.getItem('authorizationToken')
  };
    this.homeservice.getPickedRefReqs(params).subscribe((res: any) => {
      this.loadingBar=false;
      if (res.msg === undefined) {
        let slots_grpby = _.groupBy(res, (item) => { return item.Slot_Id });
        Object.keys(slots_grpby).map(k => {
          let request = slots_grpby[k][0];
          var obj = {
            Ref_PS_Req_Id: request.Ref_PS_Req_Id,
            Res_Id: request.Res_Id,
            City_Id: request.City_Id,
            Ref_Slot_Req_Id: request.Ref_Slot_Req_Id,
            Ref_PS_Status: request.Ref_PS_Req_Id,
            Slot_Id: request.Slot_Id,
            Slot: request.Slot,
            Ground_Id: request.Ground_Id,
            Sport_Id: request.Sport_Id,
            Slot_Date: request.Slot_Date,
            Ground_Name: request.Ground_Name,
            Sport_Name: request.Sport_Name,
            referees: slots_grpby[k]
          }
          this.picked_ref_reqs.push(obj);
        });
      }

    })
 }

 assignReferee(request) {
  let data = {
    ref_ps_req_id: request. Ref_PS_Req_Id,
    reservation_id: request.Res_Id,
    ref_id: this.selectedReferee,
    city_id: this.city_id,
    status: "Accepted"
  }

  this.homeservice.assignReferee(data)
  .subscribe((res: any) => {
    console.log('assign referee', res);
    this.picked_ref_reqs = [];
    this.getPickedRequests();
    this.messageService.add({ 
      severity: 'success', 
      summary: 'Assign Referee', 
      detail: res.body.msg 
    });
  });
 }

}
