import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
declare let paypal: any;
declare let paypal_auth:any;
import { UserLoginService } from '../../service/user-login.service';
import {HomeService} from '../../service/home.service';
import * as moment from 'moment';
import { CitiesService } from '../../service/cities.service';
import { environment } from './../../../environments/environment';


@Component({
  selector: 'app-referee-booking',
  templateUrl: './referee-booking.component.html',
  styleUrls: ['./referee-booking.component.css']
})
export class RefereeBookingComponent implements OnInit {

  countdownTimer:any;
  seconds:any;
  countdown:any;
  timeInterval:any;
  addScript: boolean = false;
  paypalLoad: boolean = true;
  paypalToken: any;
  payment_option=false;
  user = 'false';
  paymentDone: boolean = false;
  paymentSuccess: boolean = false;
  futureReservations: any [] = [];
  sportId: number;
  currentSlot: any;
  userId:any;
  resvId:any;
  moment: any = moment;
  currentSport: any;
  environment:any=environment;
  loading_spinner:boolean=false;
  paypal_client_id: string;
  paypal_secret_key: string;
  slot_duration: any;
  toal_price: any;
  support_requests: boolean = false;
  sports_details=false;
  show_allSports=false;
  park_slotsDetails=false;
  reportIssue = false;
  feedback=false;

  constructor(
    public router: Router, 
    public userService: UserLoginService,
    private route: ActivatedRoute,
    public homeService: HomeService,
    public citiesservice: CitiesService
  ) {
    this.route.queryParams.subscribe(params => {
      this.sportId = JSON.parse(params.sportId);
      this.resvId = JSON.parse(params.resvId);
    });
  }

  ngOnInit() {
    this.userId = localStorage.getItem('id');
    this.seconds = 300;
    this.timeInterval=setInterval(() => {
    this.countdownTimer =this.secondPassed();
    }, 1000);
    this.getPaypalDetails();
    this.user = localStorage.getItem('user');

    this.citiesservice.currentMessage.subscribe(res => {
      console.log('Response1::', res);
       if(res === 'default message'){
           this.router.navigate(['/home'])
       }
       else{
          this.currentSport=res;
       }
    })
    this.homeService.currentMessage.subscribe(res => {
       if(res === 'default message'){
           this.router.navigate(['/home'])
       }
       else{
           this.currentSlot=res;
       }
    })

    this.getPaypalDetails();
    this.calSlotDuration();

  }

  gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
    this.router.navigate(['/home/support-requests']);
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

  getPaypalDetails() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      console.log('get paypal res', res);
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
        this.setPayPal(this.paypal_client_id, this.toal_price)
      }
    })
  }

  calSlotDuration() {
    let from_time_arr = this.currentSlot.Slot.split('-')[0];
    let to_time_arr = this.currentSlot.Slot.split('-')[1];

    var startTime1 = moment(from_time_arr, "hh:mm A");
    var endTime1 = moment(to_time_arr, "hh:mm A");
    
    var duration = moment.duration(endTime1.diff(startTime1));
    let slot_hours = Math.round(duration.asHours());

    this.slot_duration = slot_hours;
    this.toal_price = this.slot_duration*0.10;
    this.toal_price = this.toal_price.toFixed(2);
  }

  logout () {
    this.user = 'false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  secondPassed() {
		var minutes = Math.round((this.seconds - 30)/60);
		var remainingSeconds = this.seconds % 60;
		if (remainingSeconds < 10) {
		remainingSeconds = 0 + remainingSeconds;  
		}
		if (this.seconds == 0) {
		clearInterval(this.countdownTimer);
		this.router.navigate(['/home'])
		} else {
		this.seconds--;
        this.countdown = minutes + ":" + remainingSeconds;
		}
  }

  setPayPal(token, price) {
   this.paypalToken=token;
   setTimeout(function(){
      this.paypal_price = price;
   }.bind(this), 0);

   if (!this.addScript) {
     this.addPaypalScript().then(() => {
       setTimeout(function(){
          paypal.Buttons({
       createOrder: (data, actions) => {
         return actions.order.create({
           purchase_units: [{
           amount: {
           value: this.paypal_price
           }
           }],
           application_context: {
           shipping_preference: "NO_SHIPPING"
           },
         });
       },
       onApprove: (data, actions) => {
         this.loadingBar = true;
         this.checkout = false;
         return actions.order.capture().then((payment) => {
          this.paymentDone = true;
           this.payPalReceipt = payment;
           if ( payment.status === 'COMPLETED') {
           console.log("payment success::", payment)
           //this.onFormSubmit();
           this.paymentSuccess = true;
           this.requestReferee();
           setTimeout(() => {
             this.router.navigate(['/home'])
           }, 5000);
             //this.goToMenu(3);
           } else {
            this.paymentSuccess = false;
               this.onPayment();
               alert('Some error occured:' + payment.state);
           }
         })
       }
          }).render('#paypal-checkout-btn');
       }.bind(this), 0);
       this.paypalLoad = false;
     })
   }
}

addPaypalScript() {
   this.addScript = true;
   return new Promise((resolve, reject) => {
     let scripttagElement = document.createElement('script');
     scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=USD&commit=true&intent='+'capture';
     scripttagElement.onload = resolve;
     document.body.appendChild(scripttagElement);
   })
}

requestReferee() {
  let payment_status = 1;
  this.homeService.requestReferee(this.userId, this.resvId, payment_status)
  .subscribe((res: any)=> {
    console.log('request referee res', res);
  });
}
  
ngOnDestroy(){
  clearInterval(this.timeInterval);
}
 

}
